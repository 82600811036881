import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'grafico-velocimetro',
  templateUrl: './grafico-velocimetro.component.html',
  styleUrls: ['./grafico-velocimetro.component.css']
})
export class GraficoVelocimetroComponent implements OnInit {

  constructor() { }
  @Input() porcentaje;
  ponerColor(){
    if(this.porcentaje>66){
      return '#ed0505';
    }else if(this.porcentaje>33){
      return '#ff9b14';
    }else{
      return '#1de572';
    }
  }
  ngOnInit(): void {
  }
  //180 es el valor maximo que puede tener el angulo de la flecha
  conversion(porcentaje){
    if(porcentaje>100){
      porcentaje=100;
    }
    return 180*porcentaje/100;
  }
  
}
