import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ApexNonAxisChartSeries,ApexResponsive,ApexChart,ChartComponent} from 'ng-apexcharts';
import { takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/Services/loader.service';
import { UserService } from 'src/app/Services/user.service';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
@Component({
  selector: 'popup-informacion-empleado',
  templateUrl: './popup-informacion-empleado.component.html',
  styleUrls: ['./popup-informacion-empleado.component.css'],
})
export class PopupInformacionEmpleadoComponent extends UnsubscribesDestroy implements OnInit {
  opcion: string = 'Corto Plazo';
  @ViewChild('chart') chart!: ChartComponent;

  procesoActual: any = null;

  public chartOptions: any;
  trabajador;
  constructor(
    public dialogRef: MatDialogRef<PopupInformacionEmpleadoComponent>,
    private loaderSE: LoaderService,
    private notificacionesSE: NotificacionesService,
    private UserSE: UserService,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
    this.chartOptions = {
      series: [12, 30, 13],
      chart: {
        type: 'donut',
        toolbar: {
          show: false,
        },
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      pie: {
        customScale: 0.8,
        width: '20px',
      },
      legend: {
        show: false,
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200
      //       },
      //       legend: {
      //         position: "bottom"
      //       }
      //     }
      //   }
      // ],
      plotOptions: {
        pie: {
          donut: {
            size: '80%',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
    };
  }
  porcentaje = 0;

  ngOnInit(): void {
    this.trabajador = this.modalData;
    this.porcentaje = this.trabajador.porcentaje_corto * 100;
    if (this.modalData.procesos.length != 0) {
      this.procesoActual = this.modalData.procesos[0];
    }
  }

  opcionSeleccionada(event: string) {
    this.opcion = event;
    switch (this.opcion) {
      case 'Corto Plazo':
        this.porcentaje = this.trabajador.porcentaje_corto * 100;
        break;
      case 'Medio Plazo':
        this.porcentaje = this.trabajador.porcentaje_medio * 100;
        break;
      case 'Largo Plazo':
        this.porcentaje = this.trabajador.porcentaje_largo * 100;
        break;
    }
  }
  procesoActualIndice = 0;
  avanzar() {
    if (this.procesoActualIndice + 1 < this.modalData.procesos.length) {
      this.procesoActualIndice++;
      this.procesoActual = this.modalData.procesos[this.procesoActualIndice];
    }
  }

  retrasar() {
    if (this.procesoActualIndice - 1 >= 0) {
      this.procesoActualIndice--;
      this.procesoActual = this.modalData.procesos[this.procesoActualIndice];
    }
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  cambiarAcceso() {
    this.loaderSE.open();
    this.UserSE.editUser(this.trabajador.id,{cambioAcceso:true});
    this.UserSE.getResultUpdate()
    .pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
      if (value) {
        if (value == null) {
          return;
        }
        this.trabajador.can_access = !this.trabajador.can_access;
        this.loaderSE.closeContador();
        this.notificacionesSE.showFeedBack('Actualización correcta', this.trabajador.nombre + ' ' + this.trabajador.apellidos + ' ahora ' + (this.trabajador.can_access ? 'puede' : 'no puede') + ' acceder al sistema');
        this._unsubscribeIndividual2.next('');
      }
    });

    this.UserSE.getResultUpdateError()
    .pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
      if (value) {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this._unsubscribeIndividual2.next('');
        this.notificacionesSE.showError('Error al actualizar el usuario', value.error);
      }
    });
  }
}
