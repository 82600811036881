<div class="informacion">
    <div class="color" [style.background]="proyecto.color"></div>
    <div class="abreviatura">{{proyecto.abreviatura}}</div>
    <div class="nombre">{{proyecto.nombre}}</div>
    <div class="porcentaje">
        <div class="valor">{{proyecto.porcentaje|percent}}</div>
        <div class="contenedor">
            <div class="barra" [style.width]="proyecto.porcentaje*100 + '%'"></div>
        </div>
    </div>
    <div class="rentabilidad" [class.mal]="rentabilidadMal">
        <span class="icono"><img src="/assets/icons/giving-money.png"></span>
        <span class="flecha"></span>
        <span class="valor">{{rentabilidadAnterior|percent}}</span>
    </div>
    <div class="grafica">        
        <apx-chart
        #chart
        [series]="grafica.series"
        [chart]="grafica.chart"
        [xaxis]="grafica.xaxis"
        [yaxis]="grafica.yaxis"
        [dataLabels]="grafica.dataLabels"
        [grid]="grafica.grid"
        [stroke]="grafica.stroke"
        [tooltip]="{ enabled: false}"
        [legend]="{ show: false}"
        ></apx-chart>
    </div>
</div>