
  import { HttpClient } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { take } from 'rxjs';
  import { Clientes } from '../Interfaces/clientes';
import { CRM } from '../Interfaces/crm';
  import { BaseService } from './Base.service';
  
  @Injectable({
    providedIn: 'root',
  })
  export class CrmService extends BaseService {
    constructor(protected http: HttpClient) {
      super();
    }
    
    updateCRM(crm: CRM) {      
      this.http
        .put(this.urlApi + 'api/estadoscrm/'+crm.id, crm, this.getHeader())
        .subscribe({
          next: (data) => {
            this.sendNextResult("resultUpdate",data);
          },
          error: (error) => {
            this.sendNextResult('resultError', error, { method: this.updateCRM, args: [crm] });
            this.checkStatusError(error);
          },
        });
    }
  
    getAllCRMByEmpresa(empresa,lista = false) {
      let url:string;
      if(lista){
      url = "api/estadoscrm?list&empresa=" + empresa;
      }else{
        url = "api/estadoscrm?empresa=" + empresa;
      }
      
      this.http
        .get(this.urlApi + url, this.getHeader())
        .subscribe({
          next: (data) => {
            let crms = [];
            crms.push(data);
            crms.forEach((element) => {
              let crm: CRM = new CRM();
              crm = element;
              this.sendNextResult("result",crm);
            });
          },
          error: (error) => {
            this.sendNextResult('resultError', error, { method: this.getAllCRMByEmpresa, args: [empresa,lista] });
            this.checkStatusError(error);
          },
        });
    }
  
    
    getAllCRMByParameters(num_devoluciones, num_pagina, empresa) {
      
      let query:string = "?empresa="+empresa+"&num_devoluciones="+num_devoluciones+"&num_pagina="+num_pagina;
      this.http
        .get(this.urlApi + 'api/estadoscrm' + query, this.getHeader())
        .subscribe({
          next: (data) => {
            let crms = [];
            crms.push(data);
            crms.forEach((element) => {
              let crm: CRM = new CRM();
              crm = element;
              this.sendNextResult("result",crm);
            });
          },
          error: (error) => {
            this.sendNextResult('resultError', error, { method: this.getAllCRMByParameters, args: [num_devoluciones,num_pagina,empresa] });
            this.checkStatusError(error);
          },
        });
    }
  }
  