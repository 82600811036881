<div class="proyectosGeneralContainer">
  <div class="sideBarGeneralContainer">
    <sidebar [elementoActivo]="'proyectos'"></sidebar>
  </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="Proyectos" (accionBoton)="abrirPopupAddProyecto()" [boton]="false"></app-header-bar>
      </div>
      <div class="barraSuperior">
        <button (click)="abrirPopupAddProyecto()" class="btn"><mat-icon>add_circle</mat-icon> Nuevo proyecto</button>
        </div>
      <div class="selectores">
        <div class="selector" [class.activo]="filtro==null" (click)="filtrar(null)">
          <p class="titulo">Todos los proyectos</p>
          <div class="datos">
            <div class="cantidad">{{proyectos.length}}</div>
            <div class="seleccionar">Seleccionar</div>
          </div>
        </div>
        <div class="selector" [class.activo]="filtro==1" (click)="filtrar(1)">
          <p class="titulo"><img src="/assets/icons/activo.svg"> Proyectos activos</p>
          <div class="datos">
            <div class="cantidad">{{proyectosActivos}}</div>
            <div class="seleccionar">Seleccionar</div>
          </div>
        </div>
        <div class="selector" [class.activo]="filtro==0" (click)="filtrar(0)">
          <p class="titulo"><img src="/assets/icons/pausado.svg"> Proyectos pausados</p>
          <div class="datos">
            <div class="cantidad">{{proyectosPausados}}</div>
            <div class="seleccionar">Seleccionar</div>
          </div>
        </div>
        <div class="selector" [class.activo]="filtro==3" (click)="filtrar(3)">
          <p class="titulo"><img src="/assets/icons/activo.svg"> Proyectos hibernados</p>
          <div class="datos">
            <div class="cantidad">{{proyectosHibernados}}</div>
            <div class="seleccionar">Seleccionar</div>
          </div>
        </div>
        <div class="selector" [class.activo]="filtro==2" (click)="filtrar(2)">
          <p class="titulo"><img src="/assets/icons/inactivo.svg"> Proyectos inactivo</p>
          <div class="datos">
            <div class="cantidad">{{proyectosFinalizados}}</div>
            <div class="seleccionar">Seleccionar</div>
          </div>
        </div>
        <div class="selector" [class.activo]="filtro==-1" (click)="filtrar(-1)">
          <p class="titulo"><img src="/assets/icons/archivado.svg"> Proyectos archivados</p>
          <div class="datos">
            <div class="cantidad">{{proyectosArchivados}}</div>
            <div class="seleccionar">Seleccionar</div>
          </div>
        </div>
      </div>
      <div *ngIf="false" class="navegacionGeneralContainer">
        <menu-navegacion
        [icons]="['/assets/icons/grid.png','/assets/icons/timeline.png','/assets/icons/leaderboard.png']"
          [opcionPorDefecto]="'Proyectos'"
          [opciones]="['Proyectos','Timeline', 'Analítica']"
          (emitOpcionSeleccionada)="opcionSeleccionada($event)"
        ></menu-navegacion>
      </div>
      <div class="dataBodyContainer">        
        <div *ngIf="opcion == 'Proyectos'" class="proyectoPorProyectManager">
          <div class="tarjetasProyectos">
            <div class="leyenda">
              <div></div>
              <div class="progreso">Progreso</div>
              <div class="rentabilidad">Rentabilidad</div>
              <div class="presupuesto">Presupuesto</div>
              <div class="integrantes">Integrantes</div>
            </div>
              <ng-container *ngFor="let proyecto of proyectos">
                <tarjeta-proyecto *ngIf="false" [proyecto]="proyecto"></tarjeta-proyecto>
                <proyecto-linea *ngIf="filtro == null || filtro == proyecto.estado" [proyecto]="proyecto"></proyecto-linea>
              </ng-container>
          </div>
        </div>
        <div *ngIf="opcion == 'Timeline'" class="nivelOcupacionContainer">
          <div class="graficaGeneralContainer completo">
            <div class="graficaHeader">
              <div class="titulo">
                <span>Timeline proyectos</span>
              </div>
              <div class="selector">
                <ng-select
                  [items]="plazos"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="selectedPlazoTimeline"
                  [clearable]="false"
                  [searchable]="false"
                  (change)="calcularTimeline()"
                ></ng-select>
              </div>
              <div class="radioCheckContainer">
                <div class="switchGeneralContainer">
                  <div class="switchContainer">
                    <span>Solo Activos</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7"
                      height="7"
                      viewBox="0 0 7 7"
                      [class.toggled]="potencialesTimeline"
                    >
                      <circle
                        id="Elipse_671"
                        data-name="Elipse 671"
                        cx="3.5"
                        cy="3.5"
                        r="3.5"
                        fill="#b7b8be"
                        opacity="0.576"
                      />
                    </svg>
                    <div class="switch-button">
                      <input
                        type="checkbox"
                        name="switch-button"
                        id="switch-label"
                        #switchLabel2
                        (change)="ispotencialesTimeline()"
                        [(ngModel)]="potencialesTimeline"
                        class="switch-button__checkbox"
                      />
                      <label
                        for="switch-label"
                        class="switch-button__label"
                      ></label>
                    </div>
                    <span>Potenciales</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="graficoContainer">
              <apx-chart
                #chart2
                [series]="chartOptions2.series"
                [chart]="chartOptions2.chart"
                [plotOptions]="chartOptions2.plotOptions"
                [xaxis]="chartOptions2.xaxis"
                [yaxis]="chartOptions2.yaxis"
                [dataLabels]="chartOptions2.dataLabels"
                [annotations]="chartOptions2.annotations"
              ></apx-chart>
            </div>
          </div>
        </div>

        <div *ngIf="opcion == 'Analítica'" class="nivelOcupacionContainer">
          <div class="graficaGeneralContainer">
            <div class="graficaHeader">
              <div class="titulo carga">
                <span>Cargas por compañero y semana</span>
              </div>
              <!--<div class="selector carga">
                <ng-select
                  [items]="departamentos"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="selectedDepartamento"
                  [clearable]="false"
                  [searchable]="false"
                ></ng-select>
              </div>-->
            </div>
            <div class="graficoContainer">
              <div id="dottedchart">
                <apx-chart
                  #dottedchart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [dataLabels]="chartOptions.dataLabels"
                  [title]="chartOptions.title"
                  [colors]="chartOptions.colors"
                  [plotOptions]="chartOptions.plotOptions"
                  [grid]="chartOptions.grid"
                  [xaxis]="chartOptions.xaxis"
                  [yaxis]="chartOptions.yaxis"
                ></apx-chart>
              </div>
            </div>
          </div>
          <div class="analisisSemanaContainer">
            <div class="circulo"></div>
          </div>
        </div>
        <div *ngIf="opcion == 'Analítica'" class="nivelOcupacionContainer">
          <div class="graficaGeneralContainer">
            <div class="graficaHeader">
              <div class="titulo">
                <span>Nivel de ocupación</span>
              </div>
              <div class="radioCheckContainer">
                <div class="switchGeneralContainer">
                  <div class="switchContainer">
                    <span>Potenciales</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7"
                      height="7"
                      viewBox="0 0 7 7"
                      [class.toggled]="toggled == true"
                    >
                      <circle
                        id="Elipse_671"
                        data-name="Elipse 671"
                        cx="3.5"
                        cy="3.5"
                        r="3.5"
                        fill="#b7b8be"
                        opacity="0.576"
                      />
                    </svg>
                    <div class="switch-button" (click)="isToggled()">
                      <input
                        type="checkbox"
                        name="switch-button"
                        id="switch-label"
                        #switchLabel
                        class="switch-button__checkbox"
                      />
                      <label
                        for="switch-label"
                        class="switch-button__label"
                      ></label>
                    </div>
                    <span>Solo Activos</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="graficoContainer">
              <div id="chart">
                <apx-chart
                  #chart
                  [series]="chartOptionsGrafica.series"
                  [chart]="chartOptionsGrafica.chart"
                  [xaxis]="chartOptionsGrafica.xaxis"
                  [markers]="chartOptionsGrafica.markers"
                  [yaxis]="chartOptionsGrafica.yaxis"
                  [dataLabels]="chartOptionsGrafica.dataLabels"
                  [title]="chartOptionsGrafica.title"
                  [fill]="chartOptionsGrafica.fill"
                  [tooltip]="chartOptionsGrafica.tooltip"
                  [legend]="chartOptionsGrafica.legend"
                  [plotOptions]="chartOptionsGrafica.plotOptions"
                  [grid]="chartOptionsGrafica.grid"
                ></apx-chart>
              </div>
            </div>
          </div>
          <div class="analisisSemanaContainer">
            <div class="circulo"></div>
          </div>
        </div>
      </div>
    </div>
</div>
