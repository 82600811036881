import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoadingComponent } from '../Components/loading/loading.component';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  protected iniciando: any = null;
  constructor(private dialog: MatDialog) {
    this.contador = 0;
  }
  open() {
    if (this.contador == 0) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.panelClass = 'loadingPopup';
      this.iniciando = this.dialog.open(LoadingComponent, dialogConfig);
      document.body.classList.add('loaderActive');
    }
    this.contador++;
    console.log(this.contador);
  }
  protected contador: number;
  closeContador() {
    this.contador--;
    console.log(this.contador);
    if (this.contador == 0) {
      this.iniciando.close();
      document.body.classList.remove('loaderActive');
    }
    if(this.contador < 0){
      this.contador = 0;
    }
  }
}
