import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class FinanzasService extends BaseService {

  constructor(protected http: HttpClient) {
    super();
  }

  getFinanzas(potencial=null) {    
    let url = this.urlApi + 'api/finanzas';
    if(potencial!=null){
      url = url + '&potencial=' + potencial;
    }
    this.http
      .get(url, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getFinanzas, args: [potencial] });
          this.checkStatusError(error);
        },
      });
  }

  getFacturas(empresa,potencial=null) {
    let url = this.urlApi + 'api/facturas?empresa=' + empresa;
    if(potencial!=null){
      url = url + '&potencial=' + potencial;
    }
    this.http
      .get(url, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.getFacturas, args: [empresa,potencial] });
          this.checkStatusError(error);
        },
      });
  }

  
  getFacturasTabla(num_devoluciones,num_pagina,potencial=null,ordenar=null,asc=null) {
    let query:string = "?num_devoluciones="+num_devoluciones+"&num_pagina="+num_pagina;
    if(ordenar!=null){
      query = query + "&ordenar="+ordenar;
    }
    if(asc!=null && asc){
      query = query + "&asc";
    }
    let url = this.urlApi + 'api/facturas/tabla'+query;
    if(potencial!=null){
      url = url + '&potencial=' + potencial;
    }
    this.http
      .get(url, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.getFacturasTabla, args: [num_devoluciones,num_pagina,potencial,ordenar,asc] });
          this.checkStatusError(error);
        },
      });
  }

  postFactura(factura) {
    this.http
      .post(this.urlApi + 'api/facturas', factura, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultUpdate",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.postFactura, args: [factura] });
          this.checkStatusError(error);
        },
      });
  }
  
  updateFactura(id, factura) {
    this.http
      .put(this.urlApi + 'api/facturas/' + id, factura, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultUpdate",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.updateFactura, args: [id, factura] });
          this.checkStatusError(error);
        },
      });
  }

  deleteFactura(id) {
    this.http
      .delete(this.urlApi + 'api/facturas/' + id, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultDelete",data);
        },
        error: (error) => {
          this.sendNextResult('resultDeleteError', error, { method: this.deleteFactura, args: [id] });
          this.checkStatusError(error);
        },
      });
  }
  
}
