import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanzasService } from 'src/app/Services/finanzas.service';

@Component({
  selector: 'Popup-factura',
  templateUrl: './popup-factura.component.html',
  styleUrls: ['./popup-factura.component.css']
})
export class PopupFacturaComponent implements OnInit {

  constructor( private finanzasSE: FinanzasService, public dialogRef: MatDialogRef<PopupFacturaComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }
id;
fecha_pago;
  ngOnInit(): void {
    this.id = this.modalData.id;
  }
  confirmarFactura(valor:boolean){
    if (valor) {
      this.finanzasSE.updateFactura(this.id, {pagada: true, fecha_pago: this.fecha_pago});
      this.finanzasSE.getResultUpdate().subscribe((value) => {
        if (value == null) {
          return;
        }
      this.closePopup({returnValue:true});
      });
    }else{
      this.closePopup({returnValue:false});
    }
  }
  closePopup(returnValue?:any){
    let p={returnValue:returnValue};
    this.dialogRef.close(p);
  }
}
