import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { DailyService } from 'src/app/Services/daily.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupService } from 'src/app/Services/popup.service';
import { PopupConfirmacionBorradorComponent } from '../popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/Services/login.service';
import { ValidarDailyComponent } from '../validar-daily/validar-daily.component';

@Component({
  selector: 'daily-component',
  templateUrl: './daily-component.component.html',
  styleUrls: ['./daily-component.component.css']
})
export class DailyComponentComponent extends UnsubscribesDestroy  implements OnInit {

  @Input() daily: any;
  @Input() idActivo: string;
  @Output() cambioEnActivo: any = new EventEmitter();
  @Output() refrescarDailys: any = new EventEmitter();

  colorFalta= '#b1beba';
   colorCompleto = '#36403d';
  //detectar cambio en el input idActivo
  ngOnChanges(changes: any) {
    if (changes.idActivo) {
      this.idActivo = changes.idActivo.currentValue;
      this.comprobarSiActivo();
    }
  }

  comprobarEnviado(){
    if(this.daily.completada || this.daily.validado){
      return this.colorCompleto;
    }else{
      return this.colorFalta;
    }
  }
  comprobarValidado(){
    if(this.daily.validado){
      return this.colorCompleto;
    }else{
      return this.colorFalta;
    }
  }
  constructor(protected popup: PopupService,
    private loginSE: LoginService,
    private notificacionesSE: NotificacionesService,
    private dailySE: DailyService,
    public loaderSE: LoaderService,
    protected http: HttpClient) { 
    super();
  }
  comprobarSiActivo(){
    this.activo = false;
    if(this.daily.id_clockify==null){
      return;
    }
    this.daily.id_clockify.forEach(id => {
      if(id == this.idActivo){
        this.activo = true;
      }
    });
  }
me;
hoy;
activo = false;
  environment;
  admin = false;
  ngOnInit(): void {
    this.admin = this.loginSE.isIntermedio();
    this.environment = environment;
    this.me = Number(localStorage.getItem('idUser'));
    this.hoy = new Date();
    let fecha = new Date(this.hoy);
    let dia:any = fecha.getDate();
    let mes:any = fecha.getMonth() + 1;
    let anio = fecha.getFullYear();
    if (dia < 10) {
      dia = '0' + dia;
    }
    if (mes < 10) {
      mes = '0' + mes;
    }
    let fechaString = anio + "-" + mes + "-" + dia;
    this.hoy = fechaString;
    this.comprobarSiActivo();
  }
  iniciarDaily(event,item){
    event.stopPropagation();
    let mensaje = '¿Empezar a trabajar?';
    if(this.activo){
      mensaje = '¿Terminar de trabajar?';
    }
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: mensaje,
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          if(this.activo){
            this.apiRestCallPatch();
          }else{
            this.apiRestCallPost(item);
          }
        }
        this.popup.clearData();
        this._unsubscribeIndividual.next('');
      });
  }

  apiRestCallPatch(){
    let ahora = new Date();
    let url = "https://api.clockify.me/api/v1/workspaces/"+localStorage.getItem('id_clockify_empresa')+"/user/"+localStorage.getItem('user_clockify')+"/time-entries";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Api-Key': localStorage.getItem('id_clockify')
    });
    let options = { headers: headers };
    let body = {
      "end": ahora
    }
    this.loaderSE.open();
    this.http.patch(url, body, options).subscribe((data:any) => {
      this.loaderSE.closeContador();
      this.notificacionesSE.showFeedBack('Daily finalizado correctamente');
      this.cambioEnActivo.emit();
      }
    );
  }


  apiRestCallPost(item){
    let url = "https://api.clockify.me/api/v1/workspaces/"+localStorage.getItem('id_clockify_empresa')+"/time-entries";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Api-Key': localStorage.getItem('id_clockify')
    });
    let options = { headers: headers };
    let body = {
      "name": "Daily",
      "description": item.nombre_tarea,
      "projectId": item.proyecto.id_clockify
    }
    this.http.post(url, body, options).subscribe((data:any) => {
      this.dailySE.clockifyDailyId(item.id,data.id);
      this.loaderSE.open();
      this.dailySE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
        if (value == null) {
          return;
        }
        this.refrescarDailys.emit();
        this.loaderSE.closeContador();
        this.dailySE.clearResultUpdate();
        this.notificacionesSE.showFeedBack('Daily iniciado correctamente');
        this.cambioEnActivo.emit();
        this._unsubscribeIndividual2.next('');
      }
      );
    }
    );
  }

  eliminarDaily(event){
    event.stopPropagation();
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Eliminar el daily?',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.dailySE.deleteDaily(this.daily.id);
          this.loaderSE.open();
          this.dailySE.getResultDelete().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this.dailySE.clearResultDelete();
            this.notificacionesSE.showFeedBack('Daily eliminado correctamente');
            this._unsubscribeIndividual2.next('');
            this.refrescarDailys.emit();
          }
          );
          this.popup.clearData();
          this._unsubscribeIndividual.next('');
        }
      });
  }

  validarDaily(event,validacion){
    if(!this.admin)
    return;
    event.stopPropagation();
    if(validacion){
        this.popup.openPopup(ValidarDailyComponent, {
          descripcion: '¿Pasar el daily a validado?',
          id: this.daily.id
        });
        this.popup
          .returnData()
          .pipe(takeUntil(this._unsubscribeIndividual))
          .subscribe((value) => {
            if (value == null) {
              return;
            }
            if (value.returnValue) {
              this.daily.validado = true;
            }
            this.popup.clearData();
            this._unsubscribeIndividual.next('');
          });
      }else{
        this.popup.openPopup(PopupConfirmacionBorradorComponent, {
          descripcion: '¿Pasar el daily a NO validado?'
        });
        this.popup
          .returnData()
          .pipe(takeUntil(this._unsubscribeIndividual))
          .subscribe((value) => {
            if (value == null) {
              return;
            }
            if (value.returnValue) {
              this.dailySE.validateDailyId(this.daily.id,validacion);
              this.loaderSE.open();
              this.dailySE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
                if (value == null) {
                  return;
                }
                this.loaderSE.closeContador();
                this.dailySE.clearResultUpdate();
                this._unsubscribeIndividual2.next('');
                this.refrescarDailys.emit();
              }
              );
            }
            this.popup.clearData();
            this._unsubscribeIndividual.next('');
          });
        }
      }


      /***contrastes ***/
       getRGB(c) {
        return parseInt(c, 16) || c
      }
      
       getsRGB(c) {
        return this.getRGB(c) / 255 <= 0.03928
          ? this.getRGB(c) / 255 / 12.92
          : Math.pow((this.getRGB(c) / 255 + 0.055) / 1.055, 2.4)
      }
      
       getLuminance(hexColor) {
        return (
          0.2126 * this.getsRGB(hexColor.substr(1, 2)) +
          0.7152 * this.getsRGB(hexColor.substr(3, 2)) +
          0.0722 * this.getsRGB(hexColor.substr(-2))
        )
      }
      
       getContrast(f, b) {
        const L1 = this.getLuminance(f)
        const L2 = this.getLuminance(b)
        return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
      }
      
       getTextColor(bgColor) {
        const whiteContrast = this.getContrast(bgColor, '#ffffff')
        const blackContrast = this.getContrast(bgColor, '#000000')
      
        return whiteContrast > blackContrast ? '#ffffff' : '#000000'
      }

}
