import { Injectable } from '@angular/core';
import { BaseService } from '../Base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MesesCerradosService extends BaseService {

  constructor(protected http: HttpClient) {
    super();
  }

  getMesesYear(year) {
    this.http
      .get(this.urlApi + 'api/mes/cerrado/'+year, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getMesesYear, args: [year] });
          this.checkStatusError(error);
        },
      });
  }  

  cerrarMes(year,mes) {    
    this.http
      .post(this.urlApi + 'api/mes/cerrado/'+year+"/"+mes,null, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {          
          this.sendNextResult('resultError', error, { method: this.cerrarMes, args: [year,mes] });
          this.checkStatusError(error);
        },
      });
  }

  abrirMes(year,mes) {
    this.http
      .delete(this.urlApi + 'api/mes/cerrado/'+year+"/"+mes, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {          
          this.sendNextResult('resultError', error, { method: this.abrirMes, args: [year,mes] });
          this.checkStatusError(error);
        },
      });
  }

}
