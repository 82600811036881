
import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { EmpresasService } from 'src/app/Services/empresas.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { LoginService } from 'src/app/Services/login.service';
import { UserService } from 'src/app/Services/user.service';
import { DailyService } from 'src/app/Services/daily.service';
import { PageEvent } from '@angular/material/paginator';
import { PopupService } from 'src/app/Services/popup.service';
import { PopupAddDailyComponent } from 'src/app/Components/popup-add-daily/popup-add-daily.component';
import { PopupConfirmacionBorradorComponent } from 'src/app/Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { PopupEditDailyComponent } from 'src/app/Components/popup-edit-daily/popup-edit-daily.component';
import { HttpClient } from '@angular/common/http';
import { CalendarOptions,EventInput} from '@fullcalendar/core'; // useful for typechecking
import { PopUpDailyVerificarComponent } from 'src/app/Components/pop-up-daily-verificar/pop-up-daily-verificar.component';

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.css']
})
export class DailyComponent
extends UnsubscribesDestroy
implements OnInit{
  diasSemana = [];
  cambioFecha(){
    //guardar en diasSemana los dias de la semana de la fecha seleccionada
    this.diasSemana = [];
    let fecha = new Date(this.fecha);
    let dia = fecha.getDay()-1;
    if(dia == -1){
      dia = 6;
    }
    let fechaAux = new Date(fecha);
    fechaAux.setDate(fecha.getDate() - dia);
    for(let i = 0; i < 7; i++){
      this.diasSemana.push(new Date(fechaAux));
      fechaAux.setDate(fechaAux.getDate() + 1);
    }
    this.buscarDaily();
  }
  mostrarFechaString(fecha){
    let dia = fecha.getDate();
    if(dia < 10){
      dia = '0' + dia;
    }
    let mes = fecha.getMonth() + 1;
    if(mes < 10){
      mes = '0' + mes;
    }
    let anio = fecha.getFullYear();
    let fechaString = anio + '-' + mes + '-' + dia;
    return fechaString;
  }
  decrementarUnDia(){
    let fecha = new Date(this.fecha);
    fecha.setDate(fecha.getDate() - 1);
    this.fecha = this.convertirFecha(fecha);
    this.cambioFecha();
  }
  incrementarUnDia(){
    let fecha = new Date(this.fecha);
    fecha.setDate(fecha.getDate() + 1);
    this.fecha = this.convertirFecha(fecha);
    this.cambioFecha();
  }
  seleccionarDia(fecha){
    this.fecha = this.convertirFecha(fecha);
    this.cambioFecha();
  }
  admin = false;
  trabajadores = [];
  idUser;
  
  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public router: Router,
    public userSE: UserService,
    public loaderSE: LoaderService,
    public empresaSE: EmpresasService,
    private dailySE: DailyService,
    public loginSE: LoginService,
    protected popup: PopupService,
    protected http: HttpClient
  ) {
    super();
  }
//Convertir fecha Datetime en formato yyyy/mm/dd
  convertirFecha(fecha) {
    let fechaConvertida = new Date(fecha);
    let anio = fechaConvertida.getFullYear();
    let mes:any = fechaConvertida.getMonth() + 1;
    let dia:any = fechaConvertida.getDate();
    if (mes < 10) {
      mes = '0' + mes;
    }
    if (dia < 10) {
      dia = '0' + dia;
    }
    let fechaFinal = anio + '-' + mes + '-' + dia;
    return fechaFinal;
  }
  me;
  daily = [];
  pageSize = 999;
  pagina = 0;
  cantidadPaginas = 1;
  clockify = false;
  idActivo = null;
  ngOnInit(): void {
    if (
      localStorage.getItem('token') === null &&
      localStorage.getItem('login') != 'login'
    ) {
      this.loginSE.desconectar();
    }
    if(localStorage.getItem('id_clockify') != null && localStorage.getItem('id_clockify') != ''){
      this.clockify = true;
      this.obtenerClockifyActivo();
    }
    this.admin = this.loginSE.isIntermedio();

    this.idUser = Number(localStorage.getItem('idUser'));
    this.me = Number(localStorage.getItem('idUser'));
    localStorage.setItem('lastRoute', this.router.url);
    if(this.admin){
    this.getAllUsersByEmpresa();
    }
    this.fecha = this.convertirFecha(new Date());
    this.cambioFecha();
    this.popup.openPopup(PopUpDailyVerificarComponent,null);    
        this.popup
        .returnData()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popup.clearData(); 
          this._unsubscribeIndividual.next('');
          //esperar 1 segundo
          setTimeout(() => {
            this.loaderSE.closeContador();
          }, 1000);
        });
    //this.buscarDaily();
  }

  handlePageEvent(e: PageEvent) {
    this.pagina = e.pageIndex;
    this.buscarDaily();
  }

  obtenerClockifyActivo(){
    this.dailySE.obtenerTimeEntryActual();
      this.dailySE.getResultIndividual().pipe(takeUntil(this._unsubscribeIndividual1)).subscribe((value) => {
        if (value == null) {
          return;
        }
        this.idActivo = null;
        if(value.length != 0){
          this.idActivo = value[0].id;
        }
        this._unsubscribeIndividual1.next('');
      });
  }

  buscarDaily(){
    
    if(this.admin){
    this.dailySE.getDailyByParameters(this.pageSize, this.pagina+1, this.idUser, this.fecha);
    }else{
      this.dailySE.getMyDailyByParameters(this.pageSize, this.pagina+1, this.fecha);
    }
    this.loaderSE.open();
    this.dailySE.getResult().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
      if (value == null) {
        return;
      }
      this.loaderSE.closeContador();
      this.daily = value.data;
      this.cantidadPaginas = value.total;
      this.dailySE.clearResult();
      this._unsubscribeIndividual2.next('');
    }
    );
  }
  fecha;
  getAllUsersByEmpresa() {
    this.userSE.getAllUsersByEmpresa(false);
    this.loaderSE.open();
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual3))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        value.forEach((element) => {
          if (!this.trabajadores.includes(element)) {
            this.trabajadores = [...this.trabajadores, element];
          }
        });
        this.trabajadores.map((i) => { i.fullName = i.nombre + ' ' + i.apellidos; return i; });
        this._unsubscribeIndividual3.next('');
      });
  }
  cambioUsuario(idUser){
    if(idUser == this.idUser){
      this.idUser = null;
    }else{
      this.idUser = idUser;
    }
    this.pagina = 0;
    this.buscarDaily();
  }
  addDaily(){    
    let data = {
      trabajadores: this.trabajadores,
      idUser:this.idUser,
      fecha:this.fecha
    };
    if(!this.admin){
      data.idUser = -1;
    }
    this.popup.openPopup(PopupAddDailyComponent, data);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {        
        if (value == null) {
          return;
        }
        if (value.returnValueDaily) {
          this.buscarDaily()
          this.popup.clearData();
          this._unsubscribeIndividual.next('');
        }
      });        
  }

  completarDaily(item){
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Completar el daily?',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
        //Completar el daily
          let d = {
            porcentaje:1,
            completada:true,
            categoria:null,
          };
          if(item.categoria != null){
            d.categoria = item.categoria.id;
          }
          this.dailySE.editarDaily(item.id,d);
          this.loaderSE.open();
          this.dailySE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this.dailySE.clearResultUpdate();
            this._unsubscribeIndividual2.next('');
            this.buscarDaily();

          });
        }
        this.popup.clearData();
        this._unsubscribeIndividual.next('');
      });

  }
  editarDaily(daily){
    this.popup.openPopup(PopupEditDailyComponent, {
      daily: daily
    });    
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual3))
      .subscribe((value) => {        
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.buscarDaily();
          this.popup.clearData();
          this._unsubscribeIndividual3.next('');
        }
      });
  }

}
