import { Component, OnInit } from '@angular/core';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { CosteEmpresaService } from 'src/app/Services/coste-empresa.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { DepartamentosService } from 'src/app/Services/departamentos.service';
import { PopupService } from 'src/app/Services/popup.service';
import { PopupConfirmacionBorradorComponent } from 'src/app/Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { DailyService } from 'src/app/Services/daily.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent extends UnsubscribesDestroy implements OnInit {

  constructor( private costeEmpresaSE: CosteEmpresaService, private dailySE: DailyService,
    private popupSE: PopupService ,protected loaderSE: LoaderService, private notificacionesSE: NotificacionesService, private departamentoSE: DepartamentosService) {super(); }
  anyos = [];
  anyoSeleccionado = 0;
  costeMeses = [0,0,0,0,0,0,0,0,0,0,0,0];
  newPerfil = "";
  newColorIncidencia = "";
  newIncidencia = "";
  meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
  ngOnInit(): void {
    this.guardarAnyos();
    this.cambiarAnyo();
    this.obtenerDepartamentos();
    this.obtenerIncidencias();
  }
  perfiles = [];

  addIncidencia(){
    if(this.newIncidencia == ""){
      this.notificacionesSE.showError("El nombre de la incidencia no puede estar vacío");
      return;
    }
    if(this.newColorIncidencia == ""){
      this.notificacionesSE.showError("El color de la incidencia no puede estar vacío");
      return;
    }
    this.loaderSE.open();
    this.dailySE.crearCategoriaDaily(this.newIncidencia,this.newColorIncidencia);
    this.dailySE.getResult().pipe(takeUntil(this._unsubscribeIndividual3))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this._unsubscribeIndividual3.next('');
      this.notificacionesSE.showFeedBack("Incidencia creada correctamente");
      this.newIncidencia = "";
      this.newColorIncidencia = "";
      this.loaderSE.closeContador();
      this.dailySE.clearResult();
      this.obtenerIncidencias();
    });
  }

  addPerfil(){
    if(this.newPerfil == ""){
      this.notificacionesSE.showError("El nombre del perfil no puede estar vacío");
      return;
    }
    this.loaderSE.open();
    this.departamentoSE.postDepartamento({nombre:this.newPerfil});
    this.departamentoSE.getResult().pipe(takeUntil(this._unsubscribeIndividual3))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this._unsubscribeIndividual3.next('');
      this.notificacionesSE.showFeedBack("Perfil creado correctamente");
      this.newPerfil = "";
      this.loaderSE.closeContador();
      this.departamentoSE.clearResult();
      this.obtenerDepartamentos();
    });
  }

  incidencias = [];
  obtenerIncidencias(){
    this.dailySE.getCategoriasDaily();
    this.loaderSE.open();
    this.dailySE.getResult().pipe(takeUntil(this._unsubscribeIndividual3)).subscribe((value) => {
      if (value == null) {
        return;
      }
      console.log(value);
      this.incidencias = value;
      this._unsubscribeIndividual3.next('');
      this.dailySE.clearResult();
      this.loaderSE.closeContador();
    });
  }

  eliminarIncidencia(idPerfil){    
    this.popupSE.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Eliminar este tipo de incidencia?',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this._unsubscribeIndividual.next('');
        if (value.returnValue == true) {
          this.loaderSE.open();
          this.dailySE.eliminarCategoriaDaily(idPerfil);
          this.dailySE.getResultDelete().pipe(takeUntil(this._unsubscribeIndividual2))
          .subscribe((value) => {
            if (value == null) {
              return;
            }
            this.notificacionesSE.showFeedBack("Tipo de incidencia eliminado correctamente");
            this.loaderSE.closeContador();
            this._unsubscribeIndividual2.next('');
            this.obtenerIncidencias();
          });
        }
      });
  }

  eliminarPerfil(idPerfil){    
    this.popupSE.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Eliminar este perfil?',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this._unsubscribeIndividual.next('');
        if (value.returnValue == true) {
          this.loaderSE.open();
          this.departamentoSE.deleteDepartamento(idPerfil);
          this.departamentoSE.getResultDelete().pipe(takeUntil(this._unsubscribeIndividual2))
          .subscribe((value) => {
            if (value == null) {
              return;
            }
            this.notificacionesSE.showFeedBack("Perfil eliminado correctamente");
            this.loaderSE.closeContador();
            this._unsubscribeIndividual2.next('');
            this.obtenerDepartamentos();
          });
        }
      });
  }
  obtenerDepartamentos(){
    this.loaderSE.open();
    this.departamentoSE.getDepartamentosByEmpresa();
    this.departamentoSE.getResult().pipe(takeUntil(this._unsubscribeIndividual2))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this.perfiles = value;
      this._unsubscribeIndividual2.next('');
      this.loaderSE.closeContador();
    });
  }

  guardarAnyos(){    
    //guardar en anyos el año anterior, el actual y el que viene
    let anyoActual = new Date().getFullYear();
    this.anyoSeleccionado = anyoActual;
    this.anyos.push(anyoActual - 1);
    this.anyos.push(anyoActual);
    this.anyos.push(anyoActual + 1);
  }

  cambiarAnyo(){
    console.log(this.anyoSeleccionado);
    this.loaderSE.open();
    //obtener costes de los meses del anyo seleccionado
    this.costeEmpresaSE.getCosteEmpresaByYear(this.anyoSeleccionado);
    this.costeEmpresaSE.getResult().pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this._unsubscribeIndividual.next('');
      this.costeMeses = this.costeEmpresaSE.convertirObjetoMesesEnArray(value);
      this.loaderSE.closeContador();
    });


    
    this.costeEmpresaSE.getCosteIndirectosEmpresaByYear(this.anyoSeleccionado);
    this.costeEmpresaSE.getResultIndividual().pipe(takeUntil(this._unsubscribeIndividual2))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this._unsubscribeIndividual2.next('');
      this.costeIndirectoMeses = this.costeEmpresaSE.convertirObjetoMesesEnArray(value);
      this.loaderSE.closeContador();
    });

    
  }

  costeIndirectoMeses = [0,0,0,0,0,0,0,0,0,0,0,0];
guaradrAnyoIndirecto(){
  this.loaderSE.open();
  this.costeEmpresaSE.postCosteEmpresaIndirectoByYear(this.anyoSeleccionado, this.costeEmpresaSE.convertirArrayMesesEnObjeto(this.costeIndirectoMeses));
  this.costeEmpresaSE.getResult().pipe(takeUntil(this._unsubscribeIndividual3))
  .subscribe((value) => {
    if (value == null) {
      return;
    }
    this._unsubscribeIndividual3.next('');
    console.log(value);
    this.loaderSE.closeContador();
    this.notificacionesSE.showFeedBack('Costes guardados correctamente');
  });
}

  guaradrAnyo(){
    this.loaderSE.open();
    this.costeEmpresaSE.postCosteEmpresaByYear(this.anyoSeleccionado, this.costeEmpresaSE.convertirArrayMesesEnObjeto(this.costeMeses));
    this.costeEmpresaSE.getResult().pipe(takeUntil(this._unsubscribeIndividual3))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this._unsubscribeIndividual3.next('');
      console.log(value);
      this.loaderSE.closeContador();
      this.notificacionesSE.showFeedBack('Costes guardados correctamente');
    });
  }


}
