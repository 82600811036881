import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class FamiliaCosteService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }
  
  getFamilias() {    
    let url = this.urlApi + 'api/familia/coste';
    this.http
      .get(url, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getFamilias, args: [] });
          this.checkStatusError(error);
        },
      });
  }
}
