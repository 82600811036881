import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { resultHttp } from '../Interfaces/resultHttp';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class PresupuestoService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  getPresupuesto(id) {    
    this.http
      .get(
        this.urlApi + 'api/presupuesto/' + id,
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.getPresupuesto, args: [id] });
          this.checkStatusError(error);
        },
      });
  }

  cerrarPresupuesto(presupuesto) {    
    this.http
      .post(this.urlApi + 'api/presupuesto/cerrar', presupuesto, this.getHeader())
      .subscribe({
        next: (data) => {
          let retu = new resultHttp();
          retu.ok = true;
          retu.mensaje = data['message'];
          retu.status = data['status'];
          retu.data = data['data'];
          this.sendNextResult("resultUpdate",retu);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.cerrarPresupuesto, args: [presupuesto] });
          this.checkStatusError(error);
        },
      });
  }


  addPresupuesto(presupuesto) {    
    this.http
      .post(this.urlApi + 'api/presupuesto', presupuesto, this.getHeader())
      .subscribe({
        next: (data) => {
          let retu = new resultHttp();
          retu.ok = true;
          retu.mensaje = data['message'];
          retu.status = data['status'];
          retu.data = data['data'];
          this.sendNextResult("resultUpdate",retu);
        },
        error: (error) => {
          this.sendNextResult('resultUpdateError', error, { method: this.addPresupuesto, args: [presupuesto] });
          this.checkStatusError(error);
        },
      });
  }

  editProyecto(id, proyecto) {
    this.http
      .put(this.urlApi + 'api/proyecto/' + id, proyecto, this.getHeader())
      .subscribe({
        next: (data) => {
          let retu = new resultHttp();
          retu.ok = true;
          retu.mensaje = data['message'];
          retu.status = data['status'];
          this.sendNextResult("resultUpdate",retu);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.editProyecto, args: [id,proyecto] });
          this.checkStatusError(error);
        },
      });
  }

  obtenerIndirecto(){
    this.http
      .get(
        this.urlApi + 'api/indirecto/hora',
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.obtenerIndirecto, args: [] });
          this.checkStatusError(error);
        },
      });
  }
}
