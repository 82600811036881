import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'estado-proyecto',
  templateUrl: './estado-proyecto.component.html',
  styleUrls: ['./estado-proyecto.component.css']
})
export class EstadoProyectoComponent implements OnInit {

  constructor() { }

  @Input() proyecto: any;
  rentabilidadMal = false;
  rentabilidadAnterior: number = 0;
  ngOnInit(): void {
    this.grafica.series[0].data = this.proyecto.rentabilidad;
    this.rentabilidadAnterior = this.proyecto.rentabilidad[this.proyecto.rentabilidad.length-1] - this.proyecto.rentabilidad[this.proyecto.rentabilidad.length-2];
    if(this.rentabilidadAnterior < 0){
      this.rentabilidadMal = true;
      this.rentabilidadAnterior = this.rentabilidadAnterior * -1;
    }
  }
  @ViewChild('chart') chart!: ChartComponent;
  
  grafica: any = {
    annotations: {
      xaxis: [
        {
          x: new Date().getTime(),
          borderColor: '#ccc',
          width: 5,
          strokeDashArray: 0          
        }
      ]
    },
    series: [
      {
        name: "Objetivo",
        data: []
      }
    ],
    chart: {
      animations: {
        enabled: false
      },
      height: 70,
      width: 90,
      toolbar:{
        show:false
      },
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2,
      curve: "straight",
    },
    grid: {
      show: false,
        padding: {
          top: 0,
          bottom: 0,
          left: 0
        }
      
    },
    xaxis: {
      show:false,
      labels: {
        show: false,
      },      
      axisTicks: {
        show: false,
      }
    },
    yaxis: {
      show:false,
    }
  }

}
