

<div class="popupGeneralContainer">
  <label for="title" class="title">Progreso de tarea - {{daily.nombre_tarea}}</label>
  <div class="body">
    <menu-navegacion class="especialNavegation" [colorSecundario]="true"  [condensed]="false" [redondo]="true" [opcionPorDefecto]="tipo"
      [opciones]="['Completado','No completado']"
      (emitOpcionSeleccionada)="opcionSeleccionadaTipo($event)"></menu-navegacion>
    <div class="data" *ngIf="tipo == 'No completado'">
      <div class="porcentaje">
        <div class="fakeInput">
          <span>{{(daily.porcentaje / 100) | percent}}</span>
          <input *ngIf="!daily.completada" required [(ngModel)]="daily.porcentaje" type="number" min="0" max="100">

        </div>
        <div class="progress-container" [style]="'--width: '+daily.porcentaje+'%'">
          <progress max="100" [value]="daily.porcentaje" name="progress" ngDefaultControl></progress>

          <mat-slider [value]="daily.porcentaje" step="1" [max]="100" (input)="daily.porcentaje = $event.value;">
            <input matSliderThumb>
          </mat-slider>
        </div>

      </div>
      <div class="doubleColumn">
        <div class="customInput">
          <label>Categoria</label>
          <ng-select class="popupSelector" [items]="categorias" placeholder="Categoría" bindLabel="nombre"
            bindValue="id" [(ngModel)]="daily.categoria"></ng-select>
        </div>
        <div class="customInput">
          <label>Motivo</label>
          <input required placeholder="Motivo" [(ngModel)]="daily.motivo">
        </div>
      </div>
    </div>
    <div class="data" *ngIf="tipo == 'Completado'">
      <div class="porcentaje completado">
        <div class="fakeInput">
          <span>100%</span>
        </div>
        <div class="progress-container" [style]="'--width: 100%'">
          <progress max="100" [value]="100" name="progress" ngDefaultControl></progress>
        </div>

      </div>

    </div>
  </div>
  <div class="footer">
    <button (click)="editarDaily(false)" type="button">Cancelar</button>
    <button class="confirmar" (click)="editarDaily(true)" type="button">Guardar</button>
  </div>
</div>
