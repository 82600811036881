import {Component,Inject,OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { ProyectosService } from 'src/app/Services/proyectos.service';

@Component({
  selector: 'app-pop-up-presupuesto',
  templateUrl: './pop-up-presupuesto.component.html',
  styleUrls: ['./pop-up-presupuesto.component.css']
})
export class PopUpPresupuestoComponent extends UnsubscribesDestroy implements OnInit {
  constructor(
    private proyectosSE: ProyectosService,
    private loaderSE: LoaderService,
    private notificacionesSE: NotificacionesService,
    public dialogRef: MatDialogRef<PopUpPresupuestoComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
  }
  nombre=null;
  abreviatura = null;
  clienteId;
  fechaIni=null;
  fechaFin=null;
  coste_dia = null;
  multiplicador = null;
  dias = 0;
  ngOnInit(): void {
    this.clienteId = this.modalData.id;
  }
  
  confirmarGuardarBorrador(valor: boolean) {
    this.closePopup(valor);
  }

  closePopup(returnValue?: any, id=null) {
    let p;
    if(id!=null){
      p = { returnValuePropuesta: returnValue, id:id };
    }else{
      p = { returnValuePropuesta: returnValue };
    }
    this.dialogRef.close(p);
  }

  generarFactura(){
    let fases = [
      {
        "nombre": "Presupuesto",
        "procesos": [
          {
            "nombre": "Presupuesto",
            "horas": this.dias * 8,
            "fecha_ini": this.fechaIni,
            "fecha_fin": this.fechaFin,
            'empleados': []
          }
        ]
      }
    ];
    this.proyectosSE.addProyecto({'potencial':true,'abreviatura':this.abreviatura,'nombre':this.nombre,'cliente':this.clienteId,'etiqueta':'','contacto':'','sector':'','fechaIni':this.fechaIni,'fechaFin':this.fechaFin,'coste_dia':this.coste_dia,'multiplicador':this.multiplicador,'fases':fases});
    this.loaderSE.open();
    this.proyectosSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.notificacionesSE.showFeedBack(value.mensaje);
        this._unsubscribeIndividual.next('');
        this.closePopup(true,value.data.id);
      });
    this.proyectosSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.notificacionesSE.showError(value.mensaje);
        this._unsubscribeIndividual.next('');
      });
    }

    precio = 0;
cambiarPrecio(){
  if(this.precio!=null){
    this.dias = this.precio / this.coste_dia / this.multiplicador;
  }else{
    this.dias = 0;
  }
}
cambiarDias(){
  if(this.dias!=null){
    this.precio = this.dias * this.coste_dia * this.multiplicador;
  }else{
    this.precio = 0;
  }
}
fijarDias:boolean = true;

}

