<div class="linea" [routerLink]="['/proyectos/'+proyecto.id]">
  <div class="nombre">
    <span class="color" [ngStyle]="{ background: proyecto.etiqueta }"></span>
    <span>{{proyecto.abreviatura}} | {{ proyecto.nombre }}</span>

  </div>
  <div class="progreso">
    <span class="indicador">
      <div class="estado" [class.error]="proyecto.estadisticas.avanceReal-proyecto.estadisticas.avancePlanificado < 0"><img class="flechaVerde" src="/assets/icons/FlechaVerde.png"><img class="flechaRoja" src="/assets/icons/FlechaRoja.png">{{proyecto.estadisticas.avanceReal-proyecto.estadisticas.avancePlanificado| percent: "1.0-1"}}</div></span>
      <div class="barraProgreso">
        <div class="valor">{{proyecto.estadisticas.avanceReal| percent: "1.0-0"}}</div>
        <div class="barra">
          <div class="contenido" [style.width]="proyecto.estadisticas.avanceReal * 100 + '%'"></div>
        </div>
      </div>
    
  </div>
  <div class="rentabilidad">
    <span class="indicador">
      <div class="estado" [class.error]="(proyecto.estadisticas.avanceReal * proyecto.presupuesto) / (proyecto.estadisticas.costeReal) - proyecto.multiplicador < 0"><img class="flechaVerde" src="/assets/icons/FlechaVerde.png"><img class="flechaRoja" src="/assets/icons/FlechaRoja.png"> {{ (proyecto.estadisticas.avanceReal * proyecto.presupuesto) / (proyecto.estadisticas.costeReal) - proyecto.multiplicador | percent: "1.0-2"}}</div></span>

  </div>
  <div class="presupuesto">
    <span>{{ proyecto.presupuesto | currency: 'EUR' }}</span>
  </div>
  <div class="equipo">
    <ng-template
      ngFor
      let-item
      [ngForOf]="proyecto.empleados"
      let-i="index"
    >
    
    <ng-container *ngIf="item.profile == null">
        <span *ngIf="i < 4">{{ item.nombre | slice: 0:1}}{{ item.apellidos | slice: 0:1 }}</span>
    </ng-container>
    <ng-container *ngIf="item.profile != null">
        <img *ngIf="i < 4" [src]="environment.urlImg + item.profile">
    </ng-container>
    </ng-template>
    <span *ngIf="proyecto.empleados.length > 4">+{{ proyecto.empleados.length - 4 }}</span>
  </div>
</div>