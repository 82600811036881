<div class="dashboardGeneralContainer">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'vacaciones'"></sidebar>
    </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="Vacaciones"></app-header-bar>
      </div>
  
      <div class="dataBodyContainer"> 
                
      </div>
    </div>
  </div>
  