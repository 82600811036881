import {Component,Inject,OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DailyService } from 'src/app/Services/daily.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
@Component({
  selector: 'app-pop-up-daily-verificar',
  templateUrl: './pop-up-daily-verificar.component.html',
  styleUrls: ['./pop-up-daily-verificar.component.css']
})
export class PopUpDailyVerificarComponent extends UnsubscribesDestroy implements OnInit {
  customOptions: OwlOptions = {
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 10,
    dots: true,
    navSpeed: 700,
    items: 1
  }
  constructor(
    private dailySE: DailyService,
    public loaderSE: LoaderService,
    public dialogRef: MatDialogRef<PopUpDailyVerificarComponent>,

    @Inject(MAT_DIALOG_DATA) public modalData: any) {
      super();
     }
daylies = [];
categorias = [];
nombre = null;

  ngOnInit(): void {
    this.nombre = localStorage.getItem('nombre');
    this.dailySE.getMyNotValidated();    
    this.dailySE.getResultIndividual().pipe(takeUntil(this._unsubscribeIndividual1)).subscribe((value) => {
        if (value == null) {
          return;
        }
        this.daylies = value;
        this._unsubscribeIndividual1.next('');
        if(this.daylies.length == 0){
          this.closePopup();
          return;
        }
        this.dailySE.getCategoriasDaily();
        this.loaderSE.open();
        this.dailySE.getResult().pipe(takeUntil(this._unsubscribeIndividual)).subscribe((value) => {
          if (value == null) {
            return;
          }
          this.categorias = value;
          this._unsubscribeIndividual.next('');
          this.dailySE.clearResult();
          this.loaderSE.closeContador();
        });
      }
    );
  }
  categoria = null;
  motivo = null;
  confirmarGuardarBorrador(valor: boolean) {
    this.closePopup(valor);
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  hecho(id:number){

    //obtener el daily

    let daily = this.daylies.find(item => item.id === id);
    daily.completada = true;
    daily.porcentaje = 1;
    console.log(daily);
    //editar el daily
    this.editarDaily(daily);
    //eliminar de la lista



  }
  editarDaily(daily){
    daily.enviado = true;
    console.log(daily);
    this.dailySE.editarDaily(daily.id,daily);
    this.dailySE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
      if (value == null) {
        return;
      }
      this.loaderSE.closeContador();
      this.dailySE.clearResultUpdate();
      this._unsubscribeIndividual2.next('');
      this.daylies = this.daylies.filter(item => item.id !== daily.id);
      if(this.daylies.length == 0){
        this.closePopup();
        return;
      }
    });

  }
  mostrarMotivo = false;
  idActual = null;
  fallado(id:number){
    if(this.mostrarMotivo){
      this.idActual = null;
    }else{
      this.idActual = id;
    }
    this.mostrarMotivo = !this.mostrarMotivo;
    this.categoria = null;
    this.motivo = null;
}

aceptarCambio(){
  let daily = this.daylies.find(item => item.id === this.idActual);
  daily.completada = false;
  daily.motivo = this.motivo;
  daily.porcentaje = 0;
  daily.categoria = this.categoria;
  this.mostrarMotivo = false;
  this.idActual = null;
  this.categoria = null;
  this.motivo = null;
  this.editarDaily(daily);
}

}
