import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { sha256 } from 'js-sha256';
import { resultHttp } from '../Interfaces/resultHttp';

@Injectable({
  providedIn: 'root'
})
export class DefaultBaseService {
  protected _result=new BehaviorSubject<any>(null);
  protected _resultAuxiliar=new BehaviorSubject<any>(null);
  protected _resultIndividual=new BehaviorSubject<any>(null);
  protected _resultUpdate=new BehaviorSubject<any>(null);
  protected _resultCreate=new BehaviorSubject<any>(null);
  protected _resultDelete=new BehaviorSubject<any>(null);
  protected _resultUpdateError=new BehaviorSubject<any>(null);
  protected _resultCreateError=new BehaviorSubject<any>(null);
  protected _resultDeleteError=new BehaviorSubject<any>(null);
  protected waitRefreshToken=10;
  protected httpHeaders={headers: new HttpHeaders()}
  constructor() { }
  

  
  protected isExpireToken(token?){
    let tokp;
    tokp=localStorage.getItem("token");
    if(token!=null){
      tokp=token;
    }
    if(tokp!=null){
      let tok=tokp.split(".")[1];
      let exp=JSON.parse(atob(tok)).exp*1000;
      let p=Math.abs(new Date().getTime() - new Date(exp).getTime());
      if(new Date()>new Date(exp)){
        return true;
      }
      return Math.ceil(p/ (1000*60))>this.waitRefreshToken;
    }
    return true;
  }
  getResult(){return this._result;}
  getResultAuxiliar(){return this._resultAuxiliar;}
  getResultIndividual(){ return this._resultIndividual;}
  getResultUpdate(){return this._resultUpdate;}
  getResultCreate(){return this._resultCreate;}
  getResultDelete(){return this._resultDelete;}
  clearResult(){this._result.next(null);}
  clearResultAuxiliar(){this._resultAuxiliar.next(null);}
  clearResultIndividual(){this._resultIndividual.next(null);}
  clearResultUpdate(){this._resultUpdate.next(null);}
  clearResultCreate(){this._resultCreate.next(null);}
  clearResultDelete(){this._resultDelete.next(null);}
  getResultUpdateError(){return this._resultUpdateError;}
  getResultCreateError(){return this._resultCreateError;}
  getResultDeleteError(){return this._resultDeleteError;}
  clearResultUpdateError(){this._resultUpdateError.next(null);}
  clearResultCreateError(){this._resultCreateError.next(null);}
  clearResultDeleteError(){this._resultDeleteError.next(null);}
  protected sendQueryIds(name,ids){
    let send={query:[]};
    if(!(ids instanceof Array)){send.query.push(ids);}else{send.query=ids;}
    send[name]=send["query"];
    delete send["query"];
    return send;
  }
  protected sendNextResultHttp(data,type:"create"|"delete"|"update",isError:boolean=false){
    let p=new resultHttp();
    let j:Function;
    p.ok=isError;
    p.status=data["status"];
    if(data["data"]!=null){
      p.data=data["data"];
    }
    if(isError){
      p.mensaje=data["error"]["message"];
      if(type=="create"){
        this._resultCreateError.next(p);
        j=this.clearResultCreateError;
      }else if(type=="delete"){
        this._resultDeleteError.next(p);
        j=this.clearResultDeleteError;
      }else{
        this._resultUpdateError.next(p);
        j=this.clearResultUpdateError;
      }
    }else{
      p.mensaje=data["message"];
      if(type=="create"){
        this._resultCreate.next(p);
        j=this.clearResultCreate;
      }else if(type=="delete"){
        this._resultDelete.next(p);
        j=this.clearResultDelete;
      }else{
        this._resultUpdate.next(p);
        j=this.clearResultUpdate;
      }
    }
    setTimeout(()=>{
      this[j.name]();
    },150);
  }
  protected getUserTypeToken(type?){
    let tokp;
    tokp=localStorage.getItem("token");
    if(type!=null){
      tokp=type;
    }
    if(tokp!=null){
      let tok=tokp.split(".")[1];
      let roles:Array<any>=JSON.parse(atob(tok)).roles;
      return roles;
    }
    return null;
  }
  protected serializeLogin(user,password){
    let passCry=sha256(password);
    let p=JSON.stringify({username:user,password:passCry});
    return btoa(p);
  }
  protected deserializeLogin(string){
    return JSON.parse(atob(string));
  }
  protected optionsGet(options){
    if(options==null){ return '';}
    let ret="?";
    let objKey=Object.keys(options);
    for (let i = 0; i < Object.keys(options).length; i++) {
      let element = objKey[i];
      if(options[element] instanceof Array){
        let j=options[element].join(",");
        ret+=element+"="+j;
      }else{
        if(i>0){ret+="&";}
        ret+=element+"="+options[element];
      }
    }
    return ret;
  }
  
  protected resultHttpErrorConnection(error){
    let p=new resultHttp();
    if(error["status"]==0&&!navigator.onLine){
      p.mensaje="No tienes conexión";
    }else{
      p.mensaje=error["error"]["message"];
    }
    p.ok=false;
    p.status=error["status"];
    return p;
  }

}
