import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'grafico-pie',
  templateUrl: './grafico-pie.component.html',
  styleUrls: ['./grafico-pie.component.css']
})
export class GraficoPieComponent implements OnInit {

  @Input() valores = [];
  @Input() colores = [];
  @Input() width = '200px';
  @Input() grosor = 8;
  @Input() separacion = 10;
  @Input() mostrarTotal:boolean = true;
  @Input() mostrarFondo:boolean = false;
  @Input() individual = undefined;
  @Input() fontSize = '18pt';
  @Input() unidad = '';

  ngOnChanges(changes) {
    console.log(changes);
    if(changes.colores != undefined){
      if(changes.colores.currentValue.length == 0){
       this.generarColoresQuefalta();
    }
  }
}


  constructor() { }

  ngOnInit(): void {
    this.generarColoresQuefalta();
  }
  total(){
    if(this.individual){
      return Math.round(this.individual);
    }
    let suma = 0;
    for(let i = 0; i < this.valores.length; i++){
      suma += this.valores[i];
    }
    return suma;
  }
  calculoIndividual(val){
    let pixel = 472 - 472/100 * val + this.separacion;
    if(pixel > 473){
      pixel = 473;
    }
    return pixel+'px'; 
  }
  calculo(val){
    let pixel = 472 - 472/100 * this.convertirEnPorcentaje(val) + this.separacion;
    if(pixel > 473){
      pixel = 473;
    }
    return pixel+'px'; 
  }
  rotacion(pos){
    let suma = 0;
    for(let i = 0; i < pos; i++){
      suma += this.convertirEnPorcentaje(this.valores[i]);
    }
    return 'rotate(calc(90deg + 1deg * 3.6 * '+suma+')) scaleY(-1)';
  }

  convertirEnPorcentaje(val){
    let suma = 0;
    for(let i = 0; i < this.valores.length; i++){
      suma += this.valores[i];
    }
    return val/suma*100;
  }
  generarColoresQuefalta(){
    for(let i = 0; i < this.valores.length; i++){
      if(this.colores[i] == undefined){
        this.colores[i] = this.generarColorRandomHEX();
      }
    }
  }
  generarColorRandomHEX(){
    let color = '#';
    for(let i = 0; i < 6; i++){
      color += Math.floor(Math.random()*16).toString(16);
    }
    return color;
  }

    
}
