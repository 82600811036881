

<div class="popupGeneralContainer">
  <label for="title" class="title">Estimación de tiempos: Método PERT - #{{modalData.numero}}</label>
  <div class="body">
    <div class="procesoContainer">
      <span>
        <img src="/assets/icons/cantidadProcesosIcon.svg" alt="">
        <ng-container *ngIf="modalData.nombre == null">
          Proceso sin nombre
        </ng-container>
        <ng-container *ngIf="modalData.nombre != null">
          {{modalData.nombre}}
        </ng-container>
      </span>
      <div class="fechas">
        <div class="fecha">
          <label for="">Inicio proceso</label>
          <span>
            <ng-container *ngIf="modalData.inicio == null">
              -
            </ng-container>
              <ng-container *ngIf="modalData.inicio != null">
              {{modalData.inicio | date:'dd/MM/yyyy'}}
            </ng-container>
          </span>
        </div>
        <div class="fecha">
          <label for="">Fin proceso</label>
          <span>
            <ng-container *ngIf="modalData.fin == null">
            -
          </ng-container>
            <ng-container *ngIf="modalData.fin != null">
            {{modalData.fin | date:'dd/MM/yyyy'}}
          </ng-container>
          </span>
        </div>
      </div>
    </div>
    <div class="tiemposContainer">
      <div class="customInput">
        <label>Tiempo optimista</label>
        <div class="fakeInput">
          <span [class.placeholder]="!optimista">
            <ng-container *ngIf="optimista">
              {{optimista}} horas
            </ng-container>
            <ng-container *ngIf="!optimista">
              <span>80 horas</span>
            </ng-container>

          </span>
          <input required placeholder="80 horas" [(ngModel)]="optimista">
        </div>
      </div>
      <div class="customInput">
        <label>Tiempo realista</label>
        <div class="fakeInput">
          <span [class.placeholder]="!realista">
            <ng-container *ngIf="realista">
              {{realista}} horas
            </ng-container>
            <ng-container *ngIf="!realista">
              <span>80 horas</span>
            </ng-container>

          </span>
          <input required placeholder="80 horas" [(ngModel)]="realista">
        </div>
      </div>
      <div class="customInput">
        <label>Tiempo pesimista</label>
        <div class="fakeInput">
          <span [class.placeholder]="!pesimista">
            <ng-container *ngIf="pesimista">
              {{pesimista}} horas
            </ng-container>
            <ng-container *ngIf="!pesimista">
              <span>80 horas</span>
            </ng-container>

          </span>
          <input required placeholder="80 horas" [(ngModel)]="pesimista">
        </div>
      </div>
      <button [class.disabled]="!optimista || !realista || !pesimista" (click)="estimarTiempo()">
        Estimar tiempos
      </button>
    </div>
    <div class="tiemposFinales" *ngIf="moreDatosOpened">
      <div class="tiempoEstimado">
        <label for="">
          Tiempo estimado <br>
          (Te)
        </label>
        <span>{{tiempoEstimado|number:'0.0-2'}} horas</span>
      </div>
      <div class="desviacion">
        <div class="tipica">
          <label for="">
            Desviación típica
            <br>
            <small>Escoge la desviación mas adecuada para tu proyecto</small>
          </label>
          <div class="slider">
            <label for="">{{step}}</label>
            <span>{{desviacionSumador|number:'0.0-2'}} horas</span>
            <mat-slider min="-3" max="3" step="0.5" value="0" (valueChange)="getDesviacion($event)">
              <input matSliderThumb>
            </mat-slider>
          </div>
        </div>
        <div class="probabilidad">
          <label for="">Probabilidad de exito</label>
          <span>{{probabilidad}}%</span>
        </div>
      </div>
      <div class="tiempoFinal">
        <label for="">
          Tiempo final <br>
          (Te)
        </label>
        <span>{{tiempoFinal|number:'0.0-2'}} horas</span>
      </div>
    </div>
  </div>
  <div class="footer">
    <button  type="button" (click)="forceClosePopup()">Cancelar</button>
    <button type="button" (click)="enviar()">Guardar</button>
  </div>
</div>
