<div class="contenedor">
    <div class="tarjeta">
        <div class="cabecera">
            <div class="titulo">Fennech</div>
            <span class="cerrar"></span>
        </div>
        <div class="titulo">Selecciona un plan</div>
        <div class="selectorPlan">
            <div *ngFor="let plan of planes" [class.seleccionado]="plan == planSeleccionado" class="plan" (click)="planSeleccionado = plan">
                <div class="nombre">{{plan.nombre}}</div>
                <div class="empleados">({{plan.minEmpleados}} - {{plan.maxEmpleados}})</div>
                <div *ngIf="frecuenciaSeleccionada.nombre == 'Mensual'" class="precio">{{planSeleccionado.precioMensual|currency:'EUR'}}</div>
                <div *ngIf="frecuenciaSeleccionada.nombre == 'Anual'" class="precio">{{planSeleccionado.precio|currency:'EUR'}}</div>
            </div>
        </div>
        <div class="titulo">Selecciona un periodo</div>
        <div class="selectorPeriodo">
            <div class="periodo" (click)="frecuenciaSeleccionada = 'Mensual'" [class.seleccionado]="'Mensual' == frecuenciaSeleccionada">
                <div class="nombre">Mensual</div>
                <div class="precio">{{planSeleccionado.precioMensual|currency:'EUR'}} / mes</div>
            </div>
            <div class="periodo" (click)="frecuenciaSeleccionada = 'Anual'" [class.seleccionado]="'Anual' == frecuenciaSeleccionada">
                <div class="nombre">Anual</div>
                <div class="precio">{{planSeleccionado.precio|currency:'EUR'}} / mes</div>
            </div>
        </div>
        <div class="titulo">Información de la tarjeta</div>
        <div class="informacionTarjetaPago">
            <div class="numeroTarjeta">
                <div class="titulo">Número de tarjeta</div>
                <input [class.error]="numeroTargetaError" (input)="comprobarDatosTarjeta()" [(ngModel)]="numeroTargeta" type="text" placeholder="1234 5678 9012 3456">
            </div>
            <div class="fecha">
                <div class="titulo">Fecha de expiración</div>
                <input [class.error]="caducidadError" (input)="comprobarDatosTarjeta()" [(ngModel)]="caducidad" type="text" placeholder="MM/AA">
            </div>
            <div class="codigo">
                <div class="titulo">CVV</div>
                <input [class.error]="cvvError" (input)="comprobarDatosTarjeta()" [(ngModel)]="cvv" type="text" placeholder="123">
            </div>
        </div>
        <div class="titulo">Resumen de la compra</div>
        <div class="resumenCompra">
            <div class="total">
                <div class="nombre">Total a pagar</div>
                <div *ngIf="frecuenciaSeleccionada == 'Anual'" class="precio">{{planSeleccionado.precio * 12|currency:'EUR'}}</div>
                <div *ngIf="frecuenciaSeleccionada == 'Mensual'" class="precio">{{planSeleccionado.precioMensual|currency:'EUR'}}</div>
            </div>
        </div>
        <div class="botonPagar">
            <button [disabled]="!datosTarjetaOk || planSeleccionado == null || frecuenciaSeleccionada == null" >Pagar</button>
        </div>
    </div>
</div>