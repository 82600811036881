import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CosteEmpresaService } from 'src/app/Services/coste-empresa.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { PopUpCosteComponent } from 'src/app/Components/pop-up-coste/pop-up-coste.component';
import { PopupService } from 'src/app/Services/popup.service';

@Component({
  selector: 'app-coste-mes',
  templateUrl: './coste-mes.component.html',
  styleUrls: ['./coste-mes.component.css']
})
export class CosteMesComponent extends UnsubscribesDestroy implements OnInit {

  constructor(
    protected loaderSE: LoaderService,
    protected popup: PopupService,
    protected costeSE: CosteEmpresaService,
    protected activatedRoute : ActivatedRoute) {
      super();
    }
year = 0;
month = 0;
cargarInformacion(){
  this.loaderSE.open();
  this.costeSE.getCostesYearMonth(this.year,this.month);
  this.costeSE.getResult()
  .pipe(takeUntil(this._unsubscribeIndividual2))
  .subscribe((value) => {
    if (value == null) {
      return;
    }
    this.res = value;
    this.loaderSE.closeContador();
    this._unsubscribeIndividual2.next('');
  });
}
  ngOnInit(): void {
    if(this.activatedRoute.snapshot.params['year'] != undefined){
      this.year = this.activatedRoute.snapshot.params['year'];
      this.month = this.activatedRoute.snapshot.params['month']
    }
    this.cargarInformacion();    
  }
res;
  meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

  abrirPopUp(tipo){
    
    let data = {tipo:tipo,year:this.year,month:this.month};
    this.popup.openPopup(PopUpCosteComponent,data);    
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValue) {
        this.popup.clearData();
        this.cargarInformacion();        
        this._unsubscribeIndividual.next('');
      }
    });

    
  }
}
