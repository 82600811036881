<div class="confirmarGuardarBorradorGeneralContainer">
  <div class="confirmarGuardarBorradorConainer">
    <div class="bodyGuardarBorrador">
      <span>{{ descripcion }}</span>
      <div class="bodyBotones">
        <button  class="confirmar" (click)="confirmarGuardarBorrador(true)" type="button">
          Sí
        </button>
        <button (click)="confirmarGuardarBorrador(false)" type="button">
          No
        </button>
      </div>
    </div>
  </div>
</div>
