<div class="tarjeta">
  <h1>{{ titulo }}</h1>
  <button class="atras" (click)="cerrarPopUp($event)">x</button
  ><input type="file" (change)="fileChangeEvent($event)" />
  <div class="imagen">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="aspectRatio"
      [resizeToWidth]="tam"
      format="jpeg"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
  <button (click)="enviar()" class="btn_destacado">Ok</button>
</div>
