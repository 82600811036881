<div class="confirmarCerrarSesionGeneralContainer" >
    <div class="confirmarCerrarSesionConainer">
        <div class="bodyCerrarSesion">
          <span *ngIf="id==null">Nuevo Hito de pago</span>
          <span *ngIf="id!=null">Editar Hito de pago</span>
          <div class="campos">
            <div class="linea">
                <div *ngIf="fases.length > 0" class="campo">
                    <label>Fase</label> 
                    <ng-select
                    [items]="fases"
                    placeholder="Selecciona la fase"
                    bindLabel="nombre"
                    [(ngModel)]="fase"
                    (change)="cambioFase()"
                    >
                    </ng-select>
                </div>
                <div *ngIf="procesos.length > 0" class="campo">
                    <label>Proceso</label> 
                    <ng-select
                    [items]="procesos"
                    placeholder="Selecciona el proceso"
                    bindLabel="nombre"
                    [(ngModel)]="proceso"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="linea">
              <div *ngIf="fase==null" class="campo">
                <label>Fecha emisión</label>
                <input required type="date" [(ngModel)]="fecha">
              </div>
              <div class="campo">
                <label>Concepto</label> 
                <input required type="text" [(ngModel)]="concepto">
              </div>
              <div class="campo">
                <label>Cantidad (Máx. {{presupuestoRestante|currency:'EUR'}})</label>
                <input required type="number" [(ngModel)]="cantidad" [class.error]="cantidad > presupuestoRestante" [max]="presupuestoRestante">
              </div> 
            </div>
          </div>
          <div class="bodyBotones">
            <button class="confirmar" (click)="crearFactura(true)" type="button">Guardar</button>
            <button (click)="crearFactura(false)" type="button">Cancelar</button>
            
          </div>
        </div>
    </div>
  </div>
  