import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { Empresa } from '../Interfaces/empresa';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class EmpresasService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  getMyEmpresa() {    
    this.http
      .get(this.urlApi + 'api/empresa/me', this.getHeader())
      .subscribe({
        next: (data) => {
          let empresa: Empresa = Empresa.converObjectToFront(data);
          this.sendNextResult("result",empresa);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getMyEmpresa, args: [] });
          this.checkStatusError(error);
        },
      });
  }

  getStatusEmpresa() {    
    this.http
      .get(this.urlApi + 'api/status', this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getStatusEmpresa, args: [] });
          this.checkStatusError(error);
        },
      });
  }
}
