import { Injectable } from '@angular/core';
import { BaseService } from './Base.service';
import { HttpClient } from '@angular/common/http';
import { sha256 } from 'js-sha256';
import { Router } from '@angular/router';
import { TokenManagmentService } from './TokenManagment.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Acciones de logeo
 */
export class LoginService extends BaseService {
  constructor(protected http: HttpClient, private router: Router) {
    super();
  }
  cerrarSesion() {
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem("login", "needLogin");
  }
  checkLogin(credentials: Login) {
    credentials.password = this.sha256Convert(credentials.password);
    this.http.post(this.urlApi + 'api/login_check', credentials).subscribe({
      next: (data) => {
       this.logIn(data);
       this.sendNextResult("result",data);
      },
      error: (error) => {
        this.sendNextResult('resultError', error)
        this.checkStatusError(error);
      },
    });
  }

getTokenRefresh() {
    return localStorage.getItem("refreshToken");    
}
  refreshToken() {
    let refreshActual = this.getTokenRefresh();
    this.http.post(this.urlApi + 'api/refreshToken', { refresh_token: refreshActual }).subscribe({
      next: (data) => {
        this.setToken(data["token"]);
        this.setRefreshToken(data["refresh_token"]);
        TokenManagmentService.completeRefreshToken();
      },
      error: (error) => {
        if(refreshActual != this.getTokenRefresh()){
          TokenManagmentService.completeRefreshToken();
        }else{
          TokenManagmentService.tokenLost();
        }
      },
    });
  }

  saveTokenPush(tokenPush){
    this.http.post(this.urlApi+"api/user/token_push", {tokenPush:tokenPush}, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult('resultUpdate', data)
      },
      error: (error) => {
        this.sendNextResult('resultUpdateError', error)
        this.checkStatusError(error);
      },
    });
  }

  
  private logIn(data){
    this.setToken(data["token"])
    this.setTipoUser(data["token"]);
    this.setRefreshToken(data["refresh_token"])
    localStorage.setItem("login", "loged")
    //this.saveTokenPush(localStorage.getItem("tokenPush"));
  }


  desconectar() {    
    this.cerrarSesion();
    this.router.navigate(['/login']);
  }

  getTypeUser() {
    if (this.isLogin()) {
      let tipos = JSON.parse(localStorage.getItem('tipoUser'));
      if (tipos.includes('0')) {
        return 'admin';
      } else {
        return 'none';
      }
    }
    return 'none';
  }
  isLogin() {
    if (localStorage.getItem('login') == null) {
      localStorage.setItem('login', 'needLogin');
    }
    if (localStorage.getItem('login') == 'needLogin') {
      return false;
    }
    return true;
  }

  isIntermedio() {
    if (this.isLogin()) {
      let tipos = JSON.parse(localStorage.getItem('tipoUser'));
      if (tipos.includes('0')||tipos.includes('1')) {
        return true;
      }
    }
    return false;
  }

  isAdmin() {
    if (this.isLogin()) {
      let tipos = JSON.parse(localStorage.getItem('tipoUser'));
      if (tipos.includes('0')) {
        return true;
      }
    }
    return false;
  }

  sha256Convert(string) {
    return sha256(string);
  }
}


export interface Login {
  username:string,
  password: string
}