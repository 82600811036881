<div class="proyectosGeneralContainer">
  <div class="sideBarGeneralContainer">
    <sidebar [elementoActivo]="'proyectos'"></sidebar>
  </div>
  <div class="dataGeneralContainer">
  <div class="dataHeaderContainer">
    <app-header-bar
      titulo="Información proyecto"
      [cerrar]="true"
    ></app-header-bar>
  </div>
  <div class="dataBodyContainer">
    <div class="cabeceraProyecto tarjeta">
      <div class="izquierda">
        <div class="color" [ngStyle]="{ background: proyecto.etiqueta }"></div>
        <div class="nombreProyecto">{{ proyecto.abreviatura }} | {{ proyecto.nombre }}</div>
        <span class="separador"></span>
        <div class="estadoProyecto">
          <ng-container *ngIf="proyecto.estado == 2">✅ Entregado</ng-container>
          <ng-container *ngIf="proyecto.estado == -1">📦 Archivado</ng-container>
          <ng-container *ngIf="proyecto.estado == 1">🛠️ Entregando...</ng-container>
          <ng-container *ngIf="proyecto.estado == 0">⏸️ Pausado</ng-container>
        </div>
        <div class="acciones">
          <span (click)="cambioEstado(0)" *ngIf="proyecto.estado == 1">⏸️ Pausar</span>
          <span (click)="cambioEstado(2)" *ngIf="proyecto.estado == 1">🏁 Finalizar</span>
          <span (click)="cambioEstado(-1)" *ngIf="proyecto.estado == 1">📦 Archivar</span>
          <span (click)="cambioEstado(3)" *ngIf="proyecto.estado == 1">❄️ Hibernar</span>
          <span (click)="cambioEstado(1)" *ngIf="proyecto.estado == 0 || proyecto.estado == -1 || proyecto.estado == 2">▶️ Reanudar</span>
        </div>
        <span class="separador"></span>
        <div class="faseActual">
          Fase de producción
        </div>
      </div>
      <div class="derecha">
        <menu-navegacion [icons]="iconos" [opcionPorDefecto]="'Dashboard'" [opciones]="menuGeneral" (emitOpcionSeleccionada)="opcionSeleccionadaMenu($event)"></menu-navegacion>
      </div>
    </div>
    <ng-container *ngIf="opcionMenu == 'Dashboard'">
      <div class="indicadores">
        <div class="indicador">
          <div class="estado" [class.error]="estadisticas.avanceReal-estadisticas.avancePlanificado < 0"><img class="flechaVerde" src="/assets/icons/FlechaVerde.png"><img class="flechaRoja" src="/assets/icons/FlechaRoja.png">{{estadisticas.avanceReal-estadisticas.avancePlanificado| percent: "1.0-1"}}</div>
          <div class="info">
            <div class="titulo">Avance</div>
            <div class="valor" *ngIf="estadisticas.avanceReal < estadisticas.avancePlanificado">Por debajo del plan</div>
            <div class="valor" *ngIf="estadisticas.avanceReal >= estadisticas.avancePlanificado">Por encima del plan</div>
          </div>
          <div class="porcentaje">
            <div class="titulo">Planificado</div>
            <div class="valor">{{estadisticas.avancePlanificado| percent: "1.0-2"  }}</div>
          </div>
          <div class="porcentaje">
            <div class="titulo" [class.error]="estadisticas.avanceReal < estadisticas.avancePlanificado">Real</div>
            <div class="valor" [class.error]="estadisticas.avanceReal < estadisticas.avancePlanificado">{{estadisticas.avanceReal| percent: "1.0-2"  }}</div>
          </div>
        </div>
        <div class="indicador">
          <div class="estado" [class.error]="(estadisticas.avanceReal * proyecto.presupuesto) / (estadisticas.costeReal) - proyecto.multiplicador < 0"><img class="flechaVerde" src="/assets/icons/FlechaVerde.png"><img class="flechaRoja" src="/assets/icons/FlechaRoja.png"> {{ (estadisticas.avanceReal * proyecto.presupuesto) / (estadisticas.costeReal) - proyecto.multiplicador | percent: "1.0-2"}}</div>
          <div class="info">
            <div class="titulo">Margen</div>
            <div class="valor" *ngIf="(estadisticas.avanceReal * proyecto.presupuesto) / (estadisticas.costeReal) - proyecto.multiplicador < 0">Bajada de rentabilidad</div>
            <div class="valor" *ngIf="(estadisticas.avanceReal * proyecto.presupuesto) / (estadisticas.costeReal) - proyecto.multiplicador > 0">Subida de rentabilidad</div>
          </div>
          <div class="porcentaje">
            <div class="titulo">Objetivo</div>
            <div class="valor">{{ proyecto.multiplicador - 1 | percent: "1.0-2"  }}</div>
          </div>
          <div class="porcentaje">
            <div class="titulo" [class.error]="proyecto.multiplicador > (estadisticas.avanceReal * proyecto.presupuesto) / (estadisticas.costeReal)">Real</div>
            <div class="valor" [class.error]="proyecto.multiplicador > (estadisticas.avanceReal * proyecto.presupuesto) / (estadisticas.costeReal)">{{(estadisticas.avanceReal * proyecto.presupuesto) / (estadisticas.costeReal) - 1 | percent: "1.0-2"}}</div>
          </div>
        </div>
        <div class="indicador">
          <div class="estado estado_presupuesto" [class.error]="(estadisticas.avanceReal * proyecto.presupuesto) - (estadisticas.costeReal * proyecto.multiplicador) < 0"><img style="transform: rotate(180deg);" class="flechaVerde" src="/assets/icons/FlechaVerde.png"><img style="transform: rotate(180deg);" class="flechaRoja" src="/assets/icons/FlechaRoja.png"></div>
          <div class="info">
            <div class="titulo">Presupuesto</div>
            <div class="valor" *ngIf="(estadisticas.avanceReal * proyecto.presupuesto) - (estadisticas.costeReal * proyecto.multiplicador) < 0">Aumento de costes</div>
            <div class="valor" *ngIf="(estadisticas.avanceReal * proyecto.presupuesto) - (estadisticas.costeReal * proyecto.multiplicador) > 0">Reducción de costes</div>
          </div>
          <div class="presupuesto">
            <div class="diferencia" [class.error]="(estadisticas.avanceReal * proyecto.presupuesto) - (estadisticas.costeReal * proyecto.multiplicador) < 0">N = {{((estadisticas.avanceReal * proyecto.presupuesto) - (estadisticas.costeReal * proyecto.multiplicador)) * -1 | currency:'EUR'}}</div>
          </div>
        </div>
      </div>      
      <div class="graficas">
        <div class="grafica"><div class="titulo">Avance del proyecto</div>
          <apx-chart
          *ngIf="avanceGrafica.series[0].data.length > 0"
          #chartAvance
          [series]="avanceGrafica.series"
          [chart]="avanceGrafica.chart"
          [xaxis]="avanceGrafica.xaxis"
          [yaxis]="avanceGrafica.yaxis"
          [dataLabels]="avanceGrafica.dataLabels"
          [grid]="avanceGrafica.grid"
          [stroke]="avanceGrafica.stroke"
          [annotations]="avanceGrafica.annotations"
          [tooltip]="avanceGrafica.tooltip"
          [forecastDataPoints]="avanceGrafica.forecastDataPoints"
          [legend]="{ show: false}"
          ></apx-chart>
        </div>
        <div class="grafica"><div class="titulo">Margen histórico</div>
          <apx-chart
          *ngIf="margenGrafica.series[0].data.length > 0"
          #chartMargen
          [series]="margenGrafica.series"
          [chart]="margenGrafica.chart"
          [xaxis]="margenGrafica.xaxis"
          [yaxis]="margenGrafica.yaxis"
          [dataLabels]="margenGrafica.dataLabels"
          [grid]="margenGrafica.grid"
          [stroke]="margenGrafica.stroke"
          [annotations]="margenGrafica.annotations"
          [tooltip]="margenGrafica.tooltip"
          [legend]="{ show: false}"
          ></apx-chart>
        </div>
        <div class="grafica"><div class="titulo">Presupuesto histórico</div>
          <apx-chart
          *ngIf="presupuestoGrafica.series[0].data.length > 0"
          #chartPresupuesto
          [series]="presupuestoGrafica.series"
          [chart]="presupuestoGrafica.chart"
          [xaxis]="presupuestoGrafica.xaxis"
          [yaxis]="presupuestoGrafica.yaxis"
          [dataLabels]="presupuestoGrafica.dataLabels"
          [grid]="presupuestoGrafica.grid"
          [stroke]="presupuestoGrafica.stroke"
          [annotations]="presupuestoGrafica.annotations"
          [tooltip]="presupuestoGrafica.tooltip"
          [legend]="{ show: false}"
          ></apx-chart>
        </div>
        <div class="grafica"><div class="titulo">Incidencias</div>
        <div class="bitacora">
          <div class="lineaIncidencia" *ngFor="let incidencia of proyecto.incidencias">
            <span class="icono">
              <img [src]="incidencia.tipo.icon">
              <span>{{incidencia.fecha|date:"d/M/yy"}}</span>
            </span>
            <div class="info">
              <b>{{incidencia.tipo.nombre}}</b>
              <p [innerHTML]="incidencia.descripcion"></p>
            </div>
          
          </div>
        </div>
      <div *ngIf="false" class="columnas">
        <div class="graficaCircular">
          <grafico-pie [valores]="valoresDaily" [colores]="coloresDaily" width="300px"></grafico-pie>
        </div>
<div class="info">
<div class="selector">
  <div class="opcion" [class.seleccionado]="graficoDaily=='Categoria'" (click)="graficaDailys('Categoria')">Categoria</div>
  <div class="opcion" [class.seleccionado]="graficoDaily=='Usuario'" (click)="graficaDailys('Usuario')">Integrante</div>
  <div class="opcion" [class.seleccionado]="graficoDaily=='Proceso'" (click)="graficaDailys('Proceso')">Proceso</div>
</div>
<div class="tablaIncidencias">
  <div *ngFor="let incidencia of infoDaily">
    <span class="pastilla" [style.color]="incidencia.color">{{incidencia.nombre}}</span>
    <span class="valor">{{incidencia.cant}}</span>
  </div>
</div>
</div>
        </div>
      </div>
      </div>
    </ng-container>
    <ng-container *ngIf="opcionMenu == 'Anterior'">
      <div class="dataBodyHeader">
        <div
          class="dataBodyHeaderIdentificador"
          [ngStyle]="{ background: proyecto.etiqueta }"
        ></div>
        <div class="dataBodyInformacion">
          <div class="nombre">
            <span>{{ proyecto.abreviatura }}</span>
            <span>{{ proyecto.nombre }}</span>
          </div>
          <div class="otrosDatos">
            <div class="potencialoProyecto" (click)="activarProyecto()">
              <ng-container *ngIf="proyecto.potencial == 0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <circle
                    id="Elipse_565"
                    data-name="Elipse 565"
                    cx="8"
                    cy="8"
                    r="8"
                    fill="#6cc09a"
                  />
                </svg>
                <span>Proyecto Activo</span>
              </ng-container>
              <ng-container *ngIf="proyecto.potencial == 1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <circle
                    id="Elipse_565"
                    data-name="Elipse 565"
                    cx="8"
                    cy="8"
                    r="8"
                    fill="#eb6262"
                  />
                </svg>
                <span>Proyecto Inactivo</span>
              </ng-container>
            </div>
            
            <div *ngIf="false && proyecto.potencial == 0" class="estadoProyecto" (click)="activarProyecto()">
                <span> 🏁 Finalizar Proyecto</span>
            </div>

            <div class="estadoProyecto">
              <ng-container *ngIf="proyecto.estado == 2">✅</ng-container>
              <ng-container *ngIf="proyecto.estado == -1">📦</ng-container>
              <ng-container *ngIf="proyecto.estado == 0 || proyecto.estado == 1">🛠️</ng-container>
            </div>
            <div class="fechaFinalizacion">
              <span>Fecha Finalización</span>
              <span>{{ proyecto.fecha_fin.split("T")[0] }}</span>
            </div>
            <div class="botonEditar">
              <button type="button" [routerLink]="['/proyectos/editar-proyecto/'+proyecto.id]">Editar</button>
            </div>
          </div>
        </div>
      </div>
      <div class="gantt">
        <div class="zoom">
          <p>Vista</p><menu-navegacion [opcionPorDefecto]="'12M'" [opciones]="tamanyosMeses" (emitOpcionSeleccionada)="cambioTamDias($event)"></menu-navegacion>
        </div>
        <gantt [fases]="fasesGantt" [anchoDia]="anchoDia"></gantt>
      </div>
      <div class="alertasGeneralContainer" >
        <div class="alertaTitulo">
          <span>Alertas</span>
        </div>
        <div class="alertasContainer">
          <div class="alertas">
            <div>{{ proyecto.horas | number: "1.0-2" }}</div>
            <div>Horas Presupuestadas</div>
          </div>
          <div class="alertas">
            <div>{{ proyecto.presupuesto | currency:'EUR'}}</div>
            <div>Presupuesto asignado</div>
          </div>
          <div class="alertas">
            <div>{{ proyecto.multiplicador | number: "1.0-2" }}</div>
            <div>Rendimiento esperado</div>
          </div>
        </div>        
        <div class="alertasContainer">
          <div class="alertas">
            <div>
              {{ proyecto.horasTrabajadas | number: "1.0-2" }}
            </div>
            <div>Horas Imputadas</div>
          </div>
          <div class="alertas" >
            <div>
              {{ estadisticas.costeReal | currency:'EUR' }}
            </div>
            <div>Presupuesto consumido</div>
          </div>        
          <div class="alertas" >
            <div>
              {{ proyecto.presupuesto / (estadisticas.costeReal)  | number:'1.0-2' }}
            </div>
            <div>Rendimiento</div>
          </div>
        </div>
      </div>
    <div class="procesosGeneralContainer" [class.pagos]="tipoPopup == 'pagos'">
      <div class="procesosTitulo">
        <span>Procesos</span>
      </div>
      <div class="menuNavegacionContainer" *ngIf="tipoPopup != 'pagos'">
        <menu-navegacion
          *ngIf="filtroFases.length > 2"
          [opcionPorDefecto]="'Todas'"
          [opciones]="filtroFases"
          (emitOpcionSeleccionada)="opcionSeleccionada($event)"
        ></menu-navegacion>
      </div>
      <div class="procesosContainer">
        <ng-container *ngFor="let proceso of procesos; let i = index">
          <ng-container *ngIf="opcion == 'Todas'">
            <div
              class="proceso"
            >
              <div class="procesoHeader">
                <div class="procesoNombre">
                  <span>{{ proceso.nombre }}</span>
                </div>
                <div>
                  <span>{{ i + 1 | number: "2.0-0" }}.</span>
                </div>
              </div>
              <div class="procesoPorcentaje">
                <span>{{ proceso.completado | percent:"0.0-2" }}</span>
                <progress max="1" value="{{ proceso.completado }}"></progress>
              </div>
              <div class="procesoEquipoFecha">
                <div>
                </div>
                <div>
                  <span>Fecha Finalización</span>
                  <span>{{ proceso.fecha_fin | date: "dd/MM/yyyy" }}</span>
                </div>
              </div>
              <div class="procesoBoton" *ngIf="tipoPopup != 'pagos'">
                <button
                  type="button"
                  (click)="openAddProceso('editarProceso', proceso,i + 1)"
                >
                  Editar Proceso
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="proceso.fase.nombre == opcion">
            <div
              class="proceso"
            >
              <div class="procesoHeader">
                <div class="procesoNombre">
                  <span>{{ proceso.nombre }}</span>
                </div>
                <div>
                </div>
                <div>
                  <span>{{ i + 1 | number: "2.0-0" }}.</span>
                </div>
              </div>
              <div class="procesoPorcentaje">
                <span>{{ proceso.completado | percent:"0.0-2"}}</span>
                <progress
                  max="1"
                  value="{{ proceso.completado * 1 }}"
                ></progress>
              </div>
              <div class="procesoEquipoFecha">
                <div>
                </div>
                <div>
                  <span>Fecha Finalización</span>
                  <span>{{ proceso.fecha_fin.split("T")[0] }}</span>
                </div>
              </div>
              <div class="procesoBoton">
                <button
                  type="button"
                  (click)="openAddProceso('editarProceso', proceso,i + 1)"
                >
                  Editar Proceso
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    </ng-container>
    <ng-container *ngIf="opcionMenu == 'Costes'">

      <div class="tarjetasInformacion">
        <div class="tarjetaInfo">
          <div class="nombre">Costes totales</div>
          <div class="valor">{{ costeTotal | currency:'EUR' }}</div>
        </div>
        <div class="tarjetaInfo">
          <div class="nombre">Costes directos</div>
          <div class="valor">{{ estadisticas.costeReal + costeDirecto | currency:'EUR' }}</div>
        </div>
        <div class="tarjetaInfo">
          <div class="nombre">Costes indirectos</div>
          <div class="valor">{{ costeIndirecto | currency:'EUR' }}</div>
        </div>
        <div class="tarjetaInfo">
          <div class="nombre">Costes periodo</div>
          <div class="valor">{{ costePeriodo | currency:'EUR' }}</div>
        </div>
      </div>
      <div class="acordeones">
        <div class="acordeon" [class.cerrado]="acordeon[0]">
          <div class="cabecera">
            <button class="btn" (click)="abrirPopUp($event)">+</button>
            <div class="titulo">Costes directos</div>
            <span class="flechaAcordeon" (click)="acordeon[0] = !acordeon[0]"><img src="/assets/icons/arrow.png"></span>
          </div>
          <div class="tabla empleados">
            <div class="cabecera">
              <div class="titulo">Empleados</div>
            </div>
            <div class="leyenda">
              <span>Concepto</span>
              <span>Horas</span>
              <span>Coste</span>
            </div>
            <div class="linea">
              <div>Coste empleados</div>
              <div>{{ estadisticas.horasClockify }} horas</div>
              <div>{{ estadisticas.costeReal | currency:'EUR' }}</div>
            </div>
          </div>
          <div class="tabla otros" *ngIf="this.costes.Fijo.length > 0">
            <div class="cabecera">
              <div class="titulo">Otros conceptos de gastos</div>
            </div>
            <div class="leyenda">
              <span>Concepto</span>
              <span>Familia</span>
              <span>Coste</span>
            </div>  
            <div *ngFor="let coste of this.costes.Fijo" class="linea">
              <div>{{ coste.concepto }}</div>
              <div>{{ coste.familia.nombre }}</div>
              <div>{{ coste.cantidad | currency:'EUR' }}</div>
            </div>          
          </div>
        </div>

        <div class="acordeon" [class.cerrado]="acordeon[1]">
          <div class="cabecera">
            <button class="btn" (click)="abrirPopUp($event)">+</button>
            <div class="titulo">Costes indirectos</div>
            <span class="flechaAcordeon" (click)="acordeon[1] = !acordeon[1]"><img src="/assets/icons/arrow.png"></span>
          </div>
          <div class="tabla empleados">
            <div class="cabecera">
              <div class="titulo">Empleados</div>
            </div>
            <div class="leyenda">
              <span>Concepto</span>
              <span>Horas</span>
              <span>Coste</span>
            </div>
          </div>
          <div class="tabla otros" *ngIf="this.costes.Variable.length > 0">
            <div class="cabecera">
              <div class="titulo">Otros conceptos de gastos</div>
            </div>
            <div class="leyenda">
              <span>Concepto</span>
              <span>Familia</span>
              <span>Coste</span>
            </div>  
            <div *ngFor="let coste of this.costes.Variable" class="linea">
              <div>{{ coste.concepto }}</div>
              <div>{{ coste.familia.nombre }}</div>
              <div>{{ coste.cantidad | currency:'EUR' }}</div>
            </div>          
          </div>
        </div>

        
        <div class="acordeon" [class.cerrado]="acordeon[2]">
          <div class="cabecera">
            <button class="btn" (click)="abrirPopUp($event)">+</button>
            <div class="titulo">Costes periodo</div>
            <span class="flechaAcordeon" (click)="acordeon[2] = !acordeon[2]"><img src="/assets/icons/arrow.png"></span>
          </div>
          <div class="tabla otros" *ngIf="this.costes.Periodo.length > 0">
            <div class="cabecera">
              <div class="titulo">Conceptos de gastos</div>
            </div>
            <div class="leyenda">
              <span>Concepto</span>
              <span>Familia</span>
              <span>Coste</span>
            </div>  
            <div *ngFor="let coste of this.costes.Periodo" class="linea">
              <div>{{ coste.concepto }}</div>
              <div>{{ coste.familia.nombre }}</div>
              <div>{{ coste.cantidad | currency:'EUR' }}</div>
            </div>          
          </div>
        </div>
      </div>
    </ng-container>

  <div class="equipoGeneralContainer" *ngIf="tipoPopup != 'pagos'">
    <!--<div class="equipoTitulo">
      <span>Equipo</span>
    </div>
    <div class="integrantesContainer">
      <div class="tarjetaInformacion" *ngFor="let trabajador of trabajadores" (click)="abrirInformacionEmpleado(trabajador)">
        <div>
          <div class="decoracionEquipo" [ngStyle]="{background: trabajador.colorDepartamento}"></div>
          <div class="circuloProgreso">
            <svg viewBox="0 0 200 200">
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stop-color="#00bc9b" />
                  <stop offset="100%" stop-color="#5eaefd" />
                </linearGradient>
              </defs>
              <path stroke-width="6" fill="none" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                style="z-index: 0;stroke: #c7c7c7;"></path>
              <path class="{{trabajador.nombre.split(' ')[0]}}" stroke="url(#gradient)" stroke-width="6" fill="none"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" stroke-dashoffset="472px"></path>
            </svg>
            <img src="../../../assets/Images/default.png" alt="">
          </div>
          <div class="nombre">
            <p>{{trabajador.nombre}}</p>
          </div>
          <div class="contadorHoras">
            <div>
              <label>Horas totales</label>
              <label>{{trabajador.horasTotales}}</label>
            </div>
            <div>
              <label>Horas Extra</label>
              <label>{{trabajador.horasExtra}}</label>
            </div>
          </div>
          <div class="cargaTotalHoras">
            <label>Carga Total de Trabajo</label>
            <label [ngStyle]="{color: trabajador.colorDepartamento}">{{trabajador.cargaTotal}}%</label>
          </div>
        </div>
      </div>
    </div>-->
    
  </div>
</div>
</div>
</div>