<div class="dashboardGeneralContainer">
    <div class="dataBodyContainer">
       <div class="familia" *ngFor="let familia of res">
        <div class="resumenFamilia" (click)="familia.abierto = !familia.abierto">
            <span class="cabecera">{{familia.concepto}}</span>
            <span>{{familia.cantidad | currency:'EUR'}}</span>
            <span>{{familia.directo | currency:'EUR'}}</span>
            <span>{{familia.indirecto | currency:'EUR'}}</span>
        </div>
        <div *ngIf="familia.abierto" class="lineaCoste leyenda">
            <span class="concepto"></span>
            <span class="cantidad">Base imponible</span>
            <span class="iva">Tipo IVA</span>
            <span class="fecha">Fecha</span>
            <span class="numeroFactura">Nº Factura</span>
            <span class="proyecto">Proyecto</span>
            <div class="pastillas">
                
            </div>
            <div class="botones">
            </div>
        </div>
        <ng-container *ngIf="familia.abierto">
            <div class="lineaCoste" *ngFor="let linea of familia.interior">
                <span class="concepto">{{linea.concepto}}</span>
                <span class="cantidad">{{linea.cantidad| currency:'EUR'}}</span>
                <span class="iva">{{linea.iva}} %</span>
                <span class="fecha">{{linea.fecha|date:'d/M/yyyy'}}</span>
                <span class="numeroFactura">{{linea.numero_factura}}</span>
                <span class="proyecto">{{linea.proyecto.nombre}}</span>
                <div class="pastillas">
                    <span class="directo pastilla"><ng-container *ngIf="linea.directo">Directo</ng-container>
                        <ng-container *ngIf="!linea.directo">Indirecto</ng-container></span>
                    <span class="tipo pastilla">
                        <ng-container *ngIf="linea.tipo == 0">Fijo</ng-container>
                        <ng-container *ngIf="linea.tipo == 1">Variable</ng-container>
                        <ng-container *ngIf="linea.tipo == 2">Periodo</ng-container>
                    </span>
                    <span class="estado pastilla" [class.ko]="linea.estado == 0" [class.ok]="linea.estado == 1">
                        <ng-container *ngIf="linea.estado == 0">Pendiente</ng-container>
                        <ng-container *ngIf="linea.estado == 1">Pagado</ng-container></span>
                </div>
                <div class="botones">
                    <button class="editar"></button>
                    <button class="eliminar"></button>
                </div>
            </div>
        </ng-container>
        </div>
        
    </div>