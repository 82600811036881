import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions } from 'chart.js';
import { ChartComponent } from 'ng-apexcharts';
import { takeUntil } from 'rxjs';
import { PopUpCosteComponent } from 'src/app/Components/pop-up-coste/pop-up-coste.component';
import { PopupConfirmacionBorradorComponent } from 'src/app/Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { MesesCerradosService } from 'src/app/Services/Api/meses-cerrados.service';
import { CosteEmpresaService } from 'src/app/Services/coste-empresa.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupService } from 'src/app/Services/popup.service';

@Component({
  selector: 'app-costes',
  templateUrl: './costes.component.html',
  styleUrls: ['./costes.component.css']
})
export class CostesComponent extends UnsubscribesDestroy implements OnInit {

  public chartOptions!: Partial<ChartOptions> | any;

    
  constructor(
    protected loaderSE: LoaderService,
    public router: Router,
    protected popup: PopupService,
    protected costeSE: CosteEmpresaService,
    protected mesesCerradosSE: MesesCerradosService,
    protected notificacionSE: NotificacionesService) {
      super();
      this.chartOptions = {
        colors: ["#fc464e"],
        series: [
          {
            name: 'Total',
            data: [],
          }
        ],
        chart: {
          type: 'line',
          height: 200,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        stroke: {
          width: 2,
          curve: "smooth",
        },
        plotOptions: {
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels:{
            show: true,
            style:{
              colors:"#515655",
              fontFamily: 'Satoshi',
              fontWeight: 500,
            }
          }
        },
        yaxis: {
          
          labels:{
            show: true,
            style:{
              colors:"#a1a3a2",
              fontFamily: 'Satoshi',
              fontWeight: 500,
            },
            formatter: (value) => { 
              //formatear en formato moneda
              let formatter = new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'EUR',
              });
              return formatter.format(value);
            },
          }
        },
        legend:{
          position:'top',
          horizontalAlign: 'right',
          fontFamily: 'Satoshi',
          markers: {
            width: 30,
            height:8,
            radius: 8,
            offsetX: -15,
          },          
          itemMargin: {
            horizontal: 30,
            vertical: 0
          },
        }
      };
     }

     @ViewChild('chart')
     chart!: ChartComponent;
  ngOnInit(): void {
    this.guardarAnyos();
    this.cambiarAnyo();
    localStorage.setItem('lastRoute', this.router.url);
  }
  fijo = [];
  variable = [];
  periodo = [];
  total = [];
  verGrafico = false;
  mesesCerrados = [];
  cambiarAnyo(){
    this.loaderSE.open();
    this.actualizando = true;
    this.costeSE.getCostesYear(this.anyoSeleccionado);
    this.costeSE.getResult()
    .pipe(takeUntil(this._unsubscribeIndividual2))
    .subscribe((value) => {
      if (value == null) {
        return;
      }

      this.meses = value;
      this.total = [];
      this.fijo = [];
      this.variable = [];
      this.periodo = [];
      for (let i = 0; i < this.meses.length; i++) {
        this.total.push(this.meses[i].Directo + this.meses[i].Indirecto);
      }
      this.pintarGraficoBarras();
      this.actualizando = false;
      this.loaderSE.closeContador();
      this._unsubscribeIndividual2.next('');
    });

    this.obtenerMesesCerrados();

  }
  generarNominas(mes,event){
    event.stopPropagation();
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Desea generar las nominas de este mes?',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.loaderSE.open();
          this.costeSE.generarNominas(this.anyoSeleccionado,mes);
          this.costeSE.getResultIndividual()
          .pipe(takeUntil(this._unsubscribeIndividual3))
          .subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this.cambiarAnyo();
            this.notificacionSE.showFeedBack(value.message);
            this._unsubscribeIndividual3.next('');
          });
        }
        this._unsubscribeIndividual.next('');
        this.popup.clearData();
      });
  }
  obtenerMesesCerrados(){
    this.loaderSE.open();
    this.mesesCerradosSE.clearResult();
    this.mesesCerradosSE.getMesesYear(this.anyoSeleccionado);
    this.mesesCerradosSE.getResult()
    .pipe(takeUntil(this._unsubscribeIndividual4))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this.loaderSE.closeContador();
      console.log(value);
      this.mesesCerrados = value;
      this._unsubscribeIndividual4.next('');
    });
  }

  abrirMes(mes,event){
    event.stopPropagation();
      //mostrar popup de confirmacion
      this.popup.openPopup(PopupConfirmacionBorradorComponent, {
        descripcion: '¿Desea abrir el mes?',
      });
      this.popup
        .returnData()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          if (value.returnValue) {
            this.mesesCerradosSE.abrirMes(this.anyoSeleccionado,mes);
            this.mesesCerradosSE.getResult()
            .pipe(takeUntil(this._unsubscribeIndividual3))
            .subscribe((value) => {
              if (value == null) {
                return;
              }
              this.obtenerMesesCerrados();
              this.notificacionSE.showFeedBack('Mes abierto correctamente');
              this._unsubscribeIndividual3.next('');
            });
          }
          this.popup.clearData();
          this._unsubscribeIndividual.next('');
        });      
  }
  cerrarMes(mes,event){
    event.stopPropagation();
    //mostrar popup de confirmacion
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Desea cerrar el mes?',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.mesesCerradosSE.cerrarMes(this.anyoSeleccionado,mes);
          this.mesesCerradosSE.getResult()
          .pipe(takeUntil(this._unsubscribeIndividual3))
          .subscribe((value) => {
            if (value == null) {
              return;
            }
            this.obtenerMesesCerrados();
            this.notificacionSE.showFeedBack('Mes cerrado correctamente');
            this._unsubscribeIndividual3.next('');
          });
        }
        this.popup.clearData();
        this._unsubscribeIndividual.next('');
      });
    
  }
  pintarGraficoBarras(){
      this.chartOptions.series = [
        {
          name: 'Total',
          data: [],
        }
      ];
      this.chartOptions.series[0].data = this.total;        
    if(this.chart != null){
      this.chart.updateSeries(this.chartOptions.series);
    }
  }
  actualizando = false;
  meses = [
    { nombre: 'Enero', Directo: 0, Indirecto:0},
    { nombre: 'Febrero', Directo: 0, Indirecto:0},
    { nombre: 'Marzo', Directo: 0, Indirecto:0},
    { nombre: 'Abril', Directo: 0, Indirecto:0},
    { nombre: 'Mayo', Directo: 0, Indirecto:0},
    { nombre: 'Junio', Directo: 0, Indirecto:0},
    { nombre: 'Julio', Directo: 0, Indirecto:0},
    { nombre: 'Agosto', Directo: 0, Indirecto:0},
    { nombre: 'Septiembre', Directo: 0, Indirecto:0},
    { nombre: 'Octubre', Directo: 0, Indirecto:0},
    { nombre: 'Noviembre', Directo: 0, Indirecto:0},
    { nombre: 'Diciembre', Directo: 0, Indirecto:0}
  ]
  anyos = [];
  anyoSeleccionado = 0;
  mesSeleccionado = null;
  guardarAnyos(){    
    //guardar en anyos el año anterior, el actual y el que viene
    let anyoActual = new Date().getFullYear();
    this.anyoSeleccionado = anyoActual;
    this.anyos.push(anyoActual - 1);
    this.anyos.push(anyoActual);
    this.anyos.push(anyoActual + 1);
  }

  mesActual = new Date().getMonth();
  anyoActual = new Date().getFullYear();

  seleccionarMes(indice){
    if(indice != this.mesSeleccionado){
      this.mesSeleccionado = indice;
    }else{
      this.mesSeleccionado = null;
    }
  }


  abrirPopUp(mes,event){
    event.stopPropagation();
    let data = {year:this.anyoSeleccionado,month:mes};
    this.popup.openPopup(PopUpCosteComponent,data);    
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValue) {
        this.cambiarAnyo();        
      }
      this.popup.clearData();
      this._unsubscribeIndividual.next('');
    });

    
  }
}
