import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { EmpresasService } from 'src/app/Services/empresas.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { LoginService } from 'src/app/Services/login.service';
import { PageEvent } from '@angular/material/paginator';
import { PopupService } from 'src/app/Services/popup.service';
import { PopupConfirmacionBorradorComponent } from 'src/app/Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { HttpClient } from '@angular/common/http';
import { ClientesService } from 'src/app/Services/clientes.service';
import { PopupAddClienteComponent } from 'src/app/Components/popup-add-cliente/popup-add-cliente.component';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent 
extends UnsubscribesDestroy
implements OnInit{  
  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public router: Router,
    public clienteSE: ClientesService,
    public loaderSE: LoaderService,
    public empresaSE: EmpresasService,
    public loginSE: LoginService,
    protected popup: PopupService,
    protected http: HttpClient
  ) {
    super();
  }
  
  clientes = [];
  pageSize = 25;
  pagina = 0;
  cantidadPaginas = 1;
  clockify = false;
  ngOnInit(): void {
    if (
      localStorage.getItem('token') === null &&
      localStorage.getItem('login') != 'login'
    ) {
      this.loginSE.desconectar();
    }
    if(localStorage.getItem('id_clockify') != null && localStorage.getItem('id_clockify') != ''){
      this.clockify = true;
    }
    localStorage.setItem('lastRoute', this.router.url);
    this.buscarClientes();
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pagina = e.pageIndex;
    this.buscarClientes();
  }

  buscarClientes(){    
    let empresaId = localStorage.getItem('numEmpresa');
      this.clienteSE.getAllClientesByParameters(this.pageSize, this.pagina+1, empresaId);      
      this.loaderSE.open();
      this.clienteSE.getResult().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
      if (value == null) {
        return;
      }
      console.log(value);
      this.loaderSE.closeContador();
      this.clientes = value.data;
      this.cantidadPaginas = value.total;
      this.clienteSE.clearResult();
      this._unsubscribeIndividual2.next('');
    }
    );
  }

  addCliente(){
    //abrir popup para agregar cliente
    this.popup.openPopup(PopupAddClienteComponent,{});
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual3))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.buscarClientes();
        }
        this.popup.clearData();
        this._unsubscribeIndividual3.next('');
      }
      );
  }

  eliminarCliente(id){
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Eliminar el cliente?',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          
        }
        this.popup.clearData();
        this._unsubscribeIndividual.next('');
      });
  }
  
  editarCliente(cliente){
    //abrir popup para agregar cliente
    this.popup.openPopup(PopupAddClienteComponent,{cliente: cliente});
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual3))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.buscarClientes();
        }
        this.popup.clearData();
        this._unsubscribeIndividual3.next('');
      }
      );
  }

}
