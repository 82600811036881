import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexTitleSubtitle} from 'ng-apexcharts';
import { takeUntil } from 'rxjs';
import { PopUpFacturaComponent } from 'src/app/Components/pop-up-factura/pop-up-factura.component';
import { PopupFacturaComponent } from 'src/app/Components/popup-factura/popup-factura.component';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { FinanzasService } from 'src/app/Services/finanzas.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { PopupService } from 'src/app/Services/popup.service';
import { PageEvent } from '@angular/material/paginator';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-tesoreria',
  templateUrl: './tesoreria.component.html',
  styleUrls: ['./tesoreria.component.css'],
})
export class TesoreriaComponent extends UnsubscribesDestroy implements OnInit {  
  buscador = null;
  clientes = [];
  clientesFacturas = [];
  filtro: string = '';
  opcion: string = 'Analítica';
  abiertos: number[] = [];
  analiticas = [
    {
      tipo: 'horasExtra',
      valor: 0,
      titulo: 'Total cobrado',
    },
    {
      tipo: 'costeMedioEmpresa',
      valor: 0,
      titulo: 'Total presupuestado',
    },
    {
      tipo: 'ingresosPotenciales',
      valor: 0,
      titulo: 'Ingresos potenciales presupuestados',
    },
    {
      tipo: 'facturacionHistorico',
      valor: 0,
      titulo: 'Diferencia año anterior',
    },
  ];

  facturas = [];

  @ViewChild('chart')
  chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions> | any;

  constructor(
    public popup: PopupService,
    protected finanzasSE: FinanzasService,
    public loaderSE: LoaderService,
    private elementRef: ElementRef
  ) {
    super();
    this.chartOptions = {
      colors: ['#6CC09A', '#F66F6B','#FFBF73','#699CBA'],
      series: [
        {
          name: 'Cobrado',
          data: [],
        },
        {
          name: 'Pendiente',
          data: [],
        },
        {
          name: 'Potencial',
          data: [],
        },
        {
          name: 'Estimado',
          data: [],
        },
      ],        
      yaxis: {
        labels: {
          style:{
            cssClass: 'yaxisSimulador',
            colors:'#1f586b',
            fontFamily: 'Satoshi'
          },
          formatter: function (val: any) {            
            let formatter = new Intl.NumberFormat('es-ES', {
              style: 'currency',
              currency: 'EUR',
            });
            return formatter.format(val);
          },
        },
        forceNiceScale: true,
        min: 0,
        show:true,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {            
            let formatter = new Intl.NumberFormat('es-ES', {
              style: 'currency',
              currency: 'EUR',
            });
            return formatter.format(val);
          },
        },
      },

      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20px',
          
        borderRadius: 7,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: 'last'
        },
      },
      dataLabels: {
        enabled: false,
      },

      xaxis: {
        categories: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style:{
            colors:'#192b45',
            fontFamily: 'Satoshi',
            fontWeight: 600,
            fontSize: '13px',
          },
        },
      },
    };
  }
  orden = {
    clave:'numero',
    orden:'desc'
  }
  ordenar(clave){
    if(this.orden.clave == clave){
      if(this.orden.orden == 'asc'){
        this.orden.orden = 'desc';
      }else{
        this.orden.orden = null;
        this.orden.clave = null;
      }
    }else{
      this.orden.clave = clave;
      this.orden.orden = 'asc';
    }
    this.obtenerFacturas();
  }
pagina = 0;
pageSize = 25;
cantidadPaginas = 1;
  obtenerFacturas() {
    this.loaderSE.open();
    this.finanzasSE.getFacturasTabla(this.pageSize,this.pagina+1,false,this.orden.clave,this.orden.orden=='asc');
    this.finanzasSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubscribeIndividual2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.facturas = value.data;
        this.cantidadPaginas = value.total;
        this.loaderSE.closeContador();
        this._unsubscribeIndividual2.next('');
      });
  }

  
  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pagina = e.pageIndex;
    this.obtenerFacturas();
  }
  datosTesoreria = [];
  ngOnInit(): void {
    this.loaderSE.open();
    this.finanzasSE.getFinanzas();
    this.finanzasSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        let cobrado = [];
        let falta = [];
        let potencial = [];
        let estimado = [];
        let cobradoActual = 0;
        let contFaltante = 0;
        let cont = 0;
        let actual;

        value.meses.forEach((mes) => {
          this.analiticas[0].valor += mes.pagado;
          this.analiticas[1].valor += mes.pagado + mes.pendiente;
          cobrado.push(mes.pagado);
          falta.push(mes.pendiente);
          potencial.push(mes.potencial);
          estimado.push(mes.estimado);
          
          if (cont == new Date().getMonth()) {
            actual = mes.pendiente;
          }
          if (cont < new Date().getMonth()) {
            contFaltante += mes.pendiente;
          }
          cobradoActual += mes.pagado;
          cont++;

        });

        this.datosTesoreria.push({
          cantidad: value.facturado,
          texto: 'Facturado',
        });
        this.datosTesoreria.push({
          cantidad: value.negocioGenerado,
          texto: 'Negocio generado',
        });
        this.datosTesoreria.push({
          cantidad: cobradoActual,
          texto: 'Cobrado',
        });
        this.datosTesoreria.push({
          cantidad: value.anterior,
          texto: 'Año anterior',
        });

        this.analiticas[2].valor = value.potencial;

        this.analiticas[3].valor = this.analiticas[0].valor - value.anterior;
        this.elementRef.nativeElement.style.setProperty(
          '--cont-1',
          this.analiticas[0].valor
        );
        this.elementRef.nativeElement.style.setProperty(
          '--cont-2',
          this.analiticas[1].valor
        );
        this.elementRef.nativeElement.style.setProperty(
          '--cont-3',
          this.analiticas[2].valor
        );
        this.elementRef.nativeElement.style.setProperty(
          '--cont-4',
          this.analiticas[3].valor
        );

        this.chartOptions.series[0].data = cobrado;
        this.chartOptions.series[1].data = falta;
        this.chartOptions.series[2].data = potencial;
        this.chartOptions.series[3].data = estimado;
        if(this.chart != null){
          this.chart.updateSeries(this.chartOptions.series);
        }
        this._unsubscribeIndividual.next('');
        this.finanzasSE.clearResult();
        this.loaderSE.closeContador();
      }); 
      this.obtenerFacturas();   
  }

  contenedorHitoEstado(hito: number) {
    if (!this.abiertos.includes(hito)) {
      this.abiertos.push(hito);
    } else {
      this.abiertos.splice(this.abiertos.indexOf(hito), 1);
    }
  }

  borrarTexto() {
    this.filtro = '';
  }

  opcionSeleccionada(event: string) {
    this.opcion = event;
    if(this.chart != null){
      this.chart.updateSeries(this.chartOptions.series);
    }
  }

  abrirPopupHitosPago() {
    this.popup.openPopup(PopUpFacturaComponent,{});
  }

  pagar(factura){    
    this.popup.openPopup(PopupFacturaComponent,{id:factura.id});this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if(value==null){
        return;
      }
      if(value.returnValue){
        this.obtenerFacturas();
        this.popup.clearData();
        this._unsubscribeIndividual.next('');
      }
    });
  }
}
