<div class="dashboardGeneralContainer">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'crm'"></sidebar>
    </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="CRM"></app-header-bar>
      </div>
        <div class="dataBodyContainer">
          <div class="degradado">
            <div class="contenedor">
                <div class="columna" *ngFor="let col of crm; let i = index">
                    <span class="titulo">{{col.nombre}} <ng-container *ngIf="!col.no_cierre">({{col.cliente_estado_crms.length}}) {{col.cliente_estado_crms | crmContabilidad | currency:'EUR'}}</ng-container></span> 
                    <button (click)="addClienteCRM(col.id)">+</button>     
                    <div class="proyectos" 
                    cdkDropList
                    [id]="i.toString()"
                    [cdkDropListData]="col.cliente_estado_crms"
                    [cdkDropListConnectedTo]="nombres"
                    (cdkDropListDropped)="drop($event)">     
                    <ng-container *ngIf="!col.no_cierre"><tarjeta-crm (click)="editClienteCRM(estado)" [estado]="estado" cdkDrag *ngFor="let estado of col.cliente_estado_crms"></tarjeta-crm></ng-container>
                    </div>
                </div>
            </div>
          </div>

        </div>
    </div>
</div>
  