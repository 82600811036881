<!-- <div class="confirmarGeneralContainer">
    <div class="confirmarConainer">
      <div class="body">
        <span>{{ descripcion }}</span>
        <div class="campos">
          <div class="linea">
            <div class="campo">
              <label>Horas Estimadas</label>
              <input disabled [(ngModel)]="horasEstimadas">
            </div>

            <div class="campo">
              <label>Horas imputadas</label>
              <input required [(ngModel)]="horas">
            </div>
          </div>

          <div class="linea">
            <div class="campo">
              <label>Avance estimado %</label>
              <input disabled type="number"[(ngModel)]="avanceEstimado">
            </div>
            <div class="campo">
              <label>Avance real %</label>
              <input required type="number" max="100" step="0.1" [(ngModel)]="avance">
            </div>
          </div>
        </div>

        <div class="bodyBotones">
          <button class="confirmar" (click)="validarDaily()" type="button">
            Sí
          </button>
          <button (click)="confirmarGuardarBorrador(false)" type="button">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
   -->



<div class="popupGeneralContainer">
  <label for="title" class="title">Validación de daily -
    <ng-container *ngIf="isDataLoaded">
      {{daily.nombre_tarea}}
    </ng-container>
    <ng-container *ngIf="!isDataLoaded">
      Cargando ...
    </ng-container>
  </label>
  <div class="body">
    <!--<menu-navegacion class="especialNavegation" [colorSecundario]="true"  [condensed]="false" [redondo]="true" [opcionPorDefecto]="tipo"
    [opciones]="['Sí, completado','No, corregido']"  (emitOpcionSeleccionada)="opcionSeleccionadaTipo($event)"></menu-navegacion>-->
    <div class="doubleColumn">
      <div class="customInput">
        <label>Horas Estimadas</label>
        <div class="fakeInput">
          <span class="disabled" >
            {{horasEstimadas}} horas
          </span>
        </div>
      </div>
      <div class="customInput">
        <label>Horas imputadas</label>
        <div class="fakeInput">
          <span [class.placeholder]="!horas">
            <ng-container *ngIf="horas">
              {{horas}} horas
            </ng-container>
            <ng-container *ngIf="!horas">
              <span>Horas inputadas</span>
            </ng-container>

          </span>
          <input required placeholder="Horas inputadas" [(ngModel)]="horas">
        </div>
      </div>
    </div>
    <div class="doubleColumn">
      <div class="customInput">
        <label>Avance estimado</label>
        <div class="fakeInput">
          <span class="disabled" disabled>{{avanceEstimado}} %</span>
        </div>

      </div>
      <div class="customInput">
        <label>Avance real</label>
        <div class="fakeInput">
          <span>{{avance}} %</span>
          <input required type="number" max="100" step="0.1" [(ngModel)]="avance">
        </div>

      </div>
    </div>
  </div>
  <div class="footer">
    <button (click)="confirmarGuardarBorrador(false)" type="button">
      Cancelar
    </button>
    <button (click)="validarDaily()" type="button">
      Confirmar
    </button>
  </div>
</div>
