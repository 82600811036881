<div class="confirmarGuardarBorradorGeneralContainer">
    <div class="confirmarGuardarBorradorConainer">
      <div class="bodyGuardarBorrador">
        <span>Generar Presupuesto</span>
        <div class="campos">
          <div class="linea">
            <div class="campo">
              <label>Nombre</label> 
              <input [(ngModel)]="nombre">
            </div>
            <div class="campo">
              <label>Abreviatura</label> 
              <input [(ngModel)]="abreviatura">
            </div>
          </div>
          <div class="linea">
            <div class="campo">
              <label>Coste día</label>
              <input [(ngModel)]="coste_dia" type="number">
            </div>
            <div class="campo">
              <label>Multiplicador</label>
              <input [(ngModel)]="multiplicador" type="number">
            </div>
          </div>
          <div class="linea">
            <div class="campo">
              <label>Fecha Inicio</label>
              <input [(ngModel)]="fechaIni" type="date">
            </div>
            <div class="campo">
              <label>Fecha Fin</label>
              <input [(ngModel)]="fechaFin" type="date">
            </div>
          </div>
          <label><input type="checkbox" [(ngModel)]="fijarDias"> Fijar días</label>
          <div class="linea">
            <div class="campo">
              <label>Días</label>
              <input *ngIf="fijarDias" [(ngModel)]="dias" type="number" (input)="cambiarDias()">
              <p *ngIf="!fijarDias">{{dias}}</p>
            </div>
            <div class="campo">
              <label>Precio</label>
              <input *ngIf="!fijarDias" [(ngModel)]="precio" type="number" (input)="cambiarPrecio()">
              <p *ngIf="fijarDias">{{dias*coste_dia*multiplicador|currency:'EUR'}}</p>
            </div>
          </div>

        <div class="bodyBotones">
          <button class="confirmar" (click)="generarFactura()" type="button">
            Generar
          </button>
          <button (click)="confirmarGuardarBorrador(false)" type="button">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
  