import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-CalculadoraTiempoEstimadoPopup',
  templateUrl: './CalculadoraTiempoEstimadoPopup.component.html',
  styleUrls: ['./CalculadoraTiempoEstimadoPopup.component.scss']
})
export class CalculadoraTiempoEstimadoPopupComponent implements OnInit {

  moreDatosOpened: boolean = false

  optimista: number | null = null;
  realista: number | null = null;
  pesimista: number | null = null
  tiempoEstimado: number | null = null;
  desviacion: number | null = null;
  desviacionSumador: number = 0;
  tiempoFinal: number = 0;
  probabilidad: number = 50;
  step: number = 0;
  constructor(public dialogRef: MatDialogRef<CalculadoraTiempoEstimadoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {
    console.log(this.modalData)
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  closePopup(returnValue?:any){
    let p={returnValue:returnValue};
    this.dialogRef.close(p);
  }
  estimarTiempo(){
    this.tiempoEstimado = ((Number(this.optimista) + Number(this.realista * 4) + Number(this.pesimista))/6);
    this.desviacion = (Number(this.pesimista) - Number(this.optimista))/6
    this.tiempoFinal = (Number(this.tiempoEstimado) + Number(this.desviacionSumador));
    this.moreDatosOpened = true;
  }
  getDesviacion(event){
    console.log(event)
    switch (event) {
      case 0:
        this.probabilidad = 50;
        break;
      case 0.5:
        this.probabilidad = 65.15;
        break;
      case 1:
        this.probabilidad = 84.13;
        break;
      case 1.5:
        this.probabilidad = 93.32;
        break;
      case 2:
        this.probabilidad = 97.73;
        break;
      case 2.5:
        this.probabilidad = 99.38;
        break;
      case 3:
        this.probabilidad = 99.87;
        break;
      case -0.5:
        this.probabilidad = 30.85;
        break;
      case -1:
        this.probabilidad = 15.87;
        break;
      case -1.5:
        this.probabilidad = 6.68;
        break;
      case -2:
        this.probabilidad = 2.27;
        break;
      case -2.5:
        this.probabilidad = 0.62;
        break;
      case -3:
        this.probabilidad = 0.13;
        break;

    }
    this.step = event;
    this.desviacionSumador = Number(this.desviacion) * Number(event);
    this.tiempoFinal = this.tiempoEstimado + this.desviacionSumador;
    console.log(this.desviacionSumador)
  }
  enviar(){
    this.closePopup(this.tiempoFinal)
  }
}
