<div class="daily">
   
    <div class="elemento ajustado">
        <ng-container *ngIf="daily.proyecto!=null && !daily.validado && daily.fecha.split('T')[0] == hoy">
            <button class="trabajar" [class.stop]="activo" *ngIf="daily.proyecto.id_clockify!=null && daily.usuario.id == me" (click)="iniciarDaily($event,daily)"><img src="/assets/icons/work.png"></button>
        </ng-container>
    </div>
    <div class="elemento">
        <div *ngIf="daily.proyecto != null" class="proyecto"><img src="/assets/icons/folder-star.png"><span class="nombre overflow" [matTooltip]="daily.proyecto.nombre" matTooltipClass="tooltip" matTooltipPosition="above">{{daily.proyecto.abreviatura}} | {{daily.proyecto.nombre}}</span></div>
    </div>
    <div *ngIf="admin" class="elemento empleado">
        <img [src]="environment.urlImg + daily.usuario.profile" alt="">
        <span class="nombre overflow" [matTooltip]="daily.usuario.nombre+' '+daily.usuario.apellidos" matTooltipClass="tooltip" matTooltipPosition="above">{{daily.usuario.nombre}} {{daily.usuario.apellidos}}</span>
    </div>
    <div class="elemento">
        <div *ngIf="daily.proceso != null" class="fase"><img src="/assets/icons/fase.png"><span class="nombre overflow" [matTooltip]="daily.proceso.nombre" matTooltipClass="tooltip" matTooltipPosition="above">{{daily.proceso.nombre}}</span></div>
        <div *ngIf="daily.sub_proceso != null" class="fase"><img src="/assets/icons/fase.png"><span class="nombre overflow" [matTooltip]="daily.sub_proceso.proceso.nombre" matTooltipClass="tooltip" matTooltipPosition="above">{{daily.sub_proceso.proceso.nombre}}</span></div>
    </div>
    
    <div class="elemento">
        <div *ngIf="daily.sub_proceso != null" class="fase"><span class="nombre overflow" [matTooltip]="daily.sub_proceso.nombre" matTooltipClass="tooltip" matTooltipPosition="above">{{daily.sub_proceso.nombre}}</span></div>
        <div *ngIf="daily.sub_proceso == null" class="fase"><span class="nombre overflow">-</span></div>
    </div>
    <div class="horasEstimadas">
        {{daily.horas_estimadas}} horas
    </div>
    <div class="elementoValidacion">
        <div class="validacion" (click)="validarDaily($event,!daily.validado)">
            <span class="icono">
                <svg xmlns="http://www.w3.org/2000/svg" width="17.667" height="9.939" viewBox="0 0 17.667 9.939">
                    <g id="Grupo_14452" data-name="Grupo 14452" transform="translate(-1391.588 -246.823)">
                    <path id="checkmark_7_" data-name="checkmark (7)" d="M7.75,13.381l2.6,2.894L17.591,8.75" transform="translate(1390.25 239.487)" fill="none" [attr.stroke]="comprobarValidado()" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="checkmark_7_2" data-name="checkmark (7)" d="M7.75,13.381l2.6,2.894L17.591,8.75" transform="translate(1385.25 239.487)" fill="none" [attr.stroke]="comprobarEnviado()" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </svg>              
            </span>
            <span class="texto">
                <ng-container *ngIf="!daily.completada && !daily.validado">No enviado</ng-container>
                <ng-container *ngIf="daily.completada && !daily.validado">No validado</ng-container>
                <ng-container *ngIf="daily.validado">Validado</ng-container>
            </span>
        </div>
    </div>
    <div class="keyResult">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.172" height="13.217" viewBox="0 0 14.172 13.217">
                <g id="Grupo_14453" data-name="Grupo 14453" transform="translate(-1587.586 -334.391)">
                  <path id="Trazado_2909" data-name="Trazado 2909" d="M-18828-1038l5.193,5.194-5.193,5.194" transform="translate(20417.002 1373.806)" fill="none" stroke="#788883" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Trazado_2910" data-name="Trazado 2910" d="M-18828-1038l5.193,5.194-5.193,5.194" transform="translate(20423.566 1373.806)" fill="none" stroke="#36403d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
              </svg>
              
            <span>+{{daily.avance_teorico | percent:'0.0-1'}}</span>
        </div>
    </div>
    <div class="elemento ajustado">
        <div *ngIf="daily.categoria" class="incidencia" [matTooltip]="daily.categoria.nombre" matTooltipClass="tooltip" matTooltipPosition="above" [style.color]="getTextColor(daily.categoria.color)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Grupo_14452" data-name="Grupo 14452" transform="translate(-1792 -239)">
                    <g id="Polígono_240" data-name="Polígono 240" transform="translate(1792 239)" fill="none">
                    <path d="M10.211,3.578a2,2,0,0,1,3.578,0l8.764,17.528A2,2,0,0,1,20.764,24H3.236a2,2,0,0,1-1.789-2.894Z" stroke="none"/>
                    <path d="M 12 3.972126007080078 C 11.88459014892578 3.972126007080078 11.67304039001465 4.008035659790039 11.55278968811035 4.248525619506836 L 2.788850784301758 21.77639579772949 C 2.680379867553711 21.99333572387695 2.756000518798828 22.17429542541504 2.810739517211914 22.26286506652832 C 2.865470886230469 22.35142517089844 2.993509292602539 22.49999618530273 3.23607063293457 22.49999618530273 L 20.76392936706543 22.49999618530273 C 21.00649070739746 22.49999618530273 21.13452911376953 22.35142517089844 21.18926048278809 22.26286506652832 C 21.24399948120117 22.17429542541504 21.31962013244629 21.99333572387695 21.21114921569824 21.77639579772949 L 12.44721031188965 4.248516082763672 C 12.32695960998535 4.008035659790039 12.11540985107422 3.972126007080078 12 3.972126007080078 M 12 2.472129821777344 C 12.71016502380371 2.472129821777344 13.42033004760742 2.840656280517578 13.78884983062744 3.577705383300781 L 22.55278968811035 21.10557556152344 C 23.21768951416016 22.43537521362305 22.25069999694824 23.99999618530273 20.76392936706543 23.99999618530273 L 3.23607063293457 23.99999618530273 C 1.749300003051758 23.99999618530273 0.7823104858398438 22.43537521362305 1.447210311889648 21.10557556152344 L 10.21115016937256 3.577705383300781 C 10.57966995239258 2.840656280517578 11.28983497619629 2.472129821777344 12 2.472129821777344 Z" stroke="none" [attr.fill]="daily.categoria.color"/>
                    </g>
                    <line id="Línea_2192" data-name="Línea 2192" y2="6" transform="translate(1804 248.5)" fill="none" [attr.stroke]="daily.categoria.color" stroke-linecap="round" stroke-width="1.5"/>
                    <line id="Línea_2193" data-name="Línea 2193" y2="1" transform="translate(1804 257.5)" fill="none" [attr.stroke]="daily.categoria.color" stroke-linecap="round" stroke-width="1.5"/>
                </g>
            </svg>
        </div>
    </div>

        
    <ng-container *ngIf="false">
    <div class="cabecera">
        <div class="izquierda">
        <ng-container *ngIf="daily.proyecto!=null && !daily.validado && daily.fecha.split('T')[0] == hoy">
            <button [class.stop]="activo" *ngIf="daily.proyecto.id_clockify!=null && daily.usuario.id == me" (click)="iniciarDaily($event,daily)"><img src="/assets/icons/work.png"></button>

        </ng-container>
            <div class="fecha">{{daily.fecha|date:'dd/MM/yyyy'}}</div>
        </div>
        <div class="derecha">
            <div class="porcentaje">
                <span>{{(daily.porcentaje * 100).toFixed(0)}}%</span>
                <div class="contenedor">
                    <div class="valor"  [ngStyle]="{ width: daily.porcentaje * 100 + '%' }"></div>
                </div>
                <span class="icono"><img src="/assets/icons/finish.png"></span>
            </div>
            <div *ngIf="admin" class="eliminar" (click)="eliminarDaily($event)"><img src="/assets/icons/remove.png"></div>
        </div>
    </div>
    <div class="cuerpo">
        <div class="columnas">
            <div class="col">
                <div class="titulo"><img src="/assets/icons/checklist.png"> {{daily.nombre_tarea}}</div>
                <div *ngIf="daily.proyecto != null" class="proyecto"><img src="/assets/icons/folder-star.png"> {{daily.proyecto.nombre}}</div>
                <div *ngIf="daily.proceso != null" class="fase"><img src="/assets/icons/fase.png"> {{daily.proceso.nombre}}</div>
            </div>
            <div class="col">
                <div class="validado" (click)="validarDaily($event,!daily.validado)">
                    <div class="valor"><input type="checkbox" (click)="validarDaily($event,!daily.validado)" [checked]="daily.validado" disabled></div>
                    <div class="titulo">Validado por el project manager</div>
                </div>
            </div>
        </div>

    </div>
    <div class="pie">
        <div class="izquierda">
            <div class="empleado">
                <img [src]="environment.urlImg + daily.usuario.profile" alt="">
                <span class="nombre">{{daily.usuario.nombre}} {{daily.usuario.apellidos}}</span>
            </div>
        </div>
        <div class="derecha">
            <div *ngIf="daily.categoria" class="incidencia" [style.background]="daily.categoria.color" [matTooltip]="daily.categoria.nombre" matTooltipClass="tooltip" matTooltipPosition="above" [style.color]="getTextColor(daily.categoria.color)"><img *ngIf="getTextColor(daily.categoria.color)=='#ffffff'" src="/assets/icons/bug.png"><img *ngIf="getTextColor(daily.categoria.color)=='#000000'" src="/assets/icons/bug_d.png"></div>
        </div>
    </div>
</ng-container>
</div>

