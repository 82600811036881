import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CosteEmpresaService } from 'src/app/Services/coste-empresa.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { PopUpCosteComponent } from 'src/app/Components/pop-up-coste/pop-up-coste.component';
import { PopupService } from 'src/app/Services/popup.service';


@Component({
  selector: 'coste-mes',
  templateUrl: './vista-costes-mes.component.html',
  styleUrls: ['./vista-costes-mes.component.css']
})

export class VistaCostesMesComponent extends UnsubscribesDestroy implements OnInit {

  @Input() year;
  @Input() month;

  
  ngOnChanges(changes) {
    if(changes.tarea != undefined){
  }
  if(changes.month != undefined || changes.year != undefined){
    this.cargarInformacion();
  }
}

costesFijos = [];
costesVariables = [];
costesPeriodicos = [];
totalFijo = 0;
totalVariable = 0;
totalPeriodico = 0;

  constructor(
    protected loaderSE: LoaderService,
    protected popup: PopupService,
    protected costeSE: CosteEmpresaService,
    protected activatedRoute : ActivatedRoute) {
      super();
    }
    cargando = false;
cargarInformacion(){
  if(!this.cargando){
    this.cargando = true;
    this.loaderSE.open();
    this.costeSE.getCostesYearMonth(this.year,this.month);
    this.costeSE.getResult()
    .pipe(takeUntil(this._unsubscribeIndividual2))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this.res = value;
      this.costesFijos = [];
      this.costesVariables = [];
      this.costesPeriodicos = [];
      this.totalFijo = 0;
      this.totalVariable = 0;
      this.totalPeriodico = 0;
      this.res.forEach(coste => {
        if(coste.familia == null){
          coste.familia = {nombre:"Sin familia"};
        }       
        if(coste.proyecto == null){
          coste.proyecto = {nombre:"-"};
        }
        if(coste.numero_factura == null){
          coste.numero_factura = "-";
        }
      });
      this.res = this.ordenarPorFamilia(this.res);
      console.log(this.res);
      
      this.res.forEach(familia => {
          familia.concepto = familia.familia.nombre;
          let directo = 0;
          let indirecto = 0;
          familia.interior.forEach(coste => {
            if(coste.directo){
              directo += coste.cantidad;
            }else{
              indirecto += coste.cantidad;
            }
          });
          familia.directo = directo;
          familia.indirecto = indirecto;
        
      });
      /*this.res.forEach(coste => {
        console.log(coste);
        }
      });*/
      this.loaderSE.closeContador();
      this._unsubscribeIndividual2.next('');
      this.cargando = false;
    });
  }
}

ordenarPorFamilia(familia){
  if(familia.length == 0){
  return [];
}
 let ordenado = [];
 let costeAnterior = this.guardarCostesPorValor(familia[0]);
 costeAnterior.cantidad = 0;
let interiores = [];
let primero = true;
 familia.forEach(coste => {
  if(primero){
    primero = false;
    costeAnterior = this.guardarCostesPorValor(coste);
    interiores.push(this.guardarCostesPorValor(coste));
    return;
  }
  if(coste.familia == null){
    costeAnterior.interior = interiores;
    ordenado.push(costeAnterior);
    interiores =[];
    costeAnterior = this.guardarCostesPorValor(coste);
  }else{
    if(coste.familia.nombre != costeAnterior.familia.nombre){
      if(interiores.length > 0){
      costeAnterior.interior = interiores;
      }
      ordenado.push(costeAnterior);
      interiores =[];
      costeAnterior = this.guardarCostesPorValor(coste);
      costeAnterior.cantidad = 0;
    }
  }
    costeAnterior.cantidad += coste.cantidad;
    interiores.push(this.guardarCostesPorValor(coste));
 });
 if(interiores.length > 0){
 costeAnterior.interior = interiores;
 }
 ordenado.push(costeAnterior);
 return ordenado;
}

guardarCostesPorValor(coste){
  let data = {id:coste.id,cantidad:coste.cantidad,concepto:coste.concepto,iva:coste.iva,proyecto:coste.proyecto,tipo:coste.tipo,familia:coste.familia,interior:[],abierto:false,directo:coste.directo,estado:coste.estado,fecha:coste.fecha,numero_factura:coste.numero_factura};
  return data;
}
abrirCoste(coste){
  if(coste.interior.length > 0){
    coste.abierto = !coste.abierto;
  }
}
  ngOnInit(): void {
    this.cargarInformacion();
  }
res;
  meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

  abrirPopUp(tipo){
    
    let data = {tipo:tipo,year:this.year,month:this.month};
    this.popup.openPopup(PopUpCosteComponent,data);    
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValue) {
        this.popup.clearData();
        this.cargarInformacion();        
        this._unsubscribeIndividual.next('');
      }
    });

    
  }
}