import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'gantt-tarea',
  templateUrl: './tarea.component.html',
  styleUrls: ['./tarea.component.css']
})
export class TareaComponent implements OnInit {
  @Input() tarea:any;
  
  @Output() cambioEstado: any = new EventEmitter();

  constructor() { }
  @Input() posicion:any;
top = 0;
left = 0;
width = 0;
height = 0;

  ngOnChanges(changes) {
    if(changes.tarea != undefined){
  }
  if(changes.posicion != undefined){
    if(this.posicion != undefined){
      this.top = this.posicion.top;
      this.left = this.posicion.left;
      this.width = this.posicion.width;
      this.height = this.posicion.height;
    }
  }
}
nombreMostrar = '';
mostrarAvance = false;
  ngOnInit(): void {
    if(this.tarea.completado != undefined){
      this.mostrarAvance = true;
      let porcentaje = this.tarea.completado*100;
      //redondear el porcentaje
      porcentaje = Math.round(porcentaje);
      this.nombreMostrar = this.tarea.nombre + ' (' + porcentaje + '%)';
    }else{
      this.nombreMostrar = this.tarea.nombre;
    }
  }

  cambiarEstado(){
    this.tarea.comprimida=!this.tarea.comprimida;
    this.cambioEstado.emit();
  }

}
