<div class="confirmarCerrarSesionGeneralContainer" >
    <div class="confirmarCerrarSesionConainer">
        <div class="bodyCerrarSesion">
          <span>Nuevo Cliente</span>
          <div class="formulario">
            <div class="campos">
              
            <div class="campo">
              <label [class.error]="error">Nombre</label>
              <input [class.error]="error" required [(ngModel)]="newCliente.nombre">
              </div>
              <div class="campo">
              <label>CIF</label> 
              <input required [(ngModel)]="newCliente.cif">
              </div>
              </div>            
            <div class="campos">
              <div class="campo">
              <label>Mail</label> 
              <input required [(ngModel)]="newCliente.mail">
              </div>
              <div class="campo">
              <label>Teléfono</label> 
              <input required [(ngModel)]="newCliente.telefono">
              </div>
              </div>
              <div class="campo">
              <label>Dirección</label> 
              <input required [(ngModel)]="newCliente.direccion_fiscal">
              </div>
          </div>
          <div class="bodyBotones">
            <button class="confirmar" (click)="crearCliente(true)" type="button">Guardar</button>
            <button (click)="crearCliente(false)" type="button">Cancelar</button>
          </div>
        </div>
    </div>
  </div>
  