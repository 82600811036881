import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vacaciones',
  templateUrl: './vacaciones.component.html',
  styleUrls: ['./vacaciones.component.css']
})
export class VacacionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
