<div class="confirmarGuardarBorradorGeneralContainer">
  <div class="confirmarGuardarBorradorConainer">
    <div class="bodyGuardarBorrador">
        <span>¿Estás seguro que quieres cerrar sesión?</span>
        <div class="bodyBotones">
          <button class="confirmar" (click)="confirmarCerrarSesion(true)" type="button">Sí</button>
          <button (click)="confirmarCerrarSesion(false)" type="button">No</button>
        </div>
      </div>

  </div>
</div>
