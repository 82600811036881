import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from 'src/app/Services/login.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { ProyectosService } from 'src/app/Services/proyectos.service';
import { ClientesService } from 'src/app/Services/clientes.service';
import { Clientes } from 'src/app/Interfaces/clientes';


@Component({
  selector: 'app-popup-add-cliente',
  templateUrl: './popup-add-cliente.component.html',
  styleUrls: ['./popup-add-cliente.component.css']
})
export class PopupAddClienteComponent extends UnsubscribesDestroy implements OnInit {

  constructor(public loaderSE: LoaderService, private clienteSE: ClientesService,public dialogRef: MatDialogRef<PopupAddClienteComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { super(); }

  ngOnInit(): void {
    this.newCliente = new Clientes();
    if(this.modalData.cliente != null){
      this.newCliente = this.modalData.cliente;
    }
  }
  newCliente:Clientes;
  error = false;
  crearCliente(valor:boolean){
    if (valor) {
      if(this.newCliente.nombre == null || this.newCliente.nombre == ''){
        this.error = true;
        return;
      }
      if(this.newCliente.id != null){
        this.clienteSE.updateCliente(this.newCliente);
      }else{  
        this.clienteSE.createCliente(this.newCliente);
      }
      this.loaderSE.open();
          this.clienteSE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this._unsubscribeIndividual2.next('');
            console.log(value.data.id);
            this.closePopup(true,value.data.id);
          }
          );
    }else{
      this.closePopup(false,null);
    }
  }

  closePopup(returnValue?:any,id?:any){
    let p={returnValue:returnValue,id:id};
    this.dialogRef.close(p);
  }
}
