<div class="contenedor" [style.height]="height">
    <div class="meses" [style.min-width]="anchoFases">
        <div class="mes" *ngFor="let mes of mesesString" [style.left]="mes.left+'px'" [style.width]="mes.width+'px'">
            {{mes.nombre}}
        </div>
    </div>
    <div *ngFor="let fase of fases; let i = index" class="fase" [style.height]="calcularTamFase(i)" [style.min-width]="anchoFases">
        <button (click)="comprimirFase(i)">{{i+1}}ª Fase</button>
        <gantt-tarea  *ngFor="let tarea of fase.tareas; let j = index" 
        [tarea]="tarea"
        [posicion]="tareasPosicion[indiceFaseTarea(i,j)]" 
        (cambioEstado)="calcularPosiones()"></gantt-tarea>
    </div>
    <div *ngIf="hoy && today <= fechaFin && today >= fechaInicio" [style.left]="calcularHoy()" class="hoy" [style.height]="altoTodasTareas + 'px'">
        <span class="fecha">{{today|date:'dd'}}</span>
    </div>
</div>