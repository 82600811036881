import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/Services/login.service';
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexFill, ApexTooltip, ApexXAxis, ApexLegend, ApexDataLabels, ApexTitleSubtitle, ApexYAxis} from 'ng-apexcharts';
import { PopupService } from 'src/app/Services/popup.service';
import { Router } from '@angular/router';
import { ProyectosService } from 'src/app/Services/proyectos.service';
import { takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/Services/loader.service';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { ImplicacionService } from 'src/app/Services/implicacion.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.css'],
})
export class ProyectosComponent extends UnsubscribesDestroy implements OnInit {
  toggled: boolean = false;
  potencialesTimeline: boolean = true;
  selectedPlazoTimeline: any = 'Corto plazo';
  plazos = ['Corto plazo', 'Medio plazo', 'Largo plazo'];
  selectedDepartamento: any = 'Desarrollo';
  departamentos = ['Desarrollo', 'Diseño', 'Administración'];

  proyectoSeleccionado: any;

  @ViewChild('switchLabel') switchLabel!: ElementRef;
  @ViewChild('switchLabel') switchLabel2!: ElementRef;

  @ViewChild('chart')
  chart!: ChartComponent;
  public chartOptionsGrafica!: Partial<ChartOptions> | any;

  @ViewChild('dottedchart') dottedchart!: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  @ViewChild('chart2') chart2!: ChartComponent;
  public chartOptions2: Partial<ChartOptions> | any;

  proyectos = [];

  constructor(
    public popup: PopupService,
    public router: Router,
    private loginSE: LoginService,
    private proyectosSE: ProyectosService,
    private loaderSE: LoaderService,
    private implicacionSE: ImplicacionService
  ) {
    super();

    this.chartOptions2 = {
      annotations: {
        xaxis: [
          {
            x: new Date().getTime(),
            borderColor: '#CD2F2A',
            width: 5,
            strokeDashArray: 0          
          }
        ]
      },
      series: [
        {
          data: [],
        },
      ],
      chart: {
        type: 'rangeBar',
        height: 360,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          style:{
            colors:'#1f586b',
            fontWeight: 600,
            fontFamily: 'Satoshi',
          },
        },
      },
      yaxis: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          var label = opts.w.globals.labels[opts.dataPointIndex];
          var a = val[0];
          var b = val[1];

          var diff = Math.abs(a - b);
          var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
          return label + ': ' + diffDays + (diffDays > 1 ? ' dias' : ' día');
        },
        style: {
          colors: ['#836d5d'],
          fontFamily: 'Satoshi',
          fontWeight: 500,
        },
      },
    };

    this.chartOptions = {
      series: [],
      xaxis:{
        labels: {
          style:{
            colors:'#1f586b',
            fontWeight: 600,
            fontFamily: 'Satoshi',
          },
        },
      },
      yaxis:{
        labels: {
          style:{
            colors:'#1f586b',
            fontFamily: 'Satoshi',
          },
        },
      },

      chart: {
        height: 400,
        type: 'heatmap',
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        heatmap: {
          // radius: 50,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 9,
                color: '#f5f5f5',
                name: 'Ocioso',
              },
              {
                from: 10,
                to: 49,
                color: '#C9E1C7',
                name: 'Bajo',
              },
              {
                from: 50,
                to: 79,
                color: '#F9C095',
                name: 'Medio',
              },
              {
                from: 80,
                to: 100,
                color: '#F0948C',
                name: 'Alto',
              },
            ],
            inverse: false,
            min: 10,
            max: 100,
          },
        },
      },
    };
    this.chartOptionsGrafica = {
      series: [],
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        offsetX: 40,
      },
      chart: {
        height: 350,
        type: 'bar',
        stacked: true,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 4],
      },
      xaxis: {
        categories: [1, 2, 3, 4, 5, 6, 7],
        labels: {             
          style:{
            colors:'#1f586b',
            fontWeight: 600,
            fontFamily: 'Satoshi',          
          },
          formatter: function (val) {
            return 'Semana ' + val;
          },
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 10,        
        style:{
          colors:'#1f586b',
          fontFamily: 'Satoshi',          
        },
        labels: {
          formatter: (value: any) => {
            return value + '%';
          },
        },
      },
      grid: {
        show: false,
      },
      colors: ['#A6AFDA'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '42px',
          borderRadius: 8,
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return Math.round(val * 100) / 100 + '%';
          },
        },
        //  enabled: false,
      },
    };
  }
  opcion = 'Proyectos';
  opcionSeleccionada(event: string) {
    this.opcion = event;
    if(this.chart != null){
      this.chart.updateSeries(this.chartOptions.series);
    }
    if(this.chart2 != null){
      this.chart2.updateSeries(this.chartOptions2.series);
    }
    if(this.dottedchart != null){
      this.dottedchart.updateSeries(this.chartOptions.series);
    }
  }
  ngOnDestroy() {
    this._unsubscribeIndividual.next('');
  }
  ngOnInit(): void {
    localStorage.setItem('lastRoute', this.router.url);
    if (
      localStorage.getItem('token') === null &&
      localStorage.getItem('login') != 'login'
    ) {
      this.loginSE.desconectar();
    } else {
      this.getProyectosEmpresa();
      this.getInfoGraficoCarga();
    }
  }

  getInfoGraficoCarga() {
    this.loaderSE.open();
    this.implicacionSE.getImplicacionSemana();
    this.implicacionSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }

        this.cargasSemana = value.emp;
        this.horasSemana = value.horas;
        this.proyectosSemana = value.imp;
        this.proyectosResumen = value.proyectos;
        this._unsubscribeIndividual2.next('');
        this.loaderSE.closeContador();
        this.calcularCarga();
        this.calcularCargaProyectos();
      });
  }
  cargasSemana;
  horasSemana;
  proyectosSemana;
  proyectosResumen;

  isToggled() {
    this.toggled = this.switchLabel.nativeElement.checked;
  }
  ispotencialesTimeline() {
    this.calcularTimeline();
  }
  abrirPopupAddProyecto() {
    this.router.navigate(['/proyectos/nuevo-proyecto']);
  }


  
  public generateData(count: any, yrange: any) {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = (i + 1).toString();
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y,
      });
      i++;
    }
    return series;
  }

  getProyectosEmpresa() {
    this.proyectosSE.getAllProyectos(5000, 1,"estadisticas");
    this.loaderSE.open();
    this.proyectosSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.proyectosActivos = 0;
        this.proyectosPausados = 0;
        this.proyectosFinalizados = 0;
        this.proyectosArchivados = 0;
        value.forEach((element) => {
          switch (element.estado) {
            case 1:
              this.proyectosActivos++;
              break;
            case 0:
              this.proyectosPausados++;
              break;
            case 2:
              this.proyectosFinalizados++;
              break;
            case 3:
              this.proyectosHibernados++;
              break;
            case -1:
              this.proyectosArchivados++;
              break;
          }
          if (!this.proyectos.includes(element)) {
            this.proyectos.push(element);
          }
        });
        this._unsubscribeIndividual.next('');
        this.loaderSE.closeContador();

        this.calcularTimeline();
      });
  }

  filtro = 1;
  filtrar(valor){
    if(valor == this.filtro){
      this.filtro = null;
    }else{
      this.filtro = valor;
    }
  }

  proyectosActivos = 0;
  proyectosPausados = 0;
  proyectosFinalizados = 0;
  proyectosArchivados = 0;
  proyectosHibernados = 0;


  calcularTimeline() {
    this.chartOptions2.series[0].data = [];
    let maximoTiempo = new Date();
    let hoy = new Date();
    switch (this.selectedPlazoTimeline) {
      case 'Corto plazo':
        maximoTiempo.setDate(maximoTiempo.getDate() + 7);
        break;
      case 'Medio plazo':
        maximoTiempo.setDate(maximoTiempo.getDate() + 21);
        break;
      case 'Largo plazo':
        maximoTiempo.setDate(maximoTiempo.getDate() + 49);
        break;
    }
    this.proyectos.forEach((proyecto) => {
      if (
        new Date(proyecto.fecha_ini).getTime() <= maximoTiempo.getTime() &&
        new Date(proyecto.fecha_fin).getTime() >= hoy.getTime()
      ) {
        if (this.potencialesTimeline || !proyecto.potencial) {
          this.chartOptions2.series[0].data.push({
            x: proyecto.nombre,
            y: [
              new Date(proyecto.fecha_ini).getTime(),
              new Date(proyecto.fecha_fin).getTime(),
            ],
            fillColor: proyecto.etiqueta,
            /*goals:[
              {
                name:"hoy",
                value: hoy.getTime(),
                strokeColor: '#CD2F2A'
              }
            ]*/
          });
        }
      }
    });
    if(this.chart2!=null){
    this.chart2.updateSeries(this.chartOptions2.series);
    }
  }
  calcularCargaProyectos() {
    let series = [];

    let cont = 0;

    let idsPoryectos = Object.keys(this.proyectosResumen);

    for (let idProy of idsPoryectos) {
      series[idProy] = {
        name: this.proyectosResumen[idProy].t,
        type: 'bar',
        data: [0, 0, 0, 0, 0, 0, 0],
        color: this.proyectosResumen[idProy].c,
      };
    }
    this.chartOptionsGrafica.series = series;

    this.proyectosSemana.forEach((semana) => {
      let proyectos = Object.keys(semana);
      for (let elemento of proyectos) {
        let carga = 0;
        if (!this.toggled || (this.toggled && !semana[elemento].p)) {
          carga += semana[elemento].h;
        }
        this.chartOptionsGrafica.series[elemento].data[cont] =
          (carga / this.horasSemana) * 100;
      }

      cont++;
    });
    var filtered = this.chartOptionsGrafica.series.filter(function (el) {
      if (el != null) {
        let vacio = true;
        el.data.forEach((element) => {
          if (element != 0) {
            vacio = false;
          }
        });
        if (vacio) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    });
    this.chartOptionsGrafica.series = filtered;
    if(this.chart != null){
      this.chart.updateSeries(this.chartOptionsGrafica.series);
    }
  }
  calcularCarga() {
    this.chartOptions.series = [];
    this.cargasSemana.forEach((empleado) => {
      this.chartOptions.series.push({
        name: empleado.nombre + ' ' + empleado.apellidos,
        data: this.arrayToData(empleado.carga),
      });
      if(this.dottedchart != null){
        this.dottedchart.updateSeries(this.chartOptions.series);
      }
    });
  }
  arrayToData(array) {
    let data = [];
    let cont = 1;
    array.forEach((element) => {
      data.push({
        x: 'Semana ' + cont,
        y: element,
      });
      cont++;
    });
    return data;
  }
}
