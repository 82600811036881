import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/Services/login.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() elementoActivo = '';
  @Output() subMenuOpcion = new EventEmitter<string>();

  constructor(private router: Router, private loginSE:LoginService) { }

  admin = false;
  ngOnInit(): void {    
    this.admin = this.loginSE.isIntermedio();
  }
  abierto = false;
}
