import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hello',
})
export class HelloPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let hora = new Date();
    let saludo = 'Buenos días';
    switch (hora.getHours()) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 21:
      case 22:
      case 23:
      case 24:
        saludo = 'Buenas noches';
        break;
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
        saludo = 'Buenas tardes';
        break;
    }

    return saludo;
  }
}
