import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.css'],
})
export class ModalImageComponent implements OnInit {
  @Input('aspectRatio') aspectRatio: number;
  @Input('tam') tam: number;
  @Input('titulo') titulo: string;
  @Output() propagar = new EventEmitter<boolean>();
  @Output() imagen = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}
  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  cerrarPopUp(event: Event) {
    this.propagar.emit(false);
  }
  enviar() {
    if (this.croppedImage != '') {
      this.imagen.emit(this.croppedImage);
    }
  }
}
