import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'iconocargar',
  templateUrl: './iconoCargar.component.html',
  styleUrls: ['./iconoCargar.component.css']
})
export class IconoCargarComponent implements OnInit {

  @Input("size") size:any;

  classSize=" CargarIconoNormalSize";

  constructor() { }

  ngOnInit() {
    if(this.checkSize!=null){
      this.checkSize();
    }
  }
  checkSize(){
    if(this.size=="small"){
      this.classSize=" CargarIconoSmallSize";
    }else if(this.size=="normal"){
      this.classSize=" CargarIconoNormalSize";
    }else if(this.size=="grande"||this.size=="big"){
      this.classSize=" CargarIconoBigSize";
    }else{
      new Error(this.size+" no es un tamaño válido. Los tamaños válidos son: small, normal, grande/big");
    }
  }

}
