<div class="proyectosGeneralContainer">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'presupuestos'"></sidebar>
    </div>
      <div class="dataGeneralContainer">
        <div class="dataHeaderContainer">
          <app-header-bar titulo="Presupuestos" (accionBoton)="abrirPopupAddPresupuesto()" [boton]="false"></app-header-bar>
        </div>
        <div *ngIf="false" class="navegacionGeneralContainer">
          <menu-navegacion
          [icons]="['/assets/icons/grid.png','/assets/icons/timeline.png','/assets/icons/leaderboard.png']"
            [opcionPorDefecto]="'Proyectos'"
            [opciones]="['Proyectos','Timeline', 'Analítica']"
            (emitOpcionSeleccionada)="opcionSeleccionada($event)"
          ></menu-navegacion>
        </div>
        <div class="dataBodyContainer">        
          <div *ngIf="opcion == 'Proyectos'" class="proyectoPorProyectManager">
            <div class="filtro">
              <span>Filtro de Project Managers: </span>
              <div *ngFor="let manager of managersProyecto">
                <label
                  ><input
                    type="checkbox"
                    value="{{ manager.id }}"
                    (click)="filtroManager(manager)"
                  />
                  <span>{{ manager.nombre }}</span></label
                >
              </div>
            </div>
            <div class="tarjetasProyectos">
              <ng-container *ngIf="managers.length == 0">
                <ng-container *ngFor="let proyecto of proyectos">
                  <tarjeta-proyecto [proyecto]="proyecto"></tarjeta-proyecto>
                </ng-container>
              </ng-container>
  
              <ng-container *ngIf="managers.length != 0">
                <ng-container *ngFor="let proyecto of proyectos">
                  <ng-container *ngFor="let manager of managers">
                    <tarjeta-proyecto *ngIf="proyecto.responsable.id == manager.id" [proyecto]="proyecto"></tarjeta-proyecto>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
  </div>
  