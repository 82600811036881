<div class="dashboardGeneralContainer">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'costes'"></sidebar>
    </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar [cerrar]="true" [titulo]="'Gastos ' + meses[month-1]"></app-header-bar>
      </div>  
    <div class="dataBodyContainer">
        <div class="seccionCostes">
            <div class="infoCabecera"><p>Variable</p>
            <button (click)="abrirPopUp(1)">+ Gasto</button></div>
            <div class="tabla">
                <div class="cabecera">
                    <div>Concepto</div>
                    <div>Familia</div>
                    <div>Proyecto</div>
                    <div>Iva</div>
                    <div>Gasto</div>
                </div>
                <ng-container *ngFor="let item of res">
                    <div *ngIf="item.tipo==1">
                        <div>{{item.concepto}}</div>
                        <div *ngIf="item.familia != null">{{item.familia.nombre}}</div>
                        <div *ngIf="item.familia == null">-</div>
                        <div *ngIf="item.proyecto != null">{{item.proyecto.nombre}}</div>
                        <div *ngIf="item.proyecto == null">-</div>
                        <div>{{item.iva*0.01 | percent}}</div>
                        <div>{{item.cantidad | currency:'EUR'}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="seccionCostes">
            <div class="infoCabecera"><p>Fijo</p>
            <button (click)="abrirPopUp(0)">+ Gasto</button></div>
            <div class="tabla">
                <div class="cabecera">
                    <div>Concepto</div>
                    <div>Familia</div>
                    <div>Proyecto</div>
                    <div>Iva</div>
                    <div>Gasto</div>
                </div>
                <ng-container *ngFor="let item of res">
                    <div *ngIf="item.tipo==0">
                        <div>{{item.concepto}}</div>
                        <div *ngIf="item.familia != null">{{item.familia.nombre}}</div>
                        <div *ngIf="item.familia == null">-</div>
                        <div *ngIf="item.proyecto != null">{{item.proyecto.nombre}}</div>
                        <div *ngIf="item.proyecto == null">-</div>
                        <div>{{item.iva*0.01 | percent}}</div>
                        <div>{{item.cantidad | currency:'EUR'}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="seccionCostes">
            <div class="infoCabecera"><p>Periodo</p>
            <button (click)="abrirPopUp(2)">+ Gasto</button></div>
            <div class="tabla">
                <div class="cabecera">
                    <div>Concepto</div>
                    <div>Familia</div>
                    <div>Proyecto</div>
                    <div>Iva</div>
                    <div>Gasto</div>
                </div>
                <ng-container *ngFor="let item of res">
                    <div *ngIf="item.tipo==2">
                        <div>{{item.concepto}}</div>
                        <div *ngIf="item.familia != null">{{item.familia.nombre}}</div>
                        <div *ngIf="item.familia == null">-</div>
                        <div *ngIf="item.proyecto != null">{{item.proyecto.nombre}}</div>
                        <div *ngIf="item.proyecto == null">-</div>
                        <div>{{item.iva*0.01 | percent}}</div>
                        <div>{{item.cantidad | currency:'EUR'}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
  </div>
  