import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DailyService } from 'src/app/Services/daily.service';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { ProyectosService } from 'src/app/Services/proyectos.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { ProcesosService } from 'src/app/Services/procesos.service';

@Component({
  selector: 'app-popup-add-daily',
  templateUrl: './popup-add-daily.component.html',
  styleUrls: ['./popup-add-daily.component.css']
})
export class PopupAddDailyComponent
  extends UnsubscribesDestroy implements OnInit {

  constructor(private proyectosSE: ProyectosService, public loaderSE: LoaderService, private dailySE: DailyService, public dialogRef: MatDialogRef<PopupAddDailyComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificacionesSE: NotificacionesService, private procesosSE: ProcesosService) { super(); }

  ngOnInit(): void {
    this.trabajadores = this.modalData.trabajadores;
    this.idUser = this.modalData.idUser;
    if (this.idUser == -1) {
      this.idUser = Number(localStorage.getItem('idUser'));
      this.admin = false;
    }
    this.fecha = this.modalData.fecha;
    this.getProyectosUser();
  }
  admin = true;
  idProyecto;
  trabajadores;
  idUser;
  fecha;
  nombreTarea;
  avance;
  horasEstimadas;
  minutosEstimados;
  idProceso;
  procesoSubProceso;
  seleccionarProceso(val) {
    if (val == null) {
      this.procesoSeleccionado = null;
      return;
    } else {
      this.procesoSeleccionado = val;
    }
  }
  procesoSeleccionado = null;
  crearDaily(valor: boolean) {
    if (valor) {
      let auxHoras = this.horasEstimadas;
      setTimeout(() => {
        this.minutosEstimados == null || this.minutosEstimados == 0 ? auxHoras = auxHoras : auxHoras = auxHoras + (this.minutosEstimados / 60);
        let data = {
          nombreTarea: this.nombreTarea, avanceTeorico: this.avance / 100.0, horasEstimadas: auxHoras,
          usuario: this.idUser, fecha: this.fecha, proyecto: this.idProyecto, proceso: null, subProceso: null
        };
        if(this.procesoSubProceso!=null){
          if(this.procesoSubProceso.subProceso){
            data.subProceso = this.procesoSubProceso.id;
          }else{
            data.proceso = this.procesoSubProceso.id;
          }
        }
        console.log(data);
        this.dailySE.createDaily(data);
        this.loaderSE.open();
      }, 1);


      this.dailySE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this._unsubscribeIndividual2.next('');
        this.closePopup(true);
      }
      );
      this.dailySE.getResultUpdateError().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this._unsubscribeIndividual2.next('');
        console.log(value.error);
        this.notificacionesSE.showError(value.error.message);
      });
    } else {
      this.closePopup(false);
    }
  }

  closePopup(returnValue?: any) {
    let p = { returnValueDaily: returnValue };
    this.dialogRef.close(p);
  }

  limitMinutes() {
    this.minutosEstimados >= 60 ? this.minutosEstimados = 59 : this.minutosEstimados = this.minutosEstimados
  }


  proyectos = [];

  getProyectosUser() {
    this.procesosSE.getProcesosUser(this.idUser);

    this.procesosSE.getResult().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
      if (value == null) {
        return;
      }
      this._unsubscribeIndividual2.next('');
      console.log(value);
      this.idProceso = null;
      this.idProyecto = null;
      this.proyectos = value.proyectos;
      this.procesosTot = value.procesos;
      this.procesos = [];
    });
  }
  procesosTot = [];
  procesos = [];
  getProcesosProyecto() {
    this.idProceso = null;
    console.log(this.idProyecto);
    this.procesos = [];
    this.procesosTot.forEach(proceso => {
      console.log(proceso);
      if (proceso.proyecto == this.idProyecto) {
        this.procesos.push(proceso);
      }
    });
  }
}
