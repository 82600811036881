import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'weekly-info-proyecto',
  templateUrl: './weekly-info-proyecto.component.html',
  styleUrls: ['./weekly-info-proyecto.component.css']
})
export class WeeklyInfoProyectoComponent implements OnInit {

  constructor() { }

  @Input() proyectoSeleccionado;
  @Input() empleados;
  @Input() seleccionado;
  @Output() cerrar = new EventEmitter<any>();
  
cerrarProyecto(){
  this.cerrar.emit(null);
}
  ngOnInit(): void {
  }

}
