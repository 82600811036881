import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'menu-navegacion',
  templateUrl: './menu-navegacion.component.html',
  styleUrls: ['./menu-navegacion.component.css']
})
export class MenuNavegacionComponent implements OnInit {

  @Input() opciones:string[] = [];
  @Input() icons:string[] = [];
  @Input() deshabilitarSubirClick:boolean = false;
  @Input() redondo:boolean = false;
  @Input() colorSecundario:boolean = false;
  @Input() condensed:boolean = false;
  @Input() opcionPorDefecto = '';
  @Input() tipoMenu:string = "general";
  @Output() emitOpcionSeleccionada= new EventEmitter<string>();
  @Output() emitOpcionSubmenuSeleccionada= new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    if(!this.deshabilitarSubirClick){
      this.emitOpcionSeleccionada.emit(this.opcionPorDefecto);
    }
  }
  getIndiceOpcion(opcion: string) {
    return this.opciones.indexOf(opcion);
  }
  opcionNavegacion(opcion:string){
    if(this.deshabilitarSubirClick){
      console.log(this.getIndiceOpcion(opcion));
      console.log(this.getIndiceOpcion(this.opcionPorDefecto));
      if(this.getIndiceOpcion(opcion) > this.getIndiceOpcion(this.opcionPorDefecto)){      
      return;
      }
    }
    this.opcionPorDefecto = opcion;
    this.emitOpcionSeleccionada.emit(this.opcionPorDefecto);
  }
  opcionNavegacionSubmenu(opcion: string){
    this.opcionPorDefecto = opcion;
    this.emitOpcionSubmenuSeleccionada.emit(this.opcionPorDefecto);
  }
}
