import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private _returnData = new BehaviorSubject<any>(null);

  constructor(private dialog: MatDialog) {}
  protected open(
    element: any,
    data?: any,
    addClass?: any
  ): MatDialogRef<unknown, any> {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    if (data != null) {
      dialogConfig.data = data;
    }
    if (addClass != null) {
      dialogConfig.panelClass = addClass;
    }
    let modalDialog = this.dialog.open(element, dialogConfig);
    return modalDialog;
  }
  protected listenerWhenClose(popup: MatDialogRef<unknown, any>) {
    popup.beforeClosed().subscribe((result) => {
      let j = document.body.getElementsByClassName('tooltipContainerBody')[0];
      if (j != null) {
        j.remove();
      }
    });
    popup.afterClosed().subscribe((result) => {
      if (result == null) {
        return;
      }
      result.popupName = name;
      this._returnData.next(result);
      setTimeout(() => {
        this.clearData();
      }, 1500);
    });
  }
  openPopup(
    element: any,
    data?: any,
    popupClass?: string
  ): { name: string; data: any; popupClass?: string } {
    let popup = this.open(element, data, popupClass);
    this.listenerWhenClose(popup);
    return { name: element.name, data: data };
  }

  openFullPopup(element: any, data?: any) {
    let popup = this.open(element, data, 'fullSizePopup');
    this.listenerWhenClose(popup);
  }
  returnData() {
    return this._returnData;
  }
  clearData() {
    this._returnData.next(null);
  }
}
