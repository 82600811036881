<div class="dataHeaderSaludo">
  <svg
    *ngIf="cerrar"
    (click)="cerrarPop()"
    xmlns="http://www.w3.org/2000/svg"
    width="22.164"
    height="22.164"
    viewBox="0 0 22.164 22.164"
  >
    <g
      id="Grupo_535"
      data-name="Grupo 535"
      transform="translate(-765.793 -924.793)"
    >
      <line
        id="Línea_105"
        data-name="Línea 105"
        x2="20.75"
        y2="20.75"
        transform="translate(766.5 925.5)"
        fill="none"
        stroke="#3b758f"
        stroke-width="2"
      />
      <line
        id="Línea_106"
        data-name="Línea 106"
        x1="20.75"
        y2="20.75"
        transform="translate(766.5 925.5)"
        fill="none"
        stroke="#3b758f"
        stroke-width="2"
      />
    </g>
  </svg>
  <p *ngIf="titulo != ''">{{ titulo }}</p>
  <p *ngIf="titulo == ''">👋🏼 ¡{{ "" | hello }} {{localStorage.getItem('nombre')}}!</p>
  <ng-container *ngIf="boton"><button class="add" (click)="accionBoton()">+</button></ng-container>
</div>
<div class="dataHeaderBuscador">
  <span *ngIf="false">
    <div class="inputBuscador">
      <input
        type="text"
        placeholder="Busca aquí proyecto o integrante"
        [(ngModel)]="filtro"
      />
    </div>
    <div
      class="iconoBorrarBuscador"
      [class.visible]="filtro != ''"
      (click)="borrarTexto()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.436"
        height="17.903"
        viewBox="0 0 17.436 17.903"
      >
        <g
          id="Grupo_1450"
          data-name="Grupo 1450"
          transform="translate(0.718 0.696)"
          opacity="0.329"
        >
          <line
            id="Línea_319"
            data-name="Línea 319"
            x2="16"
            y2="16.5"
            fill="none"
            stroke="#3d4345"
            stroke-width="2"
          />
          <line
            id="Línea_320"
            data-name="Línea 320"
            x1="16"
            y2="16"
            transform="translate(0 0.5)"
            fill="none"
            stroke="#3d4345"
            stroke-width="2"
          />
        </g>
      </svg>
    </div>
    <div class="IconoBuscador">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="23.38"
        viewBox="0 0 24 23.38"
      >
        <path
          id="Unión_1"
          data-name="Unión 1"
          d="M22.19,23.292l-4.029-3.925a11.181,11.181,0,0,1-7.046,2.482A11.032,11.032,0,0,1,0,10.924,11.032,11.032,0,0,1,11.114,0,11.032,11.032,0,0,1,22.229,10.924a10.755,10.755,0,0,1-2.365,6.73l4.045,3.94c.223.219.021.776-.455,1.244a1.659,1.659,0,0,1-1.013.543A.348.348,0,0,1,22.19,23.292ZM2.785,10.924A8.261,8.261,0,0,0,11.114,19.1a8.26,8.26,0,0,0,8.327-8.172,8.261,8.261,0,0,0-8.327-8.173A8.262,8.262,0,0,0,2.785,10.924Z"
          fill="var(--color-tonalidad-principal1)"
        />
      </svg>
    </div>
  </span>
</div>
<div class="dataHeaderIconos">
  <span>
    <svg
      id="Grupo_1175"
      data-name="Grupo 1175"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_2520"
            data-name="Rectángulo 2520"
            width="26"
            height="26"
            fill="none"
            stroke="#000"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g id="Grupo_1174" data-name="Grupo 1174" clip-path="url(#clip-path)">
        <path
          id="Trazado_285"
          data-name="Trazado 285"
          d="M20.994,12.491V9.252a8.5,8.5,0,1,0-17,0v3.239L.75,19.374H24.233Z"
          transform="translate(0.465 0.465)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Trazado_286"
          data-name="Trazado 286"
          d="M5,12.75S5,16.8,9.859,16.8s4.859-4.049,4.859-4.049"
          transform="translate(3.098 7.899)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </svg>
  </span>
  <span (click)="verSubmenu = !verSubmenu">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.912"
      height="27.954"
      viewBox="0 0 22.912 27.954"
    >
      <g id="Grupo_235" data-name="Grupo 235" transform="translate(0 -1)">
        <g
          id="Trazado_24"
          data-name="Trazado 24"
          transform="translate(-1 13.796)"
          fill="none"
        >
          <path
            d="M12.456,0c6.5,0,11.456,3.784,11.456,10.283S1,16.783,1,10.283,5.957,0,12.456,0Z"
            stroke="none"
          />
          <path
            d="M 12.45613384246826 1.000002861022949 C 9.463883399963379 1.000002861022949 6.795154571533203 1.874932289123535 4.941543579101562 3.46361255645752 C 3.017173767089844 5.112942695617676 2.000003814697266 7.471132278442383 2.000003814697266 10.28323268890381 C 2.000003814697266 11.61471271514893 3.595424652099609 12.49761199951172 4.933803558349609 13.00382232666016 C 6.872804641723633 13.73719215393066 9.614583969116211 14.15780258178711 12.45613384246826 14.15780258178711 C 15.29768371582031 14.15780258178711 18.03946304321289 13.73719215393066 19.97845458984375 13.00382232666016 C 21.31684303283691 12.49761199951172 22.91226387023926 11.61471271514893 22.91226387023926 10.28323268890381 C 22.91226387023926 7.471132278442383 21.89509391784668 5.112942695617676 19.97072410583496 3.46361255645752 C 18.11711502075195 1.874932289123535 15.44838428497314 1.000002861022949 12.45613384246826 1.000002861022949 M 12.45613384246826 1.9073486328125e-06 C 18.95556449890137 1.9073486328125e-06 23.91226387023926 3.78380298614502 23.91226387023926 10.28323268890381 C 23.91226387023926 16.78266143798828 1.000003814697266 16.78266334533691 1.000003814697266 10.28323268890381 C 1.000003814697266 3.78380298614502 5.956703186035156 1.9073486328125e-06 12.45613384246826 1.9073486328125e-06 Z"
            stroke="none"
            fill="#000"
          />
        </g>
        <g
          id="Elipse_245"
          data-name="Elipse 245"
          transform="translate(5.853 1)"
          fill="none"
          stroke="#000"
          stroke-width="1"
        >
          <circle cx="6" cy="6" r="6" stroke="none" />
          <circle cx="6" cy="6" r="5.5" fill="none" />
        </g>
      </g>
    </svg>
  </span>
</div>
<div class="subMenu" *ngIf="verSubmenu">
  <ul>
    <li routerLink="/ajustes">Mi perfil</li>
    <li *ngIf="loginSE.isAdmin()" routerLink="/empresa">Configuración</li>
    <li (click)="cerrarSesion()">Cerrar sesión</li>
  </ul>
</div>
