import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/Services/login.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'Popup-cerrarSesion',
  templateUrl: './popup-confirmacion-cerrar-sesion.component.html',
  styleUrls: ['./popup-confirmacion-cerrar-sesion.component.css']
})
export class PopupConfirmacionCerrarSesionComponent implements OnInit {

  constructor(private loginService: LoginService, public dialogRef: MatDialogRef<PopupConfirmacionCerrarSesionComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private router: Router) { }

  ngOnInit(): void {}

  confirmarCerrarSesion(valor:boolean){
    if (valor) {
      this.closePopup();
      this.loginService.desconectar();
    }else{
      this.closePopup();
    }
  }

  closePopup(returnValue?:any){
    let p={returnValue:returnValue};
    this.dialogRef.close(p);
  }
}
