<div class="confirmarGuardarBorradorGeneralContainer">
  <div class="confirmarGuardarBorradorConainer">
    <div class="bodyPopUp">
      <div class="cabecera">
      <span>¡Buenos días {{nombre}}! 👋🏼</span>
      <p>Tienes algunas tareas pendientes...</p>
    </div>
      <div class="dailyes">
        <b>Tus daylies pasados</b>
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let daily of daylies">
          <ng-template carouselSlide><div class="daily">
            <div class="cabecera">
              <p class="fecha">{{daily.fecha | date}}</p>
              <p class="proyecto">{{daily.proyecto.abreviatura}}</p>
            </div>
            <div class="cuerpo">
            <p>{{daily.proceso.nombre}}</p>
            <p>{{daily.nombre_tarea}}</p>
          </div>
            <div class="botones">
            <button (click)="hecho(daily.id)"><img src="/assets/icons/check.svg"></button>
            <button (click)="fallado(daily.id)"><img src="/assets/icons/x.svg" style="width: 30px;"></button>
          </div>
            </div></ng-template>
        </ng-container>
        </owl-carousel-o>
        
      </div>
      <div class="motivos" *ngIf="mostrarMotivo">
        <label>Motivo</label>        
        <ng-select class="popupSelector" [items]="categorias" placeholder="Categoría" bindLabel="nombre"
        bindValue="id" [(ngModel)]="categoria"></ng-select>
        <label>Comentario</label>        
        <input [(ngModel)]="motivo" placeholder="Escribe aquí">
        <button (click)="aceptarCambio()">Enviar</button>
      </div>
      <div class="bodyBotones">
          <button (click)="confirmarGuardarBorrador(false)" type="button">
            Recordar más tarde
          </button>
      </div>
    </div>
  </div>
</div>
