import { Injectable } from '@angular/core';
import { BaseService } from '../Base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContratoService extends BaseService {

  constructor(protected http: HttpClient) {
    super();
  }
  crearContrato(contrato) {    
    this.http
      .post(this.urlApi + 'api/contrato',contrato, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {          
          this.sendNextResult('resultError', error, { method: this.crearContrato, args: [contrato] });
          this.checkStatusError(error);
        },
      });
  }

  eliminarContrato(id) {
    this.http
      .delete(this.urlApi + 'api/contrato/'+id, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {          
          this.sendNextResult('resultError', error, { method: this.eliminarContrato, args: [id] });
          this.checkStatusError(error);
        },
      });
  }
}
