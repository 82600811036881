<div class="dashboardGeneralContainer admin">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'implicacion'"></sidebar>
    </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar></app-header-bar>
      </div>
  
      <div class="cuerpo">
        <div class="infoSuperior">
            <div class="semana">Semana {{numeroSemana}}</div>
            <div class="aviso"><p>Aún no has generado el weekly de esta semana</p> 
              
          <button class="btn" (click)="generar()">Sugiéreme Weekly</button>
          <button class="btn" (click)="validar()">Validar Weekly</button></div>
            <div *ngIf="false" class="aviso"><p>Todavía tienes pendiente validar el Weekly de la semana pasada</p> 
              
          <button (click)="generar()">Sugiéreme Weekly</button><button class="btn">Resolver ahora</button></div>
        </div>
        <div class="tarjetas">
            <div class="tarjetaSuperior">
                <div class="etiquetaContainer">Corto Plazo</div>
                <grafico-velocimetro [porcentaje]="cortoPlazoCarga/horasEmpresa*100"></grafico-velocimetro>
            </div>
            <div class="tarjetaSuperior">
                <div class="etiquetaContainer">Medio Plazo</div>
                <grafico-velocimetro [porcentaje]="porcentajeMedioPlazo"></grafico-velocimetro>
            </div>
            <div class="tarjetaSuperior">
                <div class="etiquetaContainer">Largo Plazo</div>
                <grafico-velocimetro [porcentaje]="porcentajeLargoPlazo"></grafico-velocimetro>
            </div>
            
          <div class="graficoDistribucion">
            <div class="grafico" *ngIf="horas.length > 0">
              <grafico-pie [valores]="horas" [colores]="colores" width="130px" [mostrarTotal]="false" [grosor]="10" [separacion]="16"></grafico-pie>
            </div>
            <div class="leyenda" *ngIf="horas.length > 0">
              <ng-container *ngFor="let item of proyectosWeekly">
                <div *ngIf="item.horas > 0" class="item">
                  <div class="indicador">
                    <div class="color" [style.background]="item.color"></div>
                    <div class="nombre">{{item.abreviatura}}</div>
                  </div>
                  <div class="horas">{{item.horas | number: "0.0-1"}} horas</div>
                  <div class="porcentaje">{{item.horas / horasTotales | percent: "1.0-1"}}</div>
                </div>
              </ng-container>
            </div>
            <div class="placeholder" *ngIf="horas.length == 0">
              <p>Todavía no se han asignado horas en este weekly</p>
            </div>
          </div>
        </div>

        <div *ngIf="proyectoSeleccionado==null" class="proyectos">
          <weekly-info-proyecto [proyectoSeleccionado]="proyecto" *ngFor="let proyecto of proyectosWeekly" (click)="seleccionar(proyecto)"></weekly-info-proyecto>
         
        </div>
        <weekly-info-proyecto class="seleccionado" *ngIf="proyectoSeleccionado!=null" [proyectoSeleccionado]="proyectoSeleccionado" [seleccionado]="true" [empleados]="empleadosProyecto" (cerrar)="seleccionar(null)"></weekly-info-proyecto>

        <div *ngIf="proyectoSeleccionado!=null">
          <div class="tabla">
            <div class="linea cabecera">
              <div></div>
              <div>Empleado</div>
              <div>Proceso</div>
              <div>Subproceso</div>
              <div class="pequenyo">Horas</div>
              <div class="pequenyo">Key result</div>
              <div class="pequenyo"></div>
            </div>
            <div class="linea" *ngFor="let recurso of proyectoSeleccionado.recursos; let i = index">
              <div><ng-container *ngIf="recurso.profile == null">
                <span>{{ recurso.nombre | slice: 0:1}}{{ recurso.apellidos | slice: 0:1 }}</span>
            </ng-container>
            <ng-container *ngIf="recurso.profile != null">
                <img [src]="environment.urlImg + recurso.profile">
            </ng-container></div>
              <div><ng-container *ngIf="recurso.editarTotal"><ng-select *ngIf="empleados.length>0" (change)="seleccionarUsuario(recurso,i)" [items]="empleados" bindValue="id" bindLabel="nombre" [(ngModel)]="recurso.id"></ng-select></ng-container><p *ngIf="!recurso.editarTotal" class="nombre">{{recurso.nombre}} {{recurso.apellidos}}</p></div>
              <div><ng-container *ngIf="recurso.editarTotal"><ng-select *ngIf="procesosSeleccion[i].length>0" (change)="seleccionarProceso(recurso,i)" [items]="procesosSeleccion[i]" bindValue="id" bindLabel="nombre" [(ngModel)]="recurso.proceso_id"></ng-select></ng-container><p *ngIf="!recurso.editarTotal" class="proceso">{{recurso.proceso}}</p></div>
              <div><p class="subproceso">
                <ng-container *ngIf="recurso.editarTotal"><ng-select *ngIf="subProcesosSeleccion[i].length>0" (change)="seleccionarSubProceso(recurso)" [items]="subProcesosSeleccion[i]" bindValue="id" bindLabel="nombre" [(ngModel)]="recurso.subProceso_id"></ng-select></ng-container>
                <p *ngIf="!recurso.editarTotal" class="proceso">{{recurso.subProceso}}</p>

              </div>
              <div class="pequenyo horas"><ng-container *ngIf="!recurso.editar">{{recurso.horas}} horas</ng-container><input min="0" *ngIf="recurso.editar" (input)="recalcularEmpleado()" type="number" [(ngModel)]="recurso.horas"> </div>
              <div class="pequenyo"><span class="keyresult">{{recurso.horas/horasProceso(recurso.proceso_id) |percent:'0.0-1'}}</span></div>
              <div class="pequenyo"><button *ngIf="!recurso.editar" (click)="recurso.editar=true" class="btn">Editar</button><button *ngIf="recurso.editar" (click)="guardarValor(recurso)" class="btn">Guardar</button></div>
            </div>

          </div>
          <button class="btn" (click)="addRecurso()">+ Añadir recurso</button>
        </div>  
      </div>
  
  
  
  
  
  
  
    </div>
  </div>
  