import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalHitosCliente',
  pure: false
})
export class TotalHitosClientePipe implements PipeTransform {
res;
  transform(value: Array<any>,mostrarPotenciales:boolean,mostrarPagado:boolean,mostrarNoPagado:boolean,mostrarHitoPago:boolean): number {
    this.res = 0;
    value.forEach(mes => {
      mes.forEach(factura => {
        this.calcularSuma(factura,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago);
        }
        );
    });
    return this.res;
  }
  calcularSuma(factura,mostrarPotenciales:boolean,mostrarPagado:boolean,mostrarNoPagado:boolean,mostrarHitoPago:boolean){
    if(((factura.proyecto.potencial && mostrarPotenciales) ||  !factura.proyecto.potencial) && ((!factura.potencial && factura.pagada && mostrarPagado) || (!factura.potencial && !factura.pagada && mostrarNoPagado) || (factura.potencial && mostrarHitoPago))){
      this.res+=factura.cantidad;
    }
  }
}
