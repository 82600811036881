import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'proyecto-linea',
  templateUrl: './proyecto-linea.component.html',
  styleUrls: ['./proyecto-linea.component.css']
})
export class ProyectoLineaComponent implements OnInit {

  @Input() proyecto: any;
  constructor() { }

  environment;
  ngOnInit(): void {
    this.environment = environment;
  }

}
