<div class="confirmarCerrarSesionGeneralContainer" >
    <div class="confirmarCerrarSesionConainer">
        <div class="bodyCerrarSesion">
          <span *ngIf="id==null">Nuevo <ng-container *ngIf="!potencial">Factura</ng-container><ng-container *ngIf="potencial">Hito de pago</ng-container></span>
          <span *ngIf="id!=null">Editar <ng-container *ngIf="!potencial">Factura</ng-container><ng-container *ngIf="potencial">Hito de pago</ng-container></span>
          <div class="campos">
          <div class="linea">
          <div class="campo">
            <label>Cliente</label> 
            <ng-select
            [disabled]="id!=null"
            [items]="clientes"
            placeholder="Selecciona el cliente"
            bindLabel="nombre"
            bindValue="id"
            [(ngModel)]="idCliente"
            (change)="cambioCliente($event)"
          >
          </ng-select>
          </div>
              <div class="campo">
            <label>Fecha emisión</label>
            <input required type="date" [(ngModel)]="fecha">
            </div> 
            </div>

            
          <div class="linea">
            <div class="campo">
              <label>Concepto</label> 
              <input required type="text" [(ngModel)]="concepto">
            </div>
                <div class="campo">
              <label>Cantidad <ng-container *ngIf="idProyecto != null">(Máx. {{presupuestoRestante|currency:'EUR'}})</ng-container></label>
                <ng-container *ngIf="idProyecto != null"><input required type="number" [(ngModel)]="cantidad" [class.error]="cantidad > presupuestoRestante" [max]="presupuestoRestante"></ng-container>
                <ng-container *ngIf="idProyecto == null"><input required type="number" [(ngModel)]="cantidad"></ng-container>
              </div> 
              </div>
          <div 
          *ngIf="proyectosMostrar.length > 0" class="linea">
            <div class="campo">
          <label>Proyecto</label> 
          <ng-select
          [disabled]="id!=null"
          [items]="proyectosMostrar"
          placeholder="Selecciona el proyecto"
          bindLabel="nombre"
          bindValue="id"
          [(ngModel)]="idProyecto"
          (change)="cambioProyecto($event)"
        >
        </ng-select>
        </div>
            </div>
          </div>
          <div class="bodyBotones">
            <button class="confirmar" (click)="crearFactura(true)" type="button">Guardar</button>
            <button (click)="crearFactura(false)" type="button">Cancelar</button>
            <button *ngIf="id!=null && mostrarBotonFactura" (click)="generarFactura()" type="button">Generar Factura</button>
            <button *ngIf="id!=null" (click)="eliminar()" class="eliminar" type="button">Eliminar</button>
            
          </div>
        </div>
    </div>
  </div>
  