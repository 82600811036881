import { Empresa } from "./empresa";
import { Proyectos } from "./proyectos";

export interface CRM {
  id: number;
  nombre: string;
  orden: number;
  empresa: Empresa;
  clienteEstadoCrms: Array<any>;
}
export class CRM implements CRM {
  static converObjectToFront(obj) {
    let crm = new CRM();
    crm.id = obj['id'];
    crm.nombre = obj['nombre'];
    crm.orden = obj['orden'];
    crm.empresa = obj['empresa'];
    crm.clienteEstadoCrms = obj['cliente_estado_crms'];
    return crm;
  }
}
