import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApexChart, ApexAxisChartSeries, ChartComponent, ApexDataLabels, ApexPlotOptions, ApexYAxis, ApexLegend, ApexGrid } from 'ng-apexcharts';
import { takeUntil } from 'rxjs';
import { PopupAddClienteComponent } from 'src/app/Components/popup-add-cliente/popup-add-cliente.component';
import { PopupConfirmacionBorradorComponent } from 'src/app/Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { ClientesService } from 'src/app/Services/clientes.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { LoginService } from 'src/app/Services/login.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupService } from 'src/app/Services/popup.service';
import { ProyectosService } from 'src/app/Services/proyectos.service';
import { UserService } from 'src/app/Services/user.service';

type ApexXAxis = {
  type?: 'category' | 'datetime' | 'numeric';
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
};

@Component({
  selector: 'app-Nuevo-Proyecto',
  templateUrl: './Nuevo-Proyecto.component.html',
  styleUrls: ['./Nuevo-Proyecto.component.css'],
})
export class NuevoProyectoComponent extends UnsubscribesDestroy implements OnInit {
  opcion: string = 'Descripción';
  opcionSubmenu: string = 'Corto Plazo';
  toggled: boolean = false;
  deshabilitado: boolean = true;
  clientes = [];
  usuarios = [];
  colores: string[] = [
    '#F9D8DA',
    '#F8D8E7',
    '#E6DAFC',
    '#D3E1FC',
    '#C3E5FC',
    '#B0EEEF',
    '#B7EEBE',
    '#E0E0E0',
    '#DEE1E5',
    '#EFF1D6',
    '#FFF7C2',
  ];
  vincular_clockify = true;
  proyecto_clockify = true;
  estadoCRM;
  auxiliarFase: string = '';
  abiertos: string[] = [];

  fases = [];

  etiquetas = [
    'Proceso',
    'Horas Determinadas',
    '% de Carga Proceso',
    'Presupuesto',
    'Empleados',
  ];

  nombreProyecto: string = '';
  abreviatura: string = '';
  id_clockify: string = '';
  colorSeleccionado: string = '';
  selectedCliente;
  selectedUsuario;
  sector: string = '';
  personaContacto: string = '';
  coste: number = 0;
  multiplicador: number = 0;
  fechaInicio: string = '';
  fechaFin: string = '';
  horasTot: number = null;

  @Output() estado = new EventEmitter<boolean>();
  @ViewChild('chart')
  chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions> | any;
  cambioHoras(horas, faseCambio) {
    faseCambio.horasTot = horas;
    this.horasTot = 0;

    this.fases.forEach((fase) => {
      this.horasTot += fase.horasTot;
    });
  }
  constructor(
    public popup: PopupService,
    private router: Router,
    protected activatedRoute : ActivatedRoute,
    private loginSE: LoginService,
    private clientesSE: ClientesService,
    private proyectosSE: ProyectosService,
    private loaderSE: LoaderService,
    private userSE: UserService,
    private notificacionesSE: NotificacionesService
  ) {
    super();
    this.chartOptions = {
      series: [
        {
          name: '% carga',
          data: [15, 12],
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      colors: ['#9FD2F4'],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
          borderRadius: 20,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: true,
      },
      yaxis: {
        tickAmount: 5,
        max: 100,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  }
  ngOnDestroy() {
    this._unsubscribeIndividual.next('');
  }
  empresaId;
  ngOnInit(): void {
    this.empresaId = localStorage.getItem('numEmpresa');
    if (
      localStorage.getItem('token') === null &&
      localStorage.getItem('login') != 'login'
    ) {
      this.loginSE.desconectar();
    }
    
  
    if(this.activatedRoute.snapshot.params['id'] != undefined){
      this.proyectosSE.getProyecto(this.activatedRoute.snapshot.params['id']);      
      this.loaderSE.open();
    this.proyectosSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((val) => {
        if (val == null) {
          return;
        }
        let value = val.proyecto;
        this.nombreProyecto = value.nombre;
        this.abreviatura = value.abreviatura;
        this.id_clockify = value.id_clockify;
        if(value.id_clockify != null){
          this.proyecto_clockify = true;
        }else{
          this.vincular_clockify = false;
        }
        this.colorSeleccionado = value.color;
        this.selectedCliente = value.cliente;
        this.selectedUsuario = value.usuario;
        this.sector = value.sector;
        this.personaContacto = value.personaContacto;
        this.coste = value.coste;
        this.multiplicador = value.multiplicador;
        this.fechaInicio = value.fechaInicio;
        this.fechaFin = value.fechaFin;
        this.horasTot = value.horasTot;
        this.fases = value.fases;
        this.colorSeleccionado = value.etiqueta;
        this.selectedCliente = value.cliente.id;
        this.personaContacto = value.contacto;
        this.coste = value.coste_dia;
        this.toggled = !value.potencial;
        this.selectedUsuario = value.responsable.id;
        this.fechaFin = this.convertirFechaParaInput(value.fecha_fin);
        this.fechaInicio = this.convertirFechaParaInput(value.fecha_ini);
        if(value.estado_crm != null){
          this.estadoCRM = value.estado_crm.id;
        }
        this.horasTot = 0;
        this.fases.forEach((fase) => {
          this.horasTot += fase.horas;
          fase.procesos.forEach((proceso) => {
            proceso.dias = proceso.horas / 8;
            proceso.fecha_ini = this.convertirFechaParaInput(proceso.fecha_ini);
            proceso.fecha_fin = this.convertirFechaParaInput(proceso.fecha_fin);
            let empleadosArray = [];
            proceso.asignaciones.forEach((asignacion) => {
              if(asignacion.empleado != null){
                empleadosArray.push(asignacion.empleado.id);
              }
            });
            proceso.empleados = empleadosArray;
          });
        });
        this.loaderSE.closeContador();

        this._unsubscribeIndividual.next('');
        this.proyectosSE.clearResult();
      });
    }else{
    this.horasTot = 0;
    }

    this.getAllClientesByEmpresa();
    this.getAllUsersByEmpresa();
    this.getEmpleadosOcupacion();
  } 
  convertirFechaParaInput(fecha) {
    let fechaConvertida = new Date(fecha);
    let dia:any = fechaConvertida.getDate();
    if(dia < 10){
      dia = '0' + dia;
    }
    let mes:any = fechaConvertida.getMonth() + 1;
    if(mes < 10){
      mes = '0' + mes;
    }
    let anio = fechaConvertida.getFullYear();
    let fechaFinal = anio + '-' + mes + '-' + dia;
    return fechaFinal;
  }
  calcularHora(fase){
    let sumatorio = 0;
    fase.procesos.forEach(proceso => {
      proceso.horas = proceso.dias *8;
      sumatorio += proceso.horas;
    });
    fase.horas = sumatorio;
    this.horasTot = 0;
    this.fases.forEach(fase => {
      this.horasTot += fase.horas;
    });
  }
addFase(){
  this.fases.push({factura:false,presupuesto:null,fecha_fin:null,fecha_ini:null,nombre:null,horas:0,procesos:[]});
}


addProceso(fase){
  fase.procesos.push({factura:false,presupuesto:null,fecha_fin:null,fecha_ini:null,nombre:null,dias:null,horas:null,empleados:[]});
}
  fasePadre = -1;


  //FIN POPUPS CONTROLLERS
  
  opciones = ['Descripción', 'Fases y procesos', 'Resumen'];

  avanzar() {
    let posicion = this.opciones.indexOf(this.opcion);
    this.irSeccion(this.opciones[posicion + 1]);
  }

  errorSeccion = false;
  
  irSeccion(event: string) {
    let correcto = true;
    switch (event) {
      case 'Descripción':
        break;
      case 'Fases y procesos':
        if (this.nombreProyecto == '' || this.nombreProyecto == null) {
          correcto = false;
        }
        if (this.abreviatura == '' || this.abreviatura == null) {
          correcto = false;
        }
        if (this.colorSeleccionado == '' || this.abreviatura == null) {
          correcto = false;
        }
        if (this.selectedCliente == null) {
          correcto = false;
        }
        if (this.selectedUsuario == null) {
          correcto = false;
        }
        if (this.sector == '' || this.sector == null) {
          correcto = false;
        }
        if (this.personaContacto == '' || this.personaContacto == null) {
          correcto = false;
        }
    
        if (this.coste < 0 || this.coste == null){
          correcto = false;
        }
        if (this.multiplicador < 0 || this.multiplicador == null){
          correcto = false;
        }
        if(!this.proyecto_clockify && (this.id_clockify == null || this.id_clockify == '')){
          correcto = false;
        }
        break;
      case 'Resumen': 
      let compararFechasAxuliar = this.compareDates(
        this.fechaInicio,
        this.fechaFin
      );
      if (!compararFechasAxuliar.valido) {
        correcto = false;
      }
        if(this.fases.length>0){
          if(this.fases[0].procesos.length>0){
            this.fases.forEach(fase => {
              if (fase.nombre == '' || fase.nombre == null) {
                correcto = false;
              }
              fase.procesos.forEach(proceso => {
                if (proceso.nombre == '' || proceso.nombre == null) {
                  correcto = false;
                }
                if (proceso.dias == null || proceso.dias < 0) {
                  correcto = false;
                }
                if(proceso.horas == null || proceso.horas < 0){
                  correcto = false;
                }
                if(proceso.fecha_ini == null){
                  correcto = false;
                }
                if(proceso.fecha_fin == null){
                  correcto = false;
                }
              });
            });
          }else{
            correcto = false;
          }
        }else{
          correcto = false;
        }
        if (this.fechaInicio == '' || this.fechaInicio == null) {
          correcto = false;
        }
        if (this.fechaFin == '' || this.fechaFin == null) {
          correcto = false;
        }
        break;
    }
    if(correcto){
      this.errorSeccion = false;
    this.opcion = event;
    }else{
      this.errorSeccion = true;
      this.notificacionesSE.showError("Error al cambiar de sección","Hay campos en esta sección sin rellenar correctamente");
    }
  }
  ocupacionCorto = [];
  ocupacionMedio = [];
  ocupacionLargo = [];
  opcionSubMenuSeleccionada(event: string) {
    this.opcionSubmenu = event;
    switch (this.opcionSubmenu) {
      case 'Corto Plazo':
        this.chartOptions.series[0].data = this.ocupacionCorto;
        break;
      case 'Medio Plazo':
        this.chartOptions.series[0].data = this.ocupacionMedio;
        break;
      case 'Largo Plazo':
        this.chartOptions.series[0].data = this.ocupacionLargo;
        break;
    }
    this.actualizarGraficoOcupaciones();
  }

  getColorPicker(color: string) {
    this.colorSeleccionado = color;
  }

  getAllClientesByEmpresa() {
    this.clientesSE.getAllClientesByEmpresa(this.empresaId);
    this.loaderSE.open();
    this.clientesSE
      .getResult()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.clientes = value;
        this._unsubscribe.next('');
        this.clientesSE.clearResult();
        this.loaderSE.closeContador();
      });
  }

  getEmpleadosOcupacion() {
    this.userSE.getAllUsersOcupacionByEmpresa(this.empresaId);
    this.loaderSE.open();
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual1))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.chartOptions.xaxis.categories = [];
        value.forEach((user) => {
          this.chartOptions.xaxis.categories.push(
            user.nombre + ' ' + user.apellidos
          );
          this.ocupacionCorto.push(user.porcentaje_corto * 100);
          this.ocupacionMedio.push(user.porcentaje_medio * 100);
          this.ocupacionLargo.push(user.porcentaje_largo * 100);
        });
        this.chartOptions.series[0].data = this.ocupacionCorto;
        // this.actualizarGraficoOcupaciones();
        this._unsubscribeIndividual1.next('');
        this.loaderSE.closeContador();
      });
  }
  actualizarGraficoOcupaciones() {
    this.chart.updateSeries(this.chartOptions.series);
  }
  getAllUsersByEmpresa() {
    this.userSE.getAllUsersByEmpresa();
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        value.forEach((element) => {
          if (!this.usuarios.includes(element)) {
            this.usuarios = [...this.usuarios, element];
          }
        });
        this._unsubscribeIndividual2.next('');
      });
  }


  updateProyecto(nuevoProyecto) {
    this.proyectosSE.editProyecto(this.activatedRoute.snapshot.params['id'],nuevoProyecto);
    this.loaderSE.open();
    this.proyectosSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.notificacionesSE.showFeedBack(value.mensaje);
        this.proyectosSE.clearResultUpdate();
        this._unsubscribeIndividual.next('');
        this.router.navigate(['/proyectos']);
      });
    this.proyectosSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.notificacionesSE.showError(value.mensaje);
        this.proyectosSE.clearResultUpdateError();
        this._unsubscribeIndividual.next('');
      });
  }

  createProyecto(nuevoProyecto) {
    this.proyectosSE.addProyecto(nuevoProyecto);
    this.loaderSE.open();
    this.proyectosSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.notificacionesSE.showFeedBack(value.mensaje);
        this._unsubscribeIndividual.next('');
        this.router.navigate(['/proyectos']);
      });
    this.proyectosSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.notificacionesSE.showError(value.mensaje);
        this._unsubscribeIndividual.next('');
      });
  }

  checkData() {
    let nuevoProyecto = {
      nombre: this.nombreProyecto,
      abreviatura: this.abreviatura,
      etiqueta: this.colorSeleccionado,
      contacto: this.personaContacto,
      coste_dia:this.coste,
      multiplicador:this.multiplicador,
      sector: this.sector,
      potencial: !this.toggled,
      responsable: this.selectedUsuario,
      cliente: this.selectedCliente,
      fechaIni: this.fechaInicio,
      fechaFin: this.fechaFin,
      empresa: localStorage.getItem('numEmpresa'),
      fases: this.fases,
      id_clockify:null,
      estadoCRM: this.estadoCRM
    };
    if(this.vincular_clockify){
      if(this.id_clockify != '' && this.id_clockify != undefined && this.id_clockify != null){
        nuevoProyecto.id_clockify = this.id_clockify;
        console.log("Coger id de clockify");
      }else{
        nuevoProyecto.id_clockify = "";
        console.log("crear nuevo proyecto en clockify");
      }
    }else{
      console.log("No vincular con clockify");
    }
    
    if(this.activatedRoute.snapshot.params['id'] == undefined){
        this.createProyecto(nuevoProyecto);
    }else{
      this.updateProyecto(nuevoProyecto);
    }
  }
  eliminarProceso(fase,index){
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Estás seguro de que quieres eliminar este proceso?',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue == true) {
          fase.procesos.splice(index,1);
          this.calcularHora(fase);
        }
        this.popup.clearData();
        this._unsubscribeIndividual.next('');
      });
  }
  eliminarFase(i){
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Estás seguro de que quieres eliminar esta fase?',
    });
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValue == true) {
        this.fases.splice(i,1);
        this.fases.forEach(fase => {
          this.horasTot += fase.horas;
        });
      }
      this.popup.clearData();
      this._unsubscribeIndividual.next('');
    });
}
  compareDates(inicio, fin) {
    let date1 = new Date(inicio);
    let date2 = new Date(fin);
    let fechaActual = new Date();
    if (date1.getTime() > date2.getTime()) {
      return {
        valido: false,
        mensaje: 'La fecha de inicio no puede ser mayor a la fecha de fin',
      };
    }else {
      return { valido: true, mensaje: '' };
    }
  }

  crearCliente(){
    this.popup.openPopup(PopupAddClienteComponent,{});
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual3))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.getAllClientesByEmpresa();
          this.selectedCliente = value.id;
        }
        this.popup.clearData();
        this._unsubscribeIndividual3.next('');
      }
      );
  }
}
