<div class="tesoreriaGeneralContainer">
  <div class="sideBarGeneralContainer">
    <sidebar [elementoActivo]="'tesoreria'"></sidebar>
  </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="Tesorería"></app-header-bar>
      </div>
      <div class="navegacionGeneralContainer">
        <menu-navegacion
        [icons]="['/assets/icons/listado.png','/assets/icons/leaderboard.png']"
          [opcionPorDefecto]="'Facturas'"
          [opciones]="['Facturas','Analítica']"
          (emitOpcionSeleccionada)="opcionSeleccionada($event)"
        ></menu-navegacion>
      </div>
      <div class="tesoreriaContainer">
        <ng-container *ngIf="opcion == 'Analítica'">
          <div class="tarjetaAnaliticaGeneralContainer">
            <ng-container *ngFor="let analitica of analiticas; let i = index">
              <tarjeta-info [cantidad]="analitica.valor" [texto]="analitica.titulo" [sobrecarga]="analitica.valor<0" [notificacion]="false" [money]="true"></tarjeta-info>
            </ng-container>
          </div>
          <div class="graficoGeneralContainer">
            <apx-chart
              #chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis"
              [yaxis]="chartOptions.yaxis"
              [colors]="chartOptions.colors"
              [plotOptions]="chartOptions.plotOptions"
              [dataLabels]="chartOptions.dataLabels"
              [tooltip]="chartOptions.tooltip"
            ></apx-chart>
          </div>
        </ng-container>
        <ng-container *ngIf="opcion == 'Facturas'">
    
            <div class="tarjetasInformacionTesoreriaContainer">
              <ng-container *ngFor="let datos of datosTesoreria">
                  <tarjeta-info [cantidad]="datos.cantidad" [texto]="datos.texto" [sobrecarga]="datos.cantidad<0" [notificacion]="false" [money]="true"></tarjeta-info>
              </ng-container>

            </div>
            <div class="tabla">
              <div class="cabecera">
                <div class="filtro" (click)="ordenar('numero')" [class.asc]="orden.clave=='numero' && orden.orden == 'asc'" [class.desc]="orden.clave=='numero' && orden.orden == 'desc'" >Nº</div>
                <div class="filtro" (click)="ordenar('cliente')" [class.asc]="orden.clave=='cliente' && orden.orden == 'asc'" [class.desc]="orden.clave=='cliente' && orden.orden == 'desc'" >Cliente</div>
                <div class="filtro" (click)="ordenar('proyecto')" [class.asc]="orden.clave=='proyecto' && orden.orden == 'asc'" [class.desc]="orden.clave=='proyecto' && orden.orden == 'desc'" >Proyecto</div>
                <div class="filtro" (click)="ordenar('concepto')" [class.asc]="orden.clave=='concepto' && orden.orden == 'asc'" [class.desc]="orden.clave=='concepto' && orden.orden == 'desc'" >Concepto</div>
                <div class="pequenyo filtro" (click)="ordenar('pagada')" [class.asc]="orden.clave=='pagada' && orden.orden == 'asc'" [class.desc]="orden.clave=='pagada' && orden.orden == 'desc'">Estado</div>
                <div class="pequenyo filtro" (click)="ordenar('fechaEmision')" [class.asc]="orden.clave=='fechaEmision' && orden.orden == 'asc'" [class.desc]="orden.clave=='fechaEmision' && orden.orden == 'desc'">Emisión</div>
                <div class="pequenyo filtro" (click)="ordenar('fechaPago')" [class.asc]="orden.clave=='fechaPago' && orden.orden == 'asc'" [class.desc]="orden.clave=='fechaPago' && orden.orden == 'desc'">Cobro estimado</div>
                <div class="pequenyo filtro" (click)="ordenar('fecha')" [class.asc]="orden.clave=='fecha' && orden.orden == 'asc'" [class.desc]="orden.clave=='fecha' && orden.orden == 'desc'">Fecha Pago</div>
                <div class="pequenyo">Base imponible</div>
                <div class="pequenyo ocultoMediano">IVA</div>
                <div class="pequenyo ocultoMediano">IRPF</div>
                <div class="pequenyo filtro" (click)="ordenar('cantidad')" [class.asc]="orden.clave=='cantidad' && orden.orden == 'asc'" [class.desc]="orden.clave=='cantidad' && orden.orden == 'desc'">Total</div>
                <div class="pequenyo">Acción</div>
              </div>
              <ng-container *ngFor="let factura of facturas">
                <div *ngIf="factura.cliente.nombre == buscador || buscador == null">
                  <div class="overflow"><span [matTooltip]="factura.numero" matTooltipClass="tooltip" matTooltipPosition="above">{{ factura.numero }}</span></div>
                  <div class="overflow"><span [matTooltip]="factura.cliente.nombre" matTooltipClass="tooltip" matTooltipPosition="above">{{ factura.cliente.nombre }}</span></div>
                  <ng-container *ngIf="factura.proyecto!=null"><div><span [matTooltip]="factura.proyecto.nombre" matTooltipClass="tooltip" matTooltipPosition="above">{{factura.proyecto.abreviatura}}</span></div></ng-container>
                  <ng-container *ngIf="factura.proyecto==null"><div>-</div></ng-container>
                  <div class="overflow"><span [matTooltip]="factura.concepto" matTooltipClass="tooltip" matTooltipPosition="above">{{ factura.concepto }}</span></div>
                  <div class="pequenyo" [class.pendiente]="!factura.pagada">
                    <ng-container *ngIf="factura.pagada">Pagada</ng-container><ng-container *ngIf="!factura.pagada">Pendiente</ng-container>
                  </div>
                  <div class="pequenyo">{{ factura.fecha_emision|date:'d/MM/yy' }}</div>
                  <div class="pequenyo">{{ factura.fecha|date:'d/MM/yy' }}</div>
                  <div class="pequenyo">{{ factura.fecha_pago|date:'d/MM/yy'}}</div>
                  <div class="pequenyo" [class.pendiente]="!factura.pagada">{{ factura.cantidad|currency:'EUR'}}</div>
                  <div class="pequenyo ocultoMediano" [class.pendiente]="!factura.pagada">{{ factura.cantidad * factura.iva|currency:'EUR'}}</div>
                  <div class="pequenyo ocultoMediano" [class.pendiente]="!factura.pagada">{{ factura.cantidad * factura.irpf|currency:'EUR'}}</div>
                  <div class="pequenyo" [class.pendiente]="!factura.pagada">{{ factura.cantidad * (1 + factura.iva - factura.irpf) |currency:'EUR'}}</div>
                  <div class="pequenyo">
                    <button (click)="pagar(factura)" *ngIf="!factura.pagada">Pagar</button>
                    <button class="pagado" *ngIf="false">Cancelar</button>
                  </div>
                </div>
              </ng-container>
            </div>          
            <mat-paginator *ngIf="cantidadPaginas>pageSize" [length]="cantidadPaginas"
            [pageIndex]="pagina"
            (page)="handlePageEvent($event)"
            [pageSize]=pageSize
            [pageSizeOptions]="[25 , 50 , 75 , 100]"
            aria-label="Select page">
            </mat-paginator>
        </ng-container>
      </div>
    </div>
</div>
