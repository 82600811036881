import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { PopupConfirmacionBorradorComponent } from 'src/app/Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { EmpresasService } from 'src/app/Services/empresas.service';
import { ImplicacionService } from 'src/app/Services/implicacion.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { LoginService } from 'src/app/Services/login.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupService } from 'src/app/Services/popup.service';
import { ProcesosService } from 'src/app/Services/procesos.service';
import { UserService } from 'src/app/Services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: ['./weekly.component.css']
})
export class WeeklyComponent
extends UnsubscribesDestroy
implements OnInit
{
constructor(
  public popup: PopupService,
  public router: Router,
  private implicacionSE: ImplicacionService,
  private procesosSE: ProcesosService,
  public userSE: UserService,
  public loaderSE: LoaderService,
  public empresaSE: EmpresasService,
  private notificacionesSE: NotificacionesService,
  public loginSE: LoginService
) {
  super();
}

porcentajeCortoPlazo = 43;
porcentajeMedioPlazo = 71;
porcentajeLargoPlazo = 98;

numeroSemana;
semanaActual;
anyo;
anyoActual;

cortoPlazoCarga = 0;

recalcularEmpleado(){
  this.cortoPlazoCarga = 0;
  let empleados = [];
  this.proyectosWeekly.forEach((proyecto) => {
    proyecto.horas = 0;
    proyecto.recursos.forEach((recurso) => {
      proyecto.horas+= recurso.horas;
      if(empleados[recurso.id] == null){
        empleados[recurso.id] = recurso.horas;
      }else{
        empleados[recurso.id] += recurso.horas;
      }
      this.cortoPlazoCarga += recurso.horas;
    });
  });
  this.weekly.emp.forEach((empleado) => {
    if(empleados[empleado.id] != null){
    empleado.carga = empleados[empleado.id];
    }else{
      empleado.carga = 0;
    }
  });
  this.recalcular();
}
deshacerCambios(){
  //eliminar todos los recursos añadidos sin guardar
  this.proyectoSeleccionado.recursos = this.proyectoSeleccionado.recursos.filter((recurso) => recurso.editarTotal == false);
  this.proyectoSeleccionado.recursos.forEach((recurso) => {
    recurso.horas = recurso.horasAnt;
  });
  this.recalcularEmpleado();
}

addRecurso(){
  this.proyectoSeleccionado.recursos.push({
    nombre: "",
    apellidos: "",
    horas:0,
    horasAnt: 0,
    proceso:null,
    subProceso:null,
    id:null,
    editar: true,
    editarTotal: true,
    proceso_id: null,
    subProceso_id: null
  });
}
seleccionarSubProceso(recurso){
  recurso.subProceso  = this.buscarPorId(recurso.subProceso_id,false).nombre;
}
seleccionarProceso(recurso,index){
  recurso.proceso  = this.buscarPorId(recurso.proceso_id,true).nombre;
  this.prepararSubProcesos(recurso.proceso_id,index);
}
buscarPorId(id, isProceso){
  let res = this.procesos.find((proceso) => (proceso.id == id && proceso.is_proceso == isProceso));
  return res;
}
seleccionarUsuario(recurso,index){
  recurso.proceso_id = null;
  let user = this.weekly.emp.find((usuario) => usuario.id == recurso.id);
  if(user != undefined){
    recurso.nombre = user.nombre;
    recurso.apellidos = user.apellidos;
    recurso.id = user.id;
    this.prepararProcesos(user,index);
  }else{
    recurso.nombre = null;
    recurso.apellidos = null;
    recurso.id = null;
    
  if(this.procesosSeleccion.length + 1 < index){
    this.procesosSeleccion.push([]);
    this.subProcesosSeleccion.push([]);
  }else{
    this.procesosSeleccion[index] = [];
    this.subProcesosSeleccion[index] = [];
  }
  }

}
empleados = [];


/*
addRecursoProyectoActivo(){
  this.proyectoSeleccionado.recursos.push({
    proceso_id: null,
    proyecto_id: this.proyectoSeleccionado.id,
    editar:true,
    editarTotal: true,
    empleado_id: null,
    horas: 0,
    horasAnt: 0,
    nombre: null,
    apellidos: null,
    nombreCompleto: null,
    color: null,
    colorTexto: null,
    colorTextoContraste: null,
    colorContraste: null,
    colorContrasteTexto: null,
  });
}*/
seleccionar(proyecto){
  if(proyecto == null){
    let mostrarPopUP = false;
    this.proyectoSeleccionado.recursos.forEach((recurso) => {
      if(recurso.horas != recurso.horasAnt){
        mostrarPopUP = true;
      }
      if(recurso.editarTotal == true){
        mostrarPopUP = true;
      }
    });
    if(mostrarPopUP){
      this.popup.openPopup(PopupConfirmacionBorradorComponent, {
        descripcion: 'Hay valores sin guardar, ¿desea continuar?',
      });    
      this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.deshacerCambios();
          this.proyectoSeleccionado = proyecto;
        }
      });
    }else{
      this.proyectoSeleccionado = proyecto;
    }
  }
  if(proyecto != null){
    this.proyectoSeleccionado = proyecto;
    this.obtenerEmpleados();
    this.prepararEmpleados();
    this.procesosSeleccion = [];
    this.subProcesosSeleccion = [];
    this.proyectoSeleccionado.recursos.forEach((recurso) => {
      recurso.horasAnt = recurso.horas;
      recurso.editar = false;
      recurso.editarTotal = false;      
      this.procesosSeleccion.push([]);
      this.subProcesosSeleccion.push([]);
    });
  }
}

procesosSeleccion = [];
subProcesosSeleccion = [];
prepararProcesos(user,index){
  let procesosSeleccion = [];
  this.procesos.forEach((proceso) => {
    if(proceso.proyecto.id == this.proyectoSeleccionado.id){
      let guardar = false;
      proceso.asignaciones.forEach((asignacion) => {
        if(asignacion.empleado.id == user.id){
          guardar = true;
        }else{
          user.departamentos.forEach((departamento) => {
            if(departamento.id == asignacion.perfil.id){
              guardar = true;
            }
          });
        }
        
        
      });
      if(guardar){
        if(!proceso.is_proceso){
          let procesoPadre = this.buscarPorId(proceso.padre,true);
          procesosSeleccion.push({
            id: procesoPadre.id,
            nombre: procesoPadre.nombre
          });
        }else{
        procesosSeleccion.push({
          id: proceso.id,
          nombre: proceso.nombre
        });
      }
      }
    }
  });
  //eliminar los duplicados
  procesosSeleccion = procesosSeleccion.filter((proceso, index, self) =>
  index === self.findIndex((t) => (
    t.id === proceso.id
  ))
)

  if(this.procesosSeleccion.length + 1 < index){    
    this.procesosSeleccion.push(procesosSeleccion);
  }else{
    this.procesosSeleccion[index] = procesosSeleccion;
  }
}

prepararSubProcesos(proceso_id,index){
  let subProcesosSeleccion = [];
  this.procesos.forEach((proceso) => {
    if(proceso.padre == proceso_id){
      subProcesosSeleccion.push({
        id: proceso.id,
        nombre: proceso.nombre
      });
    }
  });
  if(this.subProcesosSeleccion.length + 1 < index){
    this.subProcesosSeleccion.push(subProcesosSeleccion);
  }else{
    this.subProcesosSeleccion[index] = subProcesosSeleccion;
  }

}
prepararEmpleados(){
  this.empleados = [];
  this.weekly.emp.forEach((empleado) => {
    this.proyectoSeleccionado.empleados.forEach((empleadoProyecto) => {
      if(empleado.id == empleadoProyecto){
        this.empleados.push({
          id: empleado.id,
          nombre: empleado.nombre + ' ' +empleado.apellidos
        });
      }
    });
  });
  console.log(this.empleados);
}
guardarValor(recurso){
  recurso.horasAnt = recurso.horas;
  recurso.editar=false;
  recurso.editarTotal = false;
  this.guardarInfo();
}

empleadosProyecto = [];
obtenerEmpleados(){
  this.empleadosProyecto = [];
  this.weekly.emp.forEach(element => {
    this.proyectoSeleccionado.empleados.forEach(empleado => {
    if(element.id == empleado){
      this.empleadosProyecto.push(element);
    }
    });
  });
  
}
obtenerNumeroSemana(fecha:Date){
  let startDate = new Date(fecha.getFullYear(), 0, 1);
  var days = Math.floor((fecha.getTime() - startDate.getTime()) /
      (24 * 60 * 60 * 1000));
       
  return Math.ceil(days / 7);
}
getDateOfWeek(w, y) {
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return this.formatDate(ISOweekStart);
}
horasEmpresa = 0;
horas = [];
colores = [];
procesos = [];
proyectosWeekly = [{
id:null,
nombre:"Proyecto 1",
costePlanificado: 100,
costeReal: 50,
abreviatura: "P1",
color: "#826DD8",
avanceReal: 30,
avancePlanificado: 60,
horas: 40,
procesos:[],
recursos:[{
  activo:true,
  nombre: "Juan",
  apellidos: "Perez",
  horas:40,
  proceso:"04.Desarrollo Back-end",
  max:null,
  carga:0,
  id:null,
  proceso_id:null,
  subProceso_id:null
}]
}];
horasTotales = 0;
recalcular(){
this.horas = [];
this.colores = [];
this.horasTotales = 0;
this.proyectosWeekly.forEach((proyecto) => {
  if(proyecto.horas > 0){
    this.horasTotales += proyecto.horas;
    this.horas.push(proyecto.horas);
    this.colores.push(proyecto.color);
  }
});
}
lunesSemana;
proyectoSeleccionado = null;
cambioSemana(semana){
  if(semana< 1){
    this.anyo--;
    this.numeroSemana = 52;
  }else {
      if(semana > 52){
      this.anyo++;
      this.numeroSemana = 1;
    }else{
      this.numeroSemana = semana;
    }
  }
  this.lunesSemana = this.getDateOfWeek(this.numeroSemana, this.anyo);
  this.actualizacionesCambioSemana();
}
cambiarSemanaActual(){
  this.numeroSemana = this.semanaActual;
  this.anyo = this.anyoActual;
  this.lunesSemana = this.getDateOfWeek(this.numeroSemana, this.anyo);
  this.actualizacionesCambioSemana();
}
validar(){
  this.loaderSE.open();
  this.implicacionSE.validarWeekly(this.lunesSemana);
  this.implicacionSE.getResultIndividual()
  .pipe(takeUntil(this._unsubscribeIndividual))
  .subscribe((value) => {
    if (value == null) {
      return;
    }console.log(value);
    this.loaderSE.closeContador();
    this._unsubscribeIndividual.next('');
  });
  
}
actualizacionesCambioSemana(optimizar = false){
  this.loaderSE.open();
  this.implicacionSE.getWeekly(this.lunesSemana,optimizar);
  this.implicacionSE.getResultIndividual()
  .pipe(takeUntil(this._unsubscribeIndividual))
  .subscribe((value) => {
    if (value == null) {
      return;
    }
    this.weekly = value;
    this.procesos = value.pro;
    this.obtenerCargaTotalEmpresa();
    this.proyectosWeekly = value.proyectos;
    this.recalcularEmpleado();
    this.loaderSE.closeContador();
    this._unsubscribeIndividual.next('');
  });
}

obtenerCargaTotalEmpresa(){
  this.horasEmpresa = 0;
  this.weekly.emp.forEach((empleado) => {
    this.horasEmpresa += empleado.horas;
  });
}
environment;
ngOnInit() {
  
  this.environment = environment;
  //obtener el numero de semana
  this.anyoActual = new Date().getFullYear();
  this.anyo = this.anyoActual;
  this.numeroSemana = this.obtenerNumeroSemana(new Date());
  this.semanaActual = this.numeroSemana;
  this.lunesSemana = this.getDateOfWeek(this.numeroSemana, this.anyo);
  if (
    localStorage.getItem('token') === null &&
    localStorage.getItem('login') != 'login'
  ) {
    this.loginSE.desconectar();
  }
  this.admin = this.loginSE.isIntermedio();
  this.idUser = Number(localStorage.getItem('idUser'));
  this.actualizacionesCambioSemana();
  localStorage.setItem('lastRoute', this.router.url);
  
  
}
admin = false;  
weekly = null;
idUser;
padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}
formatDate(date: Date) {
  return [
    date.getFullYear(),
    this.padTo2Digits(date.getMonth() + 1),
    this.padTo2Digits(date.getDate()),
  ].join('-');
}
generar(){    
  this.loaderSE.open();
  this.implicacionSE.getWeekly(this.lunesSemana,true);
  this.implicacionSE.getResultIndividual()
  .pipe(takeUntil(this._unsubscribeIndividual))
  .subscribe((value) => {
    if (value == null) {
      return;
    }
    this.weekly = value;
    this.procesos = value.pro;
    this.obtenerCargaTotalEmpresa();
    this.proyectosWeekly = value.proyectos;
    this.recalcularEmpleado();
    this.loaderSE.closeContador();
    this._unsubscribeIndividual.next('');
  });
}

horasProceso(id):number{
  let res = 0;
  this.weekly.pro.forEach(proceso => {
    if(proceso.id == id){
      res = proceso.horas;
    }
  });
  return res;
}
guardarInfo(){
  let res = [];
  this.proyectosWeekly.forEach((proyecto) => {
    proyecto.recursos.forEach((recurso) => {
      if(recurso.horas > 0){
        res.push({
          idProceso: recurso.proceso_id,
          horas: recurso.horas,
          idUsuario: recurso.id,
          idProyecto: proyecto.id,
          idSubProceso: recurso.subProceso_id
        });
      }
    });
  });
  let data = {
    fecha: this.lunesSemana,
    implicaciones: res
  };
  console.log(data);
  this.loaderSE.open();
  this.implicacionSE.addImplicacion(data);
  this.implicacionSE.getResultUpdate()
  .pipe(takeUntil(this._unsubscribe))
  .subscribe((value) => {
    if (value == null) {
      return;
    }
    this.notificacionesSE.showFeedBack("Implicaciones guardadas correctamente");
    this.loaderSE.closeContador();
    this._unsubscribe.next('');
  });
}

}
