import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/Services/login.service';
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexFill, ApexTooltip, ApexXAxis, ApexLegend, ApexDataLabels, ApexTitleSubtitle, ApexYAxis} from 'ng-apexcharts';
import { PopupService } from 'src/app/Services/popup.service';
import { Router } from '@angular/router';
import { ProyectosService } from 'src/app/Services/proyectos.service';
import { takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/Services/loader.service';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { ImplicacionService } from 'src/app/Services/implicacion.service';

@Component({
  selector: 'app-presupuestos',
  templateUrl: './presupuestos.component.html',
  styleUrls: ['./presupuestos.component.css']
})
export class PresupuestosComponent extends UnsubscribesDestroy implements OnInit {
  filtro: string = '';
  toggled: boolean = false;
  potencialesTimeline: boolean = true;
  selectedPlazoTimeline: any = 'Corto plazo';
  plazos = ['Corto plazo', 'Medio plazo', 'Largo plazo'];
  selectedDepartamento: any = 'Desarrollo';
  departamentos = ['Desarrollo', 'Diseño', 'Administración'];

  proyectoSeleccionado: any;

  @ViewChild('switchLabel') switchLabel!: ElementRef;
  @ViewChild('switchLabel') switchLabel2!: ElementRef;

  @ViewChild('chart')
  chart!: ChartComponent;

  @ViewChild('dottedchart') dottedchart!: ChartComponent;
  @ViewChild('chart2') chart2!: ChartComponent;

  managers: any[] = [];
  managersProyecto = [];
  proyectos = [];

  constructor(
    public popup: PopupService,
    public router: Router,
    private loginSE: LoginService,
    private proyectosSE: ProyectosService,
    private loaderSE: LoaderService,
    private implicacionSE: ImplicacionService
  ) {
    super();

  }
  opcion = 'Proyectos';
  opcionSeleccionada(event: string) {
    this.opcion = event;
  }
  ngOnDestroy() {
    this._unsubscribeIndividual.next('');
  }
  ngOnInit(): void {
    localStorage.setItem('lastRoute', this.router.url);
    if (
      localStorage.getItem('token') === null &&
      localStorage.getItem('login') != 'login'
    ) {
      this.loginSE.desconectar();
    } else {
      this.getProyectosEmpresa();
      this.getInfoGraficoCarga();
    }
  }

  getInfoGraficoCarga() {
    this.loaderSE.open();
    this.implicacionSE.getImplicacionSemana();
    this.implicacionSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }

        this.cargasSemana = value.emp;
        this.horasSemana = value.horas;
        this.proyectosSemana = value.imp;
        this.proyectosResumen = value.proyectos;
        this.getManagers();
        this._unsubscribeIndividual2.next('');
        this.loaderSE.closeContador();
      });
  }
  cargasSemana;
  horasSemana;
  proyectosSemana;
  proyectosResumen;

  isToggled() {
    this.toggled = this.switchLabel.nativeElement.checked;
  }
  borrarTexto() {
    this.filtro = '';
  }

  abrirPopupAddPresupuesto() {
    this.router.navigate(['/presupuestos/nuevo-presupuesto']);
  }

  filtroManager(manager: any) {
    if (!this.managers.includes(manager)) {
      this.managers.push(manager);
    } else {
      this.managers.splice(this.managers.indexOf(manager), 1);
    }
  }

  
  public generateData(count: any, yrange: any) {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = (i + 1).toString();
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y,
      });
      i++;
    }
    return series;
  }

  getProyectosEmpresa() {
    this.proyectosSE.getAllProyectos(5000, 1, null, true);
    this.loaderSE.open();
    this.proyectosSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        value.forEach((element) => {
          if (!this.proyectos.includes(element)) {
            this.proyectos.push(element);
          }
        });
        this.getManagers();
        this._unsubscribeIndividual.next('');
        this.loaderSE.closeContador();
      });
  }

  getManagers() {
    let auxiliarIDS = [];
    this.proyectos.forEach((proyecto) => {
      if (!auxiliarIDS.includes(proyecto.responsable.id)) {
        auxiliarIDS.push(proyecto.responsable.id);
        if (!this.managersProyecto.includes(proyecto.responsable)) {
          this.managersProyecto.push(proyecto.responsable);
        }
      }
    });
  }

  arrayToData(array) {
    let data = [];
    let cont = 1;
    array.forEach((element) => {
      data.push({
        x: 'Semana ' + cont,
        y: element,
      });
      cont++;
    });
    return data;
  }
}
