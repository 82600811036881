<div class="confirmarGuardarBorradorGeneralContainer">
    <div class="confirmarGuardarBorradorConainer">
      <div class="bodyGuardarBorrador">
        <span>Generar Factura</span>
        <div class="campos">
          <div class="linea">
            <div class="campo">
              <label>Fecha de emisión</label> 
              <input type="date" [(ngModel)]="fecha">
            </div>
            <div class="campo">
              <label>Nº Factura</label> 
              <input [(ngModel)]="numero">
            </div>
          </div>
          <div class="linea">
            <div class="campo">
              <label>% IVA</label>
              <input [(ngModel)]="iva">
            </div>
            <div class="campo">
              <label>% IRPF</label>
              <input [(ngModel)]="irpf">
            </div>
          </div>
        <div class="bodyBotones">
          <button class="confirmar" (click)="generarFactura()" type="button">
            Generar
          </button>
          <button (click)="confirmarGuardarBorrador(false)" type="button">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
  