<div [style.width]="width">
  <svg viewBox="0 0 200 200" [style.width]="width">
  <path *ngIf="mostrarFondo" stroke-width="6" fill="none" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" [attr.stroke-width]=grosor style="z-index: 0;stroke: #c7c7c7;"></path>

  <ng-container *ngIf="individual != undefined">    
    <path [attr.stroke-dashoffset]=calculoIndividual(individual) [style.transform]="'rotate(calc(90deg)) scaleY(-1)'"  [attr.stroke]="colores[0]" [attr.stroke-width]=grosor fill="none" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" stroke-dashoffset="472px"></path>
    </ng-container>
    <ng-container *ngIf="individual == undefined">
    <path *ngFor="let val of valores; let i = index" [attr.stroke-dashoffset]=calculo(val) [style.transform]="rotacion(i)"  [attr.stroke]="colores[i]" [attr.stroke-width]=grosor fill="none" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" stroke-dashoffset="472px"></path>
    </ng-container>
  </svg>
    <span [style.font-size]="fontSize" *ngIf="mostrarTotal">{{ total()|number:'0.0-2'}}{{unidad}}</span>
</div>