<div class="tesoreriaGeneralContainer">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'hitos'"></sidebar>
    </div>
    <ng-container>
      <div class="dataGeneralContainer">
        <div class="dataHeaderContainer">
          <app-header-bar titulo="Hitos de pago" (accionBoton)="abrirPopupHitosPago()" [boton]="true"></app-header-bar>
        </div>
        <div class="filtros">
          <label><input type="checkbox" [(ngModel)]="mostrarPotenciales"> Mostrar proyectos potenciales</label>
          <label><input type="checkbox" [(ngModel)]="mostrarPagado"> Mostrar facturas pagadas</label>
          <label><input type="checkbox" [(ngModel)]="mostrarNoPagado"> Mostrar facturas no pagadas</label>
          <label><input type="checkbox" [(ngModel)]="mostrarHitoPago"> Mostrar hitos de pago</label>
        </div>
        <div class="cuerpo">
          <div class="tabla">
            <div class="cabecera">
              <div>Cliente</div>
              <div>Enero</div>
              <div>Febrero</div>
              <div>Marzo</div>
              <div>Abril</div>
              <div>Mayo</div>
              <div>Junio</div>
              <div>Julio</div>
              <div>Agosto</div>
              <div>Septiembre</div>
              <div>Octubre</div>
              <div>Noviembre</div>
              <div>Diciembre</div>
              <div>Total</div>
            </div>
            <ng-container *ngFor="let cliente of hitosProyecto | keyvalue">
              <div>
                <div><span class="overflow"><span [matTooltip]="cliente.key" matTooltipClass="tooltip" matTooltipPosition="above">{{cliente.key}}</span></span></div>
                <div *ngFor="let mes of cliente.value; let i = index" (click)="abrirPopupHitosPago(cliente.key,i+1)">
                    <ng-container *ngFor="let factura of mes">
                      <span *ngIf="((factura.proyecto.potencial && mostrarPotenciales) ||  !factura.proyecto.potencial) && ((!factura.potencial && factura.pagada && mostrarPagado) || (!factura.potencial && !factura.pagada && mostrarNoPagado) || (factura.potencial && mostrarHitoPago))" [class.potencial]="factura.potencial" [class.pagada]="factura.pagada" [class.pendiente]="!factura.pagada" class="pago" (click)="accionHito($event,factura)">
                    {{factura.cantidad|currency:'EUR': 'symbol' : '1.2-2'}}</span>
                    </ng-container>
                </div>
                <div class="totalCliente">{{cliente.value|totalHitosCliente:mostrarPotenciales:mostrarPagado:mostrarNoPagado:mostrarHitoPago|currency:'EUR': 'symbol' : '1.2-2'}}</div>
              </div>
            </ng-container>
            <div class="totalHitos">
              <div>Total</div>
              <div *ngFor="let mes of costeEmpresa;let i = index">
                <span>{{calcularValorMostrar(hitosProyecto,i,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago)|currency:'EUR': 'symbol' : '1.2-2'}}</span>
              </div>
              <div>
                <span>{{calcularValorMostrar(hitosProyecto,-1,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago)|currency:'EUR': 'symbol' : '1.2-2'}}</span>
              </div>
            </div>
            <div class="costeEmpresa">
              <div><span class="overflow"><span matTooltip="Gasto Empresa" matTooltipClass="tooltip" matTooltipPosition="above">Circulante</span></span></div>
              <div *ngFor="let mes of costeEmpresa"><span>{{mes|currency:'EUR': 'symbol' : '1.2-2'}}</span></div>
              <div><span>{{costeEmpresaTot|currency:'EUR': 'symbol' : '1.2-2'}}</span></div>
            </div>
            <div class="neto">
              <div>Neto</div>
              <div *ngFor="let mes of costeEmpresa;let i = index">
                <span [class.positivo]="calcularValorMostrar(hitosProyecto,i,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago,mes) >= 0" [class.negativo]="calcularValorMostrar(hitosProyecto,i,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago,mes) < 0">{{calcularValorMostrar(hitosProyecto,i,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago,mes)|currency:'EUR': 'symbol' : '1.2-2'}}</span>
              </div>
              <div>
                <span [class.positivo]="calcularValorMostrar(hitosProyecto,-1,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago,costeEmpresaTot) >= 0" [class.negativo]="calcularValorMostrar(hitosProyecto,-1,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago,costeEmpresaTot) < 0">{{calcularValorMostrar(hitosProyecto,-1,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago,costeEmpresaTot)|currency:'EUR': 'symbol' : '1.2-2'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  