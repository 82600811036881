import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { User } from '../Interfaces/user';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  getMyUserEmpresa() {    
    this.http.get(this.urlApi + 'api/user/me', this.getHeader()).subscribe({
      next: (data:any) => {
        let user = new User();
        user = data;
        if(user.id_clockify != null){
          localStorage.setItem("id_clockify", user.id_clockify);
          localStorage.setItem("user_clockify", user.user_clockify);
        }
        localStorage.setItem('idUser',user.id.toString());
        localStorage.setItem('nombre',user.nombre);
        user.empresas = data['empresas'][0];
        this.sendNextResult("result",user.empresas);
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getMyUserEmpresa, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getMyUser() {    
    this.http.get(this.urlApi + 'api/user/me', this.getHeader()).subscribe({
      next: (data: any) => {
        let user = new User();
        user = data;
        if(user.id_clockify != null){
          localStorage.setItem("id_clockify", user.id_clockify);
          localStorage.setItem("user_clockify", user.user_clockify);
        }
        console.log(user.empresas[0]);
        if(user.empresas[0].id_clockify != null){
          localStorage.setItem("id_clockify_empresa", user.empresas[0].id_clockify);
        }
        localStorage.setItem("idUser", user.id.toString());
        this.sendNextResult("result",user);
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getMyUser, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getUserEmpresaById(id?) {    
    this.http.get(this.urlApi + 'api/user/' + id, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult("result",data);
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.getUserEmpresaById, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  getAllUsersByEmpresa(all = true) {
    let url = all ? 'api/user?all' : 'api/user';
    this.http
      .get(this.urlApi + url, this.getHeader())
      .subscribe({
        next: (data) => {
          let ususarios = [];
          ususarios.push(data);
          ususarios.forEach((element) => {
            let usuario: User = new User();
            usuario = element;
            this.sendNextResult("result",usuario);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getAllUsersByEmpresa, args: [all] });
          this.checkStatusError(error);
        },
      });
  }

  getAllUsersOcupacionByEmpresa(id?) {    
    this.http
      .get(this.urlApi + 'api/user/ocupacion?empresa=' + id, this.getHeader())
      .subscribe({
        next: (data) => {
          let ususarios = [];
          ususarios.push(data);
          ususarios.forEach((element) => {
            let usuario: User = new User();
            usuario = element;
            this.sendNextResult("result",usuario);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getAllUsersOcupacionByEmpresa, args: [id] });
          this.checkStatusError(error);
        },
      });
  }

  createUser(data) {    
    this.http.post(this.urlApi + 'api/user', data, this.getHeader()).subscribe({
      next: (data) => {
        this.sendNextResult("resultUpdate",data);
      },
      error: (error) => {
        this.sendNextResult('resultError', error, { method: this.createUser, args: [data] });
        this.checkStatusError(error);
      },
    });
  }

  editUserMe(data) {    
    this.http
      .put(this.urlApi + 'api/userMe', data, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultUpdate",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.editUserMe, args: [data] });
          this.checkStatusError(error);
        },
      });
  }
  editUser(id,data) {    
    this.http
      .put(this.urlApi + 'api/user/'+id, data, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultUpdate",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.editUser, args: [id,data] });
          this.checkStatusError(error);
        },
      });
  }
}
