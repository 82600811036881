import { Proceso } from './proceso';
import { Proyectos } from './proyectos';

export interface Fase {
  id?: number;
  nombre: string;
  fecha_ini: string;
  fecha_fin: string;
  horas: number;
  factura: boolean;
  pago_fin:boolean;
  presupuesto: number;
  proyecto: Proyectos;
  procesos: Array<Proceso>;
}
export class Fase implements Fase {
  static converObjectToFrontFull(obj) {
    let fase = new Fase();
    fase.id = obj['id'];
    fase.nombre = obj['nombre'];
    fase.fecha_ini = obj['fecha_ini'];
    fase.fecha_fin = obj['fecha_fin'];
    fase.horas = obj['horas'];
    fase.factura = obj['factura'];
    fase.presupuesto = obj['presupuesto'];
    if (undefined !== obj['proyecto']) {
      fase.proyecto = Proyectos.converObjectToFront(obj['proyecto']);
    }
    fase.procesos = obj['procesos'];

    return fase;
  }
}
