import { Component, OnInit } from '@angular/core';
import {KeyValue} from '@angular/common';

import { takeUntil } from 'rxjs';
import { PopUpFacturaComponent } from 'src/app/Components/pop-up-factura/pop-up-factura.component';
import { PopupFacturaComponent } from 'src/app/Components/popup-factura/popup-factura.component';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { FinanzasService } from 'src/app/Services/finanzas.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { PopupService } from 'src/app/Services/popup.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { CosteEmpresaService } from 'src/app/Services/coste-empresa.service';


@Component({
  selector: 'app-hitos',
  templateUrl: './hitos.component.html',
  styleUrls: ['./hitos.component.css']
})
export class HitosComponent extends UnsubscribesDestroy implements OnInit {

  private onCompare(_left: KeyValue<any, any>, _right: KeyValue<any, any>): number {
    return -1;
  }
  year;
  hitosProyecto = [];
  costeEmpresa = [0,0,0,0,0,0,0,0,0,0,0,0];
  costeEmpresaTot = 0;

  constructor(
    public popup: PopupService,
    protected finanzasSE: FinanzasService,
    public loaderSE: LoaderService,  
    private notificacionesSE: NotificacionesService,
    private costesEmpresaSE: CosteEmpresaService
  ) {
    super();
  }

  datosTesoreria = [];
  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.calcularHitosDePago();   
    this.costesDeEmpresa(); 
  }
  mostrarPotenciales = true;
  mostrarPagado = true;
  mostrarNoPagado = true;
  mostrarHitoPago = true;
  calcularHitosDePago(){
    this.loaderSE.open();
    this.finanzasSE.getFacturas(localStorage.getItem('numEmpresa'));
    this.finanzasSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubscribeIndividual2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.hitosProyecto = value;  
        this.loaderSE.closeContador();     
        this._unsubscribeIndividual2.next('');
        this.finanzasSE.clearResult();
      });
  }

  costesDeEmpresa(){
    this.loaderSE.open();
    this.costesEmpresaSE.getCosteEmpresaByYear(this.year);
    this.costesEmpresaSE.getResult().pipe(takeUntil(this._unsubscribeIndividual1)).subscribe((value) => {
      if (value == null) {
        return;
      }      
      this.costeEmpresa = this.costesEmpresaSE.convertirObjetoMesesEnArray(value);
      this.loaderSE.closeContador();
      this._unsubscribeIndividual1.next('');
      this.costesEmpresaSE.clearResult();
      this.costeEmpresaTot = this.costeEmpresa.reduce((a, b) => a + b, 0); 
    });


    }

  abrirPopupHitosPago(cliente = null, mes = null) {
    let data = {cliente:cliente, mes:mes,potencial:true};
    this.popup.openPopup(PopUpFacturaComponent,data);    
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValueFactura) {
        this.popup.clearData();
        this.calcularHitosDePago();
        this._unsubscribeIndividual.next('');
      }
    });
  }

  pagar(factura){
    this.popup.openPopup(PopupFacturaComponent,{id:factura.id});
    this.popup.returnData().pipe(takeUntil(this._unsubscribeIndividual)).subscribe((value) => {
      if (value == null) {
        return;
      }
      console.log(value.returnValue.returnValue);
      if (value.returnValue.returnValue == true) {
        this.popup.clearData();
        this.notificacionesSE.showFeedBack('Factura pagada correctamente');
        this.calcularHitosDePago();
        this._unsubscribeIndividual.next('');
      }
    });
  }

  accionHito(event,factura){
    if(factura.potencial){
      this.editarHito(factura);
    }else{
      console.log(factura);
      if(!factura.pagada){
        this.pagar(factura);
      }else{
        event.stopPropagation();
      }
    }
  }

  editarHito(factura){
    this.popup.openPopup(PopUpFacturaComponent,{potencial:true, id:factura.id, cantidad:factura.cantidad, fecha:factura.fecha, concepto:factura.concepto, idCliente:factura.cliente.id, idProyecto:factura.proyecto.id});    
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValueFactura) {
        this.popup.clearData();
        this.notificacionesSE.showFeedBack('Hito de pago editado correctamente');
        this.calcularHitosDePago();
        this._unsubscribeIndividual.next('');
      }
    });
  }

  calcularValorMostrar(items: any[],mes:number,mostrarPotenciales:boolean,mostrarPagado:boolean,mostrarNoPagado:boolean,mostrarHitoPago:boolean,resta=0): number {
    let res = -resta;
    let clientesNombres = Object.keys(items);
    clientesNombres.forEach(nombre => {
      if(mes != -1){
        items[nombre][mes].forEach(factura => {
          res+=this.calcularSuma(factura,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago);
        });
      }else{
        items[nombre].forEach(mes => {
          mes.forEach(factura => {
            res+=this.calcularSuma(factura,mostrarPotenciales,mostrarPagado,mostrarNoPagado,mostrarHitoPago);
          });
        });
      }
    });
    return res;
  }
  calcularSuma(factura,mostrarPotenciales:boolean,mostrarPagado:boolean,mostrarNoPagado:boolean,mostrarHitoPago:boolean){
    if(factura.proyecto == null){
      factura.proyecto = {potencial:false};
    }
    if(((factura.proyecto.potencial && mostrarPotenciales) ||  !factura.proyecto.potencial) && ((!factura.potencial && factura.pagada && mostrarPagado) || (!factura.potencial && !factura.pagada && mostrarNoPagado) || (factura.potencial && mostrarHitoPago))){
      return factura.cantidad;
    }
    return 0;
  }
}
