import { Directive, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
@Directive()
export class UnsubscribesDestroy implements OnDestroy{
    _unsubscribe=new Subject<any>();
    _unsubscribeIndividual=new Subject<any>();
    _unsubscribeIndividual1=new Subject<any>();
    _unsubscribeIndividual2=new Subject<any>();
    _unsubscribeIndividual3=new Subject<any>();
    _unsubscribeIndividual4=new Subject<any>();
    ngOnDestroy(): void {
        this._unsubscribe.next(" ");
        this._unsubscribe.complete();
        this._unsubscribeIndividual.next(" ");
        this._unsubscribeIndividual.complete();
        this._unsubscribeIndividual1.next(" ");
        this._unsubscribeIndividual1.complete();
        this._unsubscribeIndividual2.next(" ");
        this._unsubscribeIndividual2.complete();
        this._unsubscribeIndividual3.next(" ");
        this._unsubscribeIndividual3.complete();
        this._unsubscribeIndividual4.next(" ");
        this._unsubscribeIndividual4.complete();
    }
}
