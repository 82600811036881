import {Component,Inject,OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { DailyService } from 'src/app/Services/daily.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { ContratoService } from 'src/app/Services/Api/contrato.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';

@Component({
  selector: 'app-pop-up-contrato',
  templateUrl: './pop-up-contrato.component.html',
  styleUrls: ['./pop-up-contrato.component.css']
})
export class PopUpContratoComponent extends UnsubscribesDestroy implements OnInit {

  constructor(
    private dailySE: DailyService,
    public loaderSE: LoaderService,
    public dialogRef: MatDialogRef<PopUpContratoComponent>,
    private contratoSE: ContratoService,
    private notificacionesSE: NotificacionesService,

    @Inject(MAT_DIALOG_DATA) public modalData: any) {
      super();
     }
     contrato = {
      inicio: null,
      fin: null,
      horas: null,
      costeHora: null,
      empleado: null
     }
  ngOnInit(): void {
    this.nombre = this.modalData.nombre;
    this.contrato.empleado = this.modalData.empleado;

  }
  nombre = null;
  closePopup(returnValue?: any, contrato?: any) {
    let p = { returnValue: returnValue, contrato: contrato };
    this.dialogRef.close(p);
  }

  crearContrato(){
    this.loaderSE.open();
    this.contratoSE.crearContrato(this.contrato);
    this.contratoSE.getResult().pipe(takeUntil(this._unsubscribe)).subscribe((value) => {
      if (value == null) {
        return;
      }
      console.log(value);
      this.notificacionesSE.showFeedBack(value.message);
      this._unsubscribe.next('');
      this.contratoSE.clearResult();
      this.loaderSE.closeContador();
      this.closePopup(true, this.contrato);
    });
    this.contratoSE.getResultError().pipe(takeUntil(this._unsubscribe)).subscribe((value) => {
      if (value == null) {
        return;
      }
      console.log(value.error.message);
      this.notificacionesSE.showError(value.error.message);
      this._unsubscribe.next('');
      this.contratoSE.clearResult();
      this.loaderSE.closeContador();
    });
  }

}
