import { Pipe, PipeTransform } from '@angular/core';
import { Proyectos } from '../Interfaces/proyectos';

@Pipe({
  name: 'crmContabilidad',
  pure: false
})
export class CrmContabilidadPipe implements PipeTransform {

  transform(crm: any): number {
    let contador = 0;
    crm.forEach(cliente => {
      if(cliente.proyecto!= null){
        contador += cliente.proyecto.presupuesto;
      }else{
        contador += cliente.presupuesto;
      }
    });
    return contador;
  }

}
