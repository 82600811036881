<div class="dashboardGeneralContainer admin">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]=""></sidebar>
    </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="Empresa"></app-header-bar>
      </div>
      <div *ngIf="false" class="tarjeta">
        <span class="nombre">Perfil</span>
        <div class="columas">
            <div class="col">
                <label>Nombre</label>
                <input type="text">
            </div>
            <div class="col">
                <label>Clockify Workspace</label>
                <input type="text">
            </div>
        </div>
        <button>Actualizar</button>
      </div>
      <div class="tarjeta">
        <span class="nombre">Coste mensual total</span>
        <div class="anyo">
            <label>Año</label>
            <select [(ngModel)]="anyoSeleccionado" (change)="cambiarAnyo()">
                <option *ngFor="let anyo of anyos">{{anyo}}</option>
            </select>
        </div>
        <div class="meses">
            <div class="mes" *ngFor="let mes of meses;let i = index">
                <label>{{mes}}</label>
                <input type="number" [(ngModel)]="costeMeses[i]">
            </div>
        </div>
        <button (click)="guaradrAnyo()">Actualizar</button>
      </div>

      
      <div class="tarjeta">
        <span class="nombre">Coste indirecto mensual</span>
        <div class="anyo">
            <label>Año</label>
            <select [(ngModel)]="anyoSeleccionado" (change)="cambiarAnyo()">
                <option *ngFor="let anyo of anyos">{{anyo}}</option>
            </select>
        </div>
        <div class="meses">
            <div class="mes" *ngFor="let mes of meses;let i = index">
                <label>{{mes}}</label>
                <input type="number" [(ngModel)]="costeIndirectoMeses[i]">
            </div>
        </div>
        <button (click)="guaradrAnyoIndirecto()">Actualizar</button>
      </div>

      <div class="contenedorTarjetas">
        <div class="tarjeta">
          <span class="nombre">Perfiles</span>
          <div class="perfil">
              <input [(ngModel)]="newPerfil" placeholder="Nuevo perfil">
              <button (click)="addPerfil()">Añadir perfil</button>
          </div>
          <div class="perfiles">
              <div  class="perfil" *ngFor="let perfil of perfiles"><p>{{perfil.nombre}}</p><span (click)="eliminarPerfil(perfil.id)">x</span></div>
          </div>
        </div>
        <div class="tarjeta">
          <span class="nombre">Incidencias</span>
          <div class="perfil">
              <input class="color personalizado" type="color" [(ngModel)]="newColorIncidencia">
              <input [(ngModel)]="newIncidencia" placeholder="Nueva incidencia">
              <button (click)="addIncidencia()">Añadir incidencia</button>
          </div>
          <div class="perfiles">
              <div  class="perfil" *ngFor="let incidencia of incidencias"><p><span class="color" [style.background]="incidencia.color"></span>{{incidencia.nombre}}</p><span (click)="eliminarIncidencia(incidencia.id)">x</span></div>
          </div>
        </div>
    </div>

    </div>
  </div>
  