<div class="confirmarCerrarSesionGeneralContainer" >
    <div class="confirmarCerrarSesionConainer">
        <div class="bodyCerrarSesion">
          <span *ngIf="id==null">Nuevo Cliente CRM</span>
          <span *ngIf="id!=null">Editar Cliente CRM</span>
          <div class="campos">
            <button *ngIf="id==null" (click)="crearCliente()">Crear Cliente</button>
          <div class="linea">
          <div class="campo">
            <label>Cliente</label> 
            <ng-select
            [items]="clientes"
            placeholder="Selecciona el cliente"
            bindLabel="nombre"
            bindValue="id"
            [(ngModel)]="idCliente"
            (change)="cambioCliente($event)"
          >
          </ng-select>
          </div>
              <div class="campo">
            <label>Temperatura</label>
            <input required type="number" [(ngModel)]="temperatura" max="100" min="0">
            </div> 
            </div>
            <button *ngIf="idProyecto==null && idCliente!=null" (click)="crearPresupuesto()">Crear Presupuesto</button>
          <div *ngIf="proyectosMostrar.length > 0" class="linea">
            <div class="campo">
          <label>Proyecto</label> 
         <ng-container *ngIf="!cargandoProyectos"><ng-select
          [items]="proyectosMostrar"
          placeholder="Selecciona el proyecto"
          bindLabel="nombre"
          bindValue="id"
          [(ngModel)]="idProyecto"
        >
        </ng-select></ng-container>
        </div>
            </div>

            <div *ngIf="proyectosMostrar.length <= 0" class="linea">

            <div class="campo">
            <label>Presupuesto</label>
            <input required type="number" [(ngModel)]="presupuesto">
            </div>
          </div>
          </div>
          <div class="bodyBotones">
            <button class="confirmar" (click)="crearClienteCRM(true)" type="button">Guardar</button>
            <button (click)="crearClienteCRM(false)" type="button">Cancelar</button>
          </div>
        </div>
    </div>
  </div>
  