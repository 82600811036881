import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { resultHttp } from '../Interfaces/resultHttp';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class ProcesosService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  updateProceso(idProceso, proceso) {    
    this.http
      .put(this.urlApi + 'api/proceso/' + idProceso, proceso, this.getHeader())
      .subscribe({
        next: (data) => {
          let result = new resultHttp();
          result.ok = true;
          result.status = data['status'];
          result.mensaje = data['message'];
          this.sendNextResult("resultUpdate",result);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.updateProceso, args: [idProceso,proceso] });
          this.checkStatusError(error);
        },
      });
  }

  getProcesosUser(idUser) {    
    this.http
      .get(this.urlApi + 'api/proceso/user/'+idUser, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getProcesosUser, args: [idUser] });
          this.checkStatusError(error);
        },
      });
  }
}
