import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tarjeta-crm',
  templateUrl: './tarjeta-crm.component.html',
  styleUrls: ['./tarjeta-crm.component.css']
})
export class TarjetaCRMComponent implements OnInit {

  constructor() { }
@Input() estado:any;
  ngOnInit(): void {
  }

}
