<div class="popupProcesosGeneralContainer">
  <div class="popupAddProceso">
    <div class="popupAddProcesoHeader">
      <div class="titulo">Editar Proceso</div>
      <div class="icono">
        <svg
          (click)="closePopup(false)"
          xmlns="http://www.w3.org/2000/svg"
          width="22.164"
          height="22.164"
          viewBox="0 0 22.164 22.164"
        >
          <g
            id="Grupo_1598"
            data-name="Grupo 1598"
            transform="translate(-765.793 -924.793)"
          >
            <line
              id="Línea_105"
              data-name="Línea 105"
              x2="20.75"
              y2="20.75"
              transform="translate(766.5 925.5)"
              fill="none"
              stroke="#3b758f"
              stroke-width="2"
            />
            <line
              id="Línea_106"
              data-name="Línea 106"
              x1="20.75"
              y2="20.75"
              transform="translate(766.5 925.5)"
              fill="none"
              stroke="#3b758f"
              stroke-width="2"
            />
          </g>
        </svg>
      </div>
    </div>
    <div class="popupAddProcesoBody">
      <div class="popupAddProcesoBodyLeft">
        <div class="tarjetaProcesoContainer">
          <div class="tarjetaProcesoHeaderContainer">
            <div>
              <span>{{ nombreProceso }}</span>
            </div>
            <div>
              <span>0{{ numeroProceso }}.</span>
            </div>
          </div>
          <div class="tarjetaProcesoBodyContainer">
            <span
              >Estimación media de <br />
              carga de trabajo</span
            >
            <span>{{ porcentaje * 100 | number: "1.0-2" }}%</span>
            <progress
              id="file"
              max="100"
              [value]="porcentaje * 100"
            ></progress>
          </div>
        </div>
        <div class="formularioFechasContainer">
          <div>
            <label for="horasTrabajo">Completado</label>
            <input
              [(ngModel)]="porcentaje1"
              (input)="cambioPorcentaje()"
              type="number"
            />
          </div>

          <div class="fechas">
        
            <div>
              <label for="horasTrabajo">Fecha inicio</label>
              <input
                type="date"
                [(ngModel)]="fechaInicio"
              />
            </div>
            <div>
              <label for="horasTrabajo">Fecha fin</label>
              <input
                min="{{ fechaInicio | date: 'yyyy-MM-dd' }}"
                type="date"
                [(ngModel)]="fechaFin"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="popupAddProcesoBodyRight">
        <div class="campoFormulario">
          <label for="nombreProceso">Nombre del Proceso</label>
          <input
            type="text"
            placeholder="Nombre del proceso"
            [(ngModel)]="nombreProceso"
          />
        </div>
        <div class="campoFormulario">
          <label for="fase">Fase</label>
          <ng-select
            *ngIf="modalData.tipo == 'nuevoProceso'"
            [items]="fases"
            placeholder="Selecciona la fase"
            bindLabel="nombre"
            bindValue="id"
            [(ngModel)]="selectedFase"
          >
          </ng-select>
          <ng-select
            *ngIf="modalData.tipo == 'editarProceso'"
            [items]="fases"
            placeholder="Selecciona la fase"
            bindLabel="nombre"
            bindValue="id"
            [(ngModel)]="selectedFase.id"
          >
          </ng-select>
        </div>
        <div class="campoFormulario">
          <label for="horasTrabajo">Horas de trabajo</label>
          <input
            type="number"
            oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : 0"
            placeholder="Horas de trabajo"
            [(ngModel)]="horas"
          />
        </div>
        <div class="campoFormulario">
          <label for="integrantes">Integrantes</label>
          <ng-select
            [items]="integrantes"
            [multiple]="true"
            placeholder="Selecciona los integrantes"
            bindLabel="nombre"
            bindValue="id"
            [(ngModel)]="selectedIntegrante"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="popupAddProcesoFooter">
      <button type="button" (click)="checkDataProceso()">Finalizar</button>
      <div></div>
    </div>
  </div>
</div>
