<div class="confirmarCerrarSesionGeneralContainer" >
  <div class="confirmarCerrarSesionConainer">
      <div class="bodyCerrarSesion">
        <span>¿Estás seguro que quieres marcar como pagada esta factura?</span>
        <div class="campos">
          <label>Fecha de pago</label>
          <input type="date" [(ngModel)]="fecha_pago" required>
        </div>
        <div class="bodyBotones">
          <button class="confirmar" [disabled]="fecha_pago == null || fecha_pago == undefined || fecha_pago == ''" (click)="confirmarFactura(true)" type="button">Guardar</button>
          <button (click)="confirmarFactura(false)" type="button">Cancelar</button>
        </div>
      </div>
  </div>
</div>
