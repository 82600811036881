import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { ProyectosService } from 'src/app/Services/proyectos.service';
import { ClientesService } from 'src/app/Services/clientes.service';
import { FinanzasService } from 'src/app/Services/finanzas.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupConfirmacionBorradorComponent } from '../popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { PopupService } from 'src/app/Services/popup.service';
import { PopUpInformacionFacturaComponent } from '../pop-up-informacion-factura/pop-up-informacion-factura.component';

@Component({
  selector: 'app-pop-up-factura',
  templateUrl: './pop-up-factura.component.html',
  styleUrls: ['./pop-up-factura.component.css']
})
export class PopUpFacturaComponent extends UnsubscribesDestroy implements OnInit {

  constructor(private proyectosSE: ProyectosService,
    private clientesSE: ClientesService,
    public popup: PopupService,
    private notificacionesSE: NotificacionesService,
    public loaderSE: LoaderService, private finanzasSE: FinanzasService,public dialogRef: MatDialogRef<PopUpFacturaComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { super(); }

    clientes = [];
    clienteBuscar = null;
  ngOnInit(): void {
    if(this.modalData.id != null){
      this.id = this.modalData.id;
      this.idCliente = this.modalData.idCliente;
      this.idProyecto = this.modalData.idProyecto;
      this.fecha = this.modalData.fecha.split('T')[0];
      this.cantidad = this.modalData.cantidad;
      this.concepto = this.modalData.concepto;
      if(this.idProyecto == null){
        this.mostrarBotonFactura = true;
      }
    }
    this.potencial = this.modalData.potencial;
    if(this.modalData.mes!=null){
      if(this.modalData.mes<10){
        this.fecha = new Date().getFullYear() + "-0" + this.modalData.mes + "-01";
      }else{
      this.fecha = new Date().getFullYear() + "-" + this.modalData.mes + "-01";
    }
    }
    if(this.modalData.cliente!=null){
      this.clienteBuscar = this.modalData.cliente;
    }
    this.getProyectosEmpresa();
    this.getClientesEmpresa();
  }
  potencial;
  id = null;
  idProyecto;
  idCliente;
  fecha;
  cantidad;
  concepto;
  crearFactura(valor:boolean){
    if (valor) {
      if(this.id == null){
          this.finanzasSE.postFactura({potencial:this.potencial,cliente:this.idCliente, fecha:this.fecha, proyecto: this.idProyecto, cantidad:this.cantidad, concepto:this.concepto});
          this.loaderSE.open();
          this.finanzasSE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this._unsubscribeIndividual2.next('');
            this.closePopup(true);
          }
          );
          this.finanzasSE.getResultError().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this._unsubscribeIndividual2.next('');
            this.notificacionesSE.showError("Error al crear la factura",value.error.message);
          }
          );
        }else{
          this.finanzasSE.updateFactura(this.id,{fecha:this.fecha, cantidad:this.cantidad, concepto:this.concepto});
          this.loaderSE.open();
          this.finanzasSE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this.finanzasSE.clearResultUpdate();
            this._unsubscribeIndividual2.next('');
            this.closePopup(true);
          }
          );
          this.finanzasSE.getResultError().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this._unsubscribeIndividual2.next('');
            this.notificacionesSE.showError("Error al editar la factura",value.error.message);
          }
          );
        }
    }else{
      this.closePopup(false);
    }
  }

  closePopup(returnValue?:any){
    let p={returnValueFactura:returnValue};
    this.dialogRef.close(p);
  }

  cambioCliente(event){
    this.proyectosMostrar = [];
    this.idProyecto = null;
    this.idCliente = event.id;
    this.buscarProyectos();
  }
  presupuestoRestante = 0;
  cambioProyecto(event){
    this.idProyecto = event.id;
    this.presupuestoRestante = event.presupuesto_restante;
  }
  buscarProyectos(){    
    this.proyectos.forEach(proyecto => {
      if(proyecto.cliente.id == this.idCliente){
        this.proyectosMostrar.push(proyecto);
      }
      if(this.idProyecto == proyecto.id){
        this.mostrarBotonFactura = !proyecto.potencial;
        this.presupuestoRestante = proyecto.presupuesto_restante + this.cantidad;
      }
    });
  }
  mostrarBotonFactura = false;
  proyectos = [];
  proyectosMostrar = [];

  generarFactura(){
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Generar factura del Hito de Pago?',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.popup.clearData();
          this._unsubscribeIndividual.next('');

          this.popup.openPopup(PopUpInformacionFacturaComponent, {
            descripcion: '¿Generar factura del Hito de Pago?',
            id:this.id
          });

          this.popup.returnData().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            if (value.returnValueFacturaNumero) {
              this._unsubscribeIndividual2.next('');
              this.popup.clearData();
              this.closePopup(true);
            }
          });
        }
        this.popup.clearData();

      });
  }
  getProyectosEmpresa() {
    this.proyectosSE.getAllProyectos(5000, 1, 'presupuesto');
    this.loaderSE.open();
    this.proyectosSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        console.log(value);
        value.forEach((element) => {
          if (!this.proyectos.includes(element)) {
            this.proyectos.push(element);
          }
        });
        this.buscarProyectos();
        this._unsubscribeIndividual.next('');
        this.proyectosSE.clearResult();
        this.loaderSE.closeContador();
      });
  }
  
  getClientesEmpresa() {
    let idEmpresa = localStorage.getItem('numEmpresa');
    this.loaderSE.open();
    this.clientesSE.getAllClientesByEmpresa(idEmpresa);
    this.clientesSE.getResult().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((data) => {
      if (data != null) {
        this.clientes = data;
        if(this.clienteBuscar != null){
        data.forEach(cliente => {
          if(cliente.nombre == this.clienteBuscar){
            this.idCliente = cliente.id;
            this.buscarProyectos();
          }
        });
      }
        this.loaderSE.closeContador();
      }
    });
  }

  eliminar(){
    this.popup.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: '¿Eliminar el hito de pago?',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.popup.clearData();
          this._unsubscribeIndividual.next('');
          this.loaderSE.open();
          this.finanzasSE.deleteFactura(this.id);
          this.finanzasSE.getResultDelete().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this._unsubscribeIndividual2.next('');
            this.finanzasSE.clearResult();
            this.notificacionesSE.showFeedBack('Hito de pago eliminado correctamente');
            this.closePopup(true);
          });
          this.finanzasSE.getResultDeleteError().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this._unsubscribeIndividual2.next('');
            this.notificacionesSE.showError('Error al eliminar el hito de pago');
            this.finanzasSE.clearResult();
          });
        }
      });
  }
  
}
