import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { FinanzasService } from 'src/app/Services/finanzas.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';

@Component({
  selector: 'app-pop-up-informacion-factura',
  templateUrl: './pop-up-informacion-factura.component.html',
  styleUrls: ['./pop-up-informacion-factura.component.css']
})
export class PopUpInformacionFacturaComponent extends UnsubscribesDestroy implements OnInit {
  constructor(
    private finanzasSE :FinanzasService,
    private loaderSE: LoaderService,
    private notificacionesSE: NotificacionesService,
    public dialogRef: MatDialogRef<PopUpInformacionFacturaComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
  }
  fecha = null;
  numero=null;
  iva = null;
  irpf = null;
  id = null;
  ngOnInit(): void {
    this.id = this.modalData.id;
  }
  
  confirmarGuardarBorrador(valor: boolean) {
    this.closePopup(valor);
  }

  closePopup(returnValue?: any) {
    let p = { returnValueFacturaNumero: returnValue };
    this.dialogRef.close(p);
  }
  
  generarFactura(){
    if(this.numero == null || this.numero == "" || this.fecha == null){
      this.notificacionesSE.showError("Error al editar la factura","El número de factura y la fecha son obligatorios");
      return;
    }
    this.finanzasSE.updateFactura(this.id,{numero:this.numero,potencial:false,iva:this.iva*0.01,irpf:this.irpf*0.01,fechaEmision:this.fecha});
    this.loaderSE.open();
    this.finanzasSE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
      if (value == null) {
        return;
      }
      this.loaderSE.closeContador();
      this.finanzasSE.clearResultUpdate();
      this._unsubscribeIndividual2.next('');
      this.closePopup(true);
    });
    this.finanzasSE.getResultError().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
      if (value == null) {
        return;
      }
      this.loaderSE.closeContador();
      this._unsubscribeIndividual2.next('');
      this.notificacionesSE.showError("Error al editar la factura",value.error.message);
    });
  }
}

