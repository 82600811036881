<div class="equipoGeneralContainer">
  <div class="sideBarGeneralContainer">
    <sidebar [elementoActivo]="'equipo'"></sidebar>
  </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="Equipo" (accionBoton)="addIntegrante()" [boton]="true"></app-header-bar>
      </div>
        <div class="dataBodyContainer">
          <div class="tabla">
            <div class="linea cabecera">
              <div (click)="activarFiltro('nombre')" [class.asc]="asc" class="filtrable" [class.activo]="filtro=='nombre'">
                Nombre integrante
              </div>
              <div class="carga">
                CP
              </div>
              <div class="carga">
                MP
              </div>
              <div class="carga">
                LP
              </div>
              <div class="filtrable tipoCoste" (click)="activarFiltro('tipo_coste')" [class.asc]="asc" [class.activo]="filtro=='tipo_coste'">
                Tipo de coste
              </div>
              <div>
                Rol
              </div>
              <div class="filtrable coste" (click)="activarFiltro('coste')" [class.asc]="asc" [class.activo]="filtro=='coste'">
                Coste/hora
              </div>
              <div class="filtrable tipoContrato" (click)="activarFiltro('horas')" [class.asc]="asc" [class.activo]="filtro=='horas'">
                Tipo de contrato
              </div>
            </div>
            <div
              class="linea"
              [class.disabled]="trabajador.can_access == false"
              *ngFor="let trabajador of trabajadores"
              (click)="abrirInformacionEmpleado(trabajador)"
            >
              <div class="imagenPerfil">                
                <span *ngIf="trabajador.profile == null">{{ trabajador.nombre | slice: 0:1}}{{ trabajador.apellidos | slice: 0:1 }}</span>
                <img
                  *ngIf="trabajador.profile != null"
                  [src]="environment.urlImg + trabajador.profile"
                  alt=""
                />
                <div class="nombre overflow" [matTooltip]="trabajador.nombre+' '+trabajador.apellidos" matTooltipClass="tooltip" matTooltipPosition="above">
                  {{ trabajador.nombre }} {{ trabajador.apellidos }}
                </div>
              </div>
              <div class="carga cp"><span>{{trabajador.horas_corto / trabajador.horas | percent: '1.0-0'}}</span></div>
              <div class="carga mp"><span>{{trabajador.horas_medio / trabajador.horas | percent: '1.0-0'}}</span></div>
              <div class="carga lp"><span>{{trabajador.horas_largo / trabajador.horas | percent: '1.0-0'}}</span></div>
              <div class="tipoCoste">
                <span>{{trabajador.tipo_coste}}</span>
              </div>
              <div class="roles"><span *ngFor="let rol of trabajador.departamentos">{{rol.nombre}}</span></div>
              <div class="coste">{{trabajador.coste |currency:'EUR'}}/hora</div>
              <div class="tipoContrato">
                  {{ tipoContratoHoras(trabajador.horas) }}
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
