
                <div
                class="tarjetaProyecto"
                [class.potencial]="proyecto.potencial"
                [routerLink]="['/proyectos/'+proyecto.id]"
              >
                <div class="tarjetaProyectoDepartamentoHeader">
                  <span class="abreviatura" [ngStyle]="{ background: proyecto.etiqueta }">{{ proyecto.abreviatura }}</span>
                  <span>{{ proyecto.nombre }}</span>
                  <span class="porcentaje">{{  proyecto.completado * 100 | number: "1.0-0"}}%</span>
                </div>
                <div class="progreso">
                  <progress [value]="proyecto.completado" max="1"></progress>
                </div>
                <div class="equipoPorcentaje">
                  <div class="equipo">
                    <ng-template
                      ngFor
                      let-item
                      [ngForOf]="proyecto.empleados"
                      let-i="index"
                    >
                    
                    <ng-container *ngIf="item.profile == null">
                        <span *ngIf="i < 4">{{ item.nombre | slice: 0:1}}{{ item.apellidos | slice: 0:1 }}</span>
                    </ng-container>
                    <ng-container *ngIf="item.profile != null">
                        <img *ngIf="i < 4" [src]="environment.urlImg + item.profile">
                    </ng-container>
                    </ng-template>
                    <span *ngIf="proyecto.empleados.length > 4">+{{ proyecto.empleados.length - 4 }}</span>
                  </div>
                </div>
              </div>