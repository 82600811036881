export interface Departamentos {
    id: number,
    nombre: string,
    color: string,
    usuario: Array<any>,
    empresa: Array<any>
}
export class Departamentos implements Departamentos{
    static converObjectToFront(obj){
        let departamento=new Departamentos();
        departamento.id = obj["id"];
        departamento.nombre = obj["nombre"];
        departamento.color = obj["color"];
        departamento.usuario = obj["usuario"];
        departamento.empresa = obj["empresa"];
        return departamento;
    }
}