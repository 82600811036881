import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { Departamentos } from '../Interfaces/departamentos';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class DepartamentosService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  getDepartamentosByEmpresa() {    
    this.http
      .get(
        this.urlApi + 'api/departamento',
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          let departamentos = [];
          departamentos.push(data);
          departamentos.forEach((element) => {
            let departamento: Departamentos =
              Departamentos.converObjectToFront(data);
            departamento = element;
            this.sendNextResult("result",departamento);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getDepartamentosByEmpresa, args: [] });
          this.checkStatusError(error);
        },
      });
  }

  postDepartamento(departamento) {
    this.http
      .post(
        this.urlApi + 'api/departamento',
        departamento,
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.postDepartamento, args: [departamento] });
          this.checkStatusError(error);
        },
      });
  }
  deleteDepartamento(id) {
    this.http
      .delete(
        this.urlApi + 'api/departamento',
        this.sendBodyOptions({departamentos_ids: [id]})
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultDelete",data);
        },
        error: (error) => {
          this.sendNextResult('resultDeleteError', error, { method: this.deleteDepartamento, args: [id] });
          this.checkStatusError(error);
        },
      });
  }
}
