import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from './Interfaces/unsubscribesDestroy';
import { LoginService } from './Services/login.service';
import { NotificacionesService } from './Services/notificaciones.service';
import { TokenManagmentService } from './Services/TokenManagment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent extends UnsubscribesDestroy implements OnInit {
  constructor(private loginSE: LoginService, private notificaciones: NotificacionesService) {
    super();
  }
  ngOnInit(): void {
    if (localStorage.getItem('login') === null) {
      localStorage.setItem('login', 'needLogin');
    }

    TokenManagmentService.listenerTokenLost().pipe(takeUntil(this._unsubscribeIndividual4)).subscribe(res=>{
      this.notificaciones.showError("Lost security token, please log in again.")
      this.loginSE.desconectar();
    })

    TokenManagmentService.listenerStartRefreshToken().pipe(takeUntil(this._unsubscribeIndividual4)).subscribe(res=>{
      this.loginSE.refreshToken()
    })

    
  }
  title = 'Fenech';
}
