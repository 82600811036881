import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { ProyectosService } from 'src/app/Services/proyectos.service';
import { ClientesService } from 'src/app/Services/clientes.service';
import { ClienteCRMService } from 'src/app/Services/cliente-crm.service';
import { PopupAddClienteComponent } from '../popup-add-cliente/popup-add-cliente.component';
import { PopupService } from 'src/app/Services/popup.service';
import { PopUpPresupuestoComponent } from '../pop-up-presupuesto/pop-up-presupuesto.component';

@Component({
  selector: 'app-pop-up-crm',
  templateUrl: './pop-up-crm.component.html',
  styleUrls: ['./pop-up-crm.component.css']
})
export class PopUpCRMComponent
extends UnsubscribesDestroy implements OnInit {

  constructor(private proyectosSE: ProyectosService, public popup: PopupService, private clientesSE: ClientesService, public loaderSE: LoaderService, private clienteCRMSE: ClienteCRMService,public dialogRef: MatDialogRef<PopUpCRMComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { super(); }

    clientes = [];
  ngOnInit(): void {
    if(this.modalData.id != null){
      this.id = this.modalData.id;
    }else{
      this.idCRM = this.modalData.idCRM;
    }
    console.log(this.modalData);
    this.idCliente = this.modalData.idCliente;
    this.idProyecto = this.modalData.idProyecto;
    this.temperatura = this.modalData.temperatura;
    this.presupuesto = this.modalData.presupuesto;
    this.getProyectosEmpresa();
    this.getClientesEmpresa();
  }
  id = null;
  idCRM;
  idProyecto;
  idCliente;
  temperatura;
  presupuesto;
  crearClienteCRM(valor:boolean){
    if (valor) {
      if(this.id == null){
          this.clienteCRMSE.createClienteCRM({estadoCRM:this.idCRM,cliente:this.idCliente, temperatura:this.temperatura, presupuesto: this.presupuesto, proyecto: this.idProyecto});
          this.loaderSE.open();
          this.clienteCRMSE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this._unsubscribeIndividual2.next('');
            this.closePopup(true);
          }
          );
        }else{
          this.clienteCRMSE.updateClienteCRM(this.id,{cliente:this.idCliente, temperatura:this.temperatura, presupuesto: this.presupuesto, proyecto: this.idProyecto});
          this.loaderSE.open();
          this.clienteCRMSE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this.clienteCRMSE.clearResultUpdate();
            this._unsubscribeIndividual2.next('');
            this.closePopup(true);
          }
          );
        }
    }else{
      this.closePopup(false);
    }
  }

  closePopup(returnValue?:any){
    let p={returnValue:returnValue};
    this.dialogRef.close(p);
  }

  cambioCliente(event){
    this.proyectosMostrar = [];
    this.idProyecto = null;
    this.idCliente = event.id;
    this.buscarProyectos();
  }
  buscarProyectos(){    
    this.proyectos.forEach(proyecto => {
      if(proyecto.cliente.id == this.idCliente){
        this.proyectosMostrar.push(proyecto);
      }
    });
  }
  proyectos = [];
  proyectosMostrar = [];
  cargandoProyectos = false;
  getProyectosEmpresa() {
    this.proyectos = [];
    this.cargandoProyectos = true;
    this.proyectosSE.getAllProyectos(5000, 1,'select');
    this.loaderSE.open();
    this.proyectosSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        console.log(value);
        value.forEach((element) => {
          if (!this.proyectos.includes(element)) {
            this.proyectos.push(element);
          }
        });
        this.cargandoProyectos = false;
        this.buscarProyectos();
        this._unsubscribeIndividual.next('');
        this.proyectosSE.clearResult();
        this.loaderSE.closeContador();
      });
  }
  
  getClientesEmpresa() {
    let idEmpresa = localStorage.getItem('numEmpresa');
    this.loaderSE.open();
    this.clientesSE.getAllClientesByEmpresa(idEmpresa);
    this.clientesSE.getResult().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((data) => {
      if (data != null) {
        this.clientes = data;
        this.loaderSE.closeContador();
      }
    });
  }

  crearCliente(){
    this.popup.openPopup(PopupAddClienteComponent,{});
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual3))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.getClientesEmpresa();
          this.idCliente = value.id;
        }
        this.popup.clearData();
        this._unsubscribeIndividual3.next('');
      }
      );
  }

  crearPresupuesto(){
    this.popup.openPopup(PopUpPresupuestoComponent,{id:this.idCliente});
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual3))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValuePropuesta) {
          this.getProyectosEmpresa();
          this.idProyecto = value.id;
          this.popup.clearData();
          this._unsubscribeIndividual3.next('');
        }
      }
      );
  }
}
