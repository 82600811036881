import { Clientes } from "./clientes";
import { Departamentos } from "./departamentos";
import { Proyectos } from "./proyectos";
import { User } from "./user";

export interface Empresa {
    alta: string,
    clientes: Array<Clientes>,
    color: string,
    departamentos: Array<Departamentos>,
    nombre: string,
    plan: 1,
    proyectos: Array<Proyectos>,
    usuarios: Array<User>
    id_clockify: string
}
export class Empresa implements Empresa{
    static converObjectToFront(obj){
        let empresa=new Empresa();
        empresa.alta = obj["alta"];
        empresa.clientes = obj["clientes"];
        empresa.departamentos = obj["departamentos"];
        empresa.nombre = obj["nombre"];
        empresa.plan = obj["plan"];
        empresa.proyectos = obj["proyectos"];
        empresa.usuarios = obj["usuarios"];
        empresa.id_clockify = obj["id_clockify"];
        return empresa;
    }
    
}