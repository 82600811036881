<div class="grficoContainer">
  <div class="grafico">
    <svg [class.activo]="porcentaje < 33" xmlns="http://www.w3.org/2000/svg" width="35.658" height="61.19" viewBox="0 0 35.658 61.19">
      <path id="Elipse_3567" data-name="Elipse 3567" d="M58.653,25.878a4.054,4.054,0,0,1-6.277,5.132A62.253,62.253,0,0,0,4.054,8.114,4.054,4.054,0,0,1,0,4.06,4,4,0,0,1,4.054.006C20.375-1.1,43.833,7.49,58.653,25.878Z" transform="translate(3.207 61.186) rotate(-93)" fill="#1de572"/>
    </svg>
    <svg [class.activo]="porcentaje > 33 && porcentaje < 66" xmlns="http://www.w3.org/2000/svg" width="67.822" height="55.398" viewBox="0 0 67.822 55.398">
      <path id="Elipse_3567" data-name="Elipse 3567" d="M58.653,25.878a4.054,4.054,0,0,1-6.277,5.132A62.253,62.253,0,0,0,4.054,8.114,4.054,4.054,0,0,1,0,4.06,4,4,0,0,1,4.054.006C20.375-1.1,43.833,7.49,58.653,25.878Z" transform="matrix(0.899, -0.438, 0.438, 0.899, 0.039, 26.192)" fill="#ff9b14"/>
    </svg>
    <svg [class.activo]="porcentaje > 66" xmlns="http://www.w3.org/2000/svg" width="66.07" height="64.072" viewBox="0 0 66.07 64.072">
      <path id="Elipse_3567" data-name="Elipse 3567" d="M58.653,25.878a4.054,4.054,0,0,1-6.277,5.132A62.253,62.253,0,0,0,4.054,8.114,4.054,4.054,0,0,1,0,4.06,4,4,0,0,1,4.054.006C20.375-1.1,43.833,7.49,58.653,25.878Z" transform="translate(21.744 0.066) rotate(42)" fill="#ed0505"/>
    </svg>
    
  </div>
    <div class="circulo" [style.transform]="'translateX(-60px) rotate('+conversion(porcentaje)+'deg)'"><svg xmlns="http://www.w3.org/2000/svg" width="16.98" height="13.992" viewBox="0 0 16.98 13.992">
      <g id="Polígono_230" data-name="Polígono 230" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.577)" [attr.fill]="ponerColor()">
        <path style="filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24));" d="M 12.94279003143311 12.92405700683594 L 3.579129695892334 12.92405700683594 C 3.028029680252075 12.92405700683594 2.538529634475708 12.63684749603271 2.269699573516846 12.1557674407959 C 2.000869512557983 11.6746768951416 2.01280951499939 11.10725688934326 2.301639556884766 10.63791751861572 L 6.983469486236572 3.029937267303467 C 7.25853967666626 2.582947254180908 7.736109733581543 2.316087245941162 8.260959625244141 2.316087245941162 C 8.785809516906738 2.316087245941162 9.26338005065918 2.582947254180908 9.538449287414551 3.029937267303467 L 14.22027969360352 10.63791751861572 C 14.50910949707031 11.10725688934326 14.52104949951172 11.6746768951416 14.25221920013428 12.1557674407959 C 13.98338985443115 12.63684749603271 13.49388980865479 12.92405700683594 12.94279003143311 12.92405700683594 Z" stroke="none"/>
        <path d="M 8.260959625244141 2.816086769104004 C 8.092939376831055 2.816086769104004 7.673619270324707 2.862457275390625 7.409299850463867 3.291987419128418 L 2.727469444274902 10.89996719360352 C 2.449929237365723 11.35097694396973 2.620120048522949 11.75785732269287 2.706179618835449 11.91185760498047 C 2.792229652404785 12.06586742401123 3.049559593200684 12.42405700683594 3.579129219055176 12.42405700683594 L 12.94278907775879 12.42405700683594 C 13.4723596572876 12.42405700683594 13.7296895980835 12.06586742401123 13.81573963165283 11.91185760498047 C 13.90180015563965 11.75785732269287 14.07198905944824 11.35097694396973 13.79444980621338 10.89996719360352 L 9.112619400024414 3.291987419128418 C 8.848299026489258 2.862457275390625 8.428969383239746 2.816086769104004 8.260959625244141 2.816086769104004 M 8.260959625244141 1.816084861755371 C 8.917377471923828 1.816084861755371 9.573795318603516 2.133352279663086 9.964279174804688 2.767887115478516 L 14.64610958099365 10.37586784362793 C 15.46612930297852 11.70840740203857 14.5074291229248 13.42405700683594 12.94278907775879 13.42405700683594 L 3.579129219055176 13.42405700683594 C 2.01448917388916 13.42405700683594 1.055789947509766 11.70840740203857 1.875809669494629 10.37586784362793 L 6.557640075683594 2.767887115478516 C 6.948124885559082 2.133352279663086 7.604541778564453 1.816084861755371 8.260959625244141 1.816084861755371 Z" stroke="none" fill="#fff"/>
      </g>
    </svg>
    
    </div>
    <span>{{ porcentaje | number: "0.0-0" }}%</span>
  </div>