<div class="popupInformacionEmpleadoGeneralContainer">
  <div class="popupInformacionEmpleado">
    <div class="popupInformacionHeader">
      <svg
        _ngcontent-irm-c70=""
        xmlns="http://www.w3.org/2000/svg"
        width="22.164"
        height="22.164"
        viewBox="0 0 22.164 22.164"
        (click)="closePopup()"
      >
        <g
          _ngcontent-irm-c70=""
          id="Grupo_1598"
          data-name="Grupo 1598"
          transform="translate(-765.793 -924.793)"
        >
          <line
            _ngcontent-irm-c70=""
            id="Línea_105"
            data-name="Línea 105"
            x2="20.75"
            y2="20.75"
            transform="translate(766.5 925.5)"
            fill="none"
            stroke="#3b758f"
            stroke-width="2"
          ></line>
          <line
            _ngcontent-irm-c70=""
            id="Línea_106"
            data-name="Línea 106"
            x1="20.75"
            y2="20.75"
            transform="translate(766.5 925.5)"
            fill="none"
            stroke="#3b758f"
            stroke-width="2"
          ></line>
        </g>
      </svg>
    </div>
    <div class="popupInformacionDatos">
      <div class="grafica">
        <div id="chart">
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [labels]="chartOptions.labels"
            [responsive]="chartOptions.responsive"
            [plotOptions]="chartOptions.plotOptions"
            [dataLabels]="chartOptions.dataLabels"
            [legend]="chartOptions.legend"
          ></apx-chart>
        </div>
        <span>{{ trabajador.nombre }} {{ trabajador.apellidos }}</span>
        <div class="departamentos">
          <span
            class="departamento"
            *ngFor="let departamento of trabajador.departamentos"
            >{{ departamento.nombre }}
          </span>
        </div>
        <button (click)="cambiarAcceso()" *ngIf="trabajador.can_access">Quitar acceso</button>
        <button (click)="cambiarAcceso()" *ngIf="!trabajador.can_access">Dar acceso</button>
      </div>
      <div class="datos">
        <div>
          <menu-navegacion
            [opcionPorDefecto]="'Corto Plazo'"
            [tipoMenu]="'submenu'"
            [opciones]="['Corto Plazo', 'Medio Plazo', 'Largo Plazo']"
            (emitOpcionSubmenuSeleccionada)="opcionSeleccionada($event)"
          ></menu-navegacion>
        </div>
        <div class="proyectosEtiquetasContainer">
          <ng-container *ngIf="opcion == 'Corto Plazo'">
            <ng-container
              *ngFor="let proyecto of trabajador.proyectosCortoPlazo"
            >
              <!-- <div class="proyectoEtiqueta" [ngStyle]="{background: proyecto.color}">{{proyecto.siglas}}</div> -->
            </ng-container>
          </ng-container>
          <ng-container *ngIf="opcion == 'Medio Plazo'">
            <ng-container
              *ngFor="let proyecto of trabajador.proyectosMedioPlazo"
            >
              <!-- <div class="proyectoEtiqueta" [ngStyle]="{background: proyecto.color}">{{proyecto.siglas}}</div> -->
            </ng-container>
          </ng-container>
          <ng-container *ngIf="opcion == 'Largo Plazo'">
            <ng-container
              *ngFor="let proyecto of trabajador.proyectosLargoPlazo"
            >
              <!-- <div class="proyectoEtiqueta" [ngStyle]="{background: proyecto.color}">{{proyecto.siglas}}</div> -->
            </ng-container>
          </ng-container>
        </div>
        <div class="horasContainer">
          <div>
            <span>Horas Contrato</span>
            <span>{{ trabajador.horas }}</span>
          </div>
          <div>
            <span>Carga</span>

            <span
              [class.bueno]="porcentaje >= 0 && porcentaje <= 49"
              [class.medio]="porcentaje >= 50 && porcentaje <= 79"
              [class.alto]="porcentaje >= 80"
              >{{ porcentaje }}%</span
            >
          </div>
        </div>
        <!--<div class="cargaContainer">
          <span>Carga total de Trabajo</span>

          <span
            [class.bueno]="porcentaje >= 0 && porcentaje <= 49"
            [class.medio]="porcentaje >= 50 && porcentaje <= 79"
            [class.alto]="porcentaje >= 80 && porcentaje <= 100"
            >{{ porcentaje }}%</span
          >
        </div>-->
      </div>
    </div>
    <div class="popupInformacionProcesos">
      <div class="tituloInformacion">
        <span>Procesos asignados</span>
      </div>
      <div
        class="procesoCarrouselVacio"
        *ngIf="trabajador.procesos.length == 0"
      >
        <span>No hay procesos asignados</span>
      </div>
      <div class="procesoCarrousel" *ngIf="trabajador.procesos.length != 0">
        <div class="atras">
          <svg
            (click)="retrasar()"
            *ngIf="trabajador.procesos.length > 1 && procesoActualIndice > 0"
            xmlns="http://www.w3.org/2000/svg"
            width="15.182"
            height="26.121"
            viewBox="0 0 15.182 26.121"
          >
            <path
              id="Trazado_278"
              data-name="Trazado 278"
              d="M1012.462,14268.778l-12,12,12,12"
              transform="translate(-998.341 -14267.718)"
              fill="none"
              stroke="var(--color-texto-color)"
              stroke-width="3"
            />
          </svg>
        </div>
        <div class="tarjeta">
          <div class="decoradorSuperior"></div>
          <div class="tarjetaProyectoDepartamento">
            <div class="tarjetaProyectoDepartamentoHeader">
              <span>{{ procesoActual.proyecto.abreviatura }}</span>
              <span>{{ procesoActual.proyecto.nombre }}</span>
            </div>
            <div class="tarjetaProyectoDepartamentoBody">
              <div class="proceso">
                <div>
                  <span>{{ procesoActual.nombre }}</span>
                </div>
                <!--<div>0{{ procesoActual.id }}.</div>-->
              </div>
              <div class="horasTiempo">
                <div>
                  <label>Fecha finalización</label>
                  <span>{{ procesoActual.fecha_fin.split("T")[0] }}</span>
                </div>
                <div>
                  <label>Horas totales</label>
                  <span>{{ procesoActual.horas }}</span>
                </div>
              </div>
              <div class="progresoContainer">
                <div>
                  <label>{{ procesoActual.completado * 100 }}%</label>
                  <progress
                    [value]="procesoActual.completado * 100"
                    max="100"
                  ></progress>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="delante">
          <svg
            (click)="avanzar()"
            *ngIf="
              trabajador.procesos.length > 1 &&
              procesoActualIndice < trabajador.procesos.length - 1
            "
            xmlns="http://www.w3.org/2000/svg"
            width="15.182"
            height="26.121"
            viewBox="0 0 15.182 26.121"
          >
            <path
              id="Trazado_277"
              data-name="Trazado 277"
              d="M1000.462,14268.778l12,12-12,12"
              transform="translate(-999.401 -14267.718)"
              fill="none"
              stroke="var(--color-texto-color)"
              stroke-width="3"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
