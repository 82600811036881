import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { Login, LoginService } from 'src/app/Services/login.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { TokenManagmentService } from 'src/app/Services/TokenManagment.service';
import { UserService } from 'src/app/Services/user.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent extends UnsubscribesDestroy implements OnInit {
  email: string = '';
  password: string = '';
  placeHolderEmail = 'Correo electrónico';
  placeHolderPassword = 'Contraseña';
  vacioPassword = false;
  vacioEmail = false;
  emailValido = true;
  test;
  constructor(
    private loginService: LoginService,
    private router: Router,
    private notificacionesSE: NotificacionesService,
    private userService: UserService,
    private loaderSE: LoaderService,
    
  ) {
    super();
  }
version = environment.version;
  enrutarDependiendoRol() {
    if (this.loginService.isIntermedio()) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/daily']);
    }
  }

  ngOnInit(): void {
    if(this.loginService.isLogin()){
      this.loaderSE.open();
      if(this.loginService.getTokenRefresh() != null){
        this.loginService.refreshToken();
        TokenManagmentService.listenerRefreshToken().pipe(takeUntil(this._unsubscribeIndividual)).subscribe(value=>{
          if(value!=null){
            if(value){
              this.loaderSE.closeContador();
              this.enrutarDependiendoRol();
            }else{
              this.loginService.cerrarSesion();
            }
          }
        });
        TokenManagmentService.listenerTokenLost().pipe(takeUntil(this._unsubscribeIndividual)).subscribe(value=>{
          if(value!=null){
            this.loaderSE.closeContador();
            this.loginService.cerrarSesion();
          }
        });
      }else{
        this.loaderSE.closeContador();
        this.loginService.cerrarSesion();
      }
    }
  }
  ngOnDestroy() {
    this._unsubscribeIndividual.next('');
  }
  validarCampos() {
    var valido = true;
    if (this.email == '') {
      this.vacioEmail = true;
      valido = false;
      this.notificacionesSE.showError(
        'Hay fallos en los campos porfavor, revisalos',
        'El correo electrónico no puede estar vacío'
      );
    } else {
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      this.vacioEmail = false;
      this.placeHolderEmail = 'Correo electrónico';
      if (!pattern.test(this.email)) {
        valido = false;
        this.emailValido = false;
        this.email = '';
        this.notificacionesSE.showError(
          'Hay fallos en los campos porfavor, revisalos',
          'El correo electrónico introducido no es válido'
        );
      } else {
        this.emailValido = true;
        valido = true;
      }
    }

    if (this.password == '') {
      this.vacioPassword = true;
      valido = false;
      this.notificacionesSE.showError(
        'Hay fallos en los campos porfavor, revisalos',
        'La contraseña no puede estar vacío'
      );
    } else {
      this.vacioPassword = false;
      this.placeHolderPassword = 'Contraseña';
    }

    if (valido == true) {
      let login: Login = {
        username: this.email,
        password: this.password,
      };
      this.loginService.checkLogin(login);
      this.loaderSE.open();
      this.loginService
        .getResult()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.userService.getMyUser();
          this.loaderSE.closeContador();
            this.enrutarDependiendoRol();
        });
      this.loginService.getResultError().pipe(takeUntil(this._unsubscribeIndividual)).subscribe(value=>{
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.notificacionesSE.showError(value.error.message);
      });
    }
  }
}
