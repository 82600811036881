<div class="dashboardGeneralContainer">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'cliente'"></sidebar>
    </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="Clientes" (accionBoton)="addCliente()" [boton]="true"></app-header-bar>
      </div>
  
    <div class="dataBodyContainer"> 
    <div class="cuerpo">
      <div class="tabla">
        <div class="cabecera">
            <div class="grande">Nombre</div>
            <div>CIF</div>
            <div class="overflow">Dirección</div>
            <div class="pequenyo">Teléfono</div>
            <div class="grande">Mail</div>
            <div class="pequenyo">Proyectos</div>
            <div class="pequenyo">Acción</div>
        </div>
        <div *ngFor="let item of clientes">
            <div  class="grande overflow" (click)="editarCliente(item)"><span [matTooltip]="item.nombre" matTooltipClass="tooltip" matTooltipPosition="above">{{item.nombre}}</span></div>
            <div class="overflow" (click)="editarCliente(item)"><span [matTooltip]="item.cif" matTooltipClass="tooltip" matTooltipPosition="above">{{item.cif}}</span></div>
            <div class="overflow" (click)="editarCliente(item)"><span [matTooltip]="item.direccion_fiscal" matTooltipClass="tooltip" matTooltipPosition="above">{{item.direccion_fiscal}}</span></div>
            <div class="pequenyo overflow" (click)="editarCliente(item)"><span [matTooltip]="item.telefono" matTooltipClass="tooltip" matTooltipPosition="above">{{item.telefono}}</span></div>
            <div class="grande overflow" (click)="editarCliente(item)"><span [matTooltip]="item.mail" matTooltipClass="tooltip" matTooltipPosition="above">{{item.mail}}</span></div>
            <div class="pequenyo overflow" (click)="editarCliente(item)"><span [matTooltip]="item.proyectos.length" matTooltipClass="tooltip" matTooltipPosition="above">{{item.proyectos.length}}</span></div>
            <div class="pequenyo">
              <button class="eliminar" (click)="eliminarCliente(item.id)">Eliminar</button></div>
        </div>

      </div>
      <mat-paginator *ngIf="cantidadPaginas>pageSize" [length]="cantidadPaginas"
        [pageIndex]="pagina"
        (page)="handlePageEvent($event)"
        [pageSize]=pageSize
        [pageSizeOptions]="[25 , 50 , 75 , 100]"
        aria-label="Select page">
      </mat-paginator>
    </div>

    </div>
</div>
  