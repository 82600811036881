<div class="dashboardGeneralContainer admin">
  <div class="sideBarGeneralContainer">
    <sidebar [elementoActivo]=""></sidebar>
  </div>
  <div class="dataGeneralContainer">
    <div class="dataHeaderContainer">
      <app-header-bar titulo="Ajustes" [cerrar]="true"></app-header-bar>
    </div>
    <div class="dataBodyContainer">
      <div class="dataFormContainer">
        <div class="formFirstBlock">
          <div class="formFirstBlock-one">
            <label for="nombre" [class.vacio]="vacioNombre"
              >Nombre</label
            >
            <input
              type="text"
              placeholder="Introduce el nombre"
              [(ngModel)]="user.nombre"
              [class.vacio]="vacioNombre"
            />
          </div>
          <div class="formFirstBlock-two">
            <label for="nombre">Mail</label>
            <input
              type="text"
              disabled
              placeholder="Introduce el nombre"
              [(ngModel)]="user.username"
              [class.vacio]="vacioNombre"
            />
          </div>
        </div>
        <div class="formSecondBlock">
          <div class="formSecondBlock-one">
            <label for="apellidos" [class.vacio]="vacioApellidos"
              >Apellidos</label
            >
            <input
              type="text"
              placeholder="Introduce los apellidos"
              [(ngModel)]="user.apellidos"
              [class.vacio]="vacioApellidos"
            />
          </div>
          <div class="formSecondBlock-two">
            <label for="Horario" [class.vacio]="vacioHorario"
              >Horas semanales</label
            >
            <input
              type="number"
              placeholder="Introduce el horario"
              [(ngModel)]="user.horas"
              [class.vacio]="vacioHorario"
            />
          </div>
        </div>

        
        <div class="formSecondBlock">
          <div class="formSecondBlock-one">
            <label for="api"
              >Api clockify</label
            >
            <input
              type="text"
              name="apiClockify"
              autocomplete="off"
              placeholder="Introduce tu api"
              [(ngModel)]="user.id_clockify"
            />
          </div>
          <div class="formSecondBlock-two">
            <a href="https://app.clockify.me/user/settings" target="_blank">
              <button type="button" class="small">Obtener API Key</button></a>
          </div>
        </div>


        
        
        <div class="formSecondBlock">
          <div class="formSecondBlock-one">
            <label for="pass" [class.vacio]="vacioApellidos"
              >Contraseña</label
            >
            <input
              type="password"
              placeholder="Contraseña"
              [(ngModel)]="password"
              [class.vacio]="vacioPass"
            />
          </div>
          <div class="formSecondBlock-two">
            <label for="pass2" [class.vacio]="vacioHorario"
              >Repetir contraseña</label
            >
            <input
              type="password"
              placeholder="Contraseña"
              [(ngModel)]="password2"
              [class.vacio]="vacioPass"
            />
          </div>
        </div>

        
      </div>
      <div class="dataFormContainer">
        <div class="profile" (click)="resizeImg = true">
          <img [src]="perfilImg" />
        </div>
      </div>
      <div class="botonesContainer">
        <button (click)="comprobarDatos()" type="button">
          Actualizar usuario
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal-image
  *ngIf="resizeImg"
  [titulo]="'Imagen de perfil'"
  (imagen)="recogerImagen($event)"
  [tam]="120"
  (propagar)="cerrar()"
  [aspectRatio]="1 / 1"
></app-modal-image>
