export interface Clientes {
  id: number;
  nombre: string;
  cif: string;
  direccion_fiscal: string;
  mail: string;
  telefono: string;
}
export class Clientes implements Clientes {
  static converObjectToFront(obj) {
    let cliente = new Clientes();
    cliente.id = obj['id'];
    cliente.nombre = obj['nombre'];
    cliente.cif = obj['cif'];
    cliente.direccion_fiscal = obj['direccion_fiscal'];
    cliente.mail = obj['mail'];
    cliente.telefono = obj['telefono'];
    return cliente;
  }
}
