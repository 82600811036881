
<div class="popupGeneralContainer">
  <label for="title" class="title">Nuevo Daily</label>
  <div class="body">
    <div class="doubleColumn">
      <div class="customInput" [class.fullWidth]="!admin">
        <label for="">Fecha Daily</label>
        <input required type="date" [(ngModel)]="fecha">
      </div>
      <div class="customInput" *ngIf="admin">
        <label>Usuario</label>
        <ng-select class="popupSelector" [items]="trabajadores" placeholder="Selecciona el usuario" bindLabel="fullName"
          bindValue="id" [(ngModel)]="idUser" (change)="getProyectosUser()">
        </ng-select>
      </div>
    </div>
    <div class="doubleColumn">
      <div class="customInput">
        <label>Proyecto</label>
        <ng-select class="popupSelector" [items]="proyectos" placeholder="Selecciona el proyecto" bindLabel="nombre"
          bindValue="id" [(ngModel)]="idProyecto" (change)="getProcesosProyecto()">
        </ng-select>
      </div>
      <div class="customInput">
        <label>Proceso</label>
        <ng-select class="popupSelector" [class.disabled]="procesos.length == 0" [items]="procesos"
          placeholder="Selecciona el proceso" bindLabel="nombre" [(ngModel)]="procesoSubProceso">
        </ng-select>
      </div>

    </div>
    <div class="doubleColumn">
      <div class="customInput">
        <label>Nombre tarea</label>
        <input required placeholder="Nombre de la tarea" [(ngModel)]="nombreTarea">
      </div>
      <div class="doubleColumn special">
        <div class="customInput">
          <label>Horas estimadas</label>
          <div class="porcentaje" *ngIf="false">
            <span>{{procesoSeleccionado.completado * 100}}%</span>
            <div class="contenedor">
              <div class="valor extra" [ngStyle]="{ width:  procesoSeleccionado.completado  * 100 + avance + '%' }">
              </div>
              <div class="valor" [ngStyle]="{ width:  procesoSeleccionado.completado  * 100 + '%' }"></div>
            </div>
          </div>
          <input required type="number" onkeydown="if(event.key==='.'){event.preventDefault();}"  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');" placeholder="Horas" [(ngModel)]="horasEstimadas" >
        </div>
        <div class="customInput">
          <label>Minutos estimados</label>
          <input required type="number" placeholder="Minutos" [(ngModel)]="minutosEstimados" (change)="limitMinutes()">
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <button (click)="crearDaily(false)" type="button">Cancelar</button>
    <button class="confirmar" (click)="crearDaily(true)" type="button">Guardar</button>
  </div>
</div>
