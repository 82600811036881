<div class="proyecto">
    <div class="titulo">{{proyectoSeleccionado.abreviatura}} | {{proyectoSeleccionado.nombre}}</div>
    <div class="horizontal">
      <div class="datosProyecto">
          <div class="linea">
              <div></div>
              <div class="comparacion">
                  <div class="leyenda">Estimado</div>
                  <div class="leyenda">Ejecutado</div>
              </div>
          </div>
          <div class="linea">
              <div>
                  <div class="indicador" [class.error]="proyectoSeleccionado.avanceReal-proyectoSeleccionado.avancePlanificado < 0"><img class="flechaVerde" src="/assets/icons/FlechaVerde.png"><img class="flechaRoja" src="/assets/icons/FlechaRoja.png">{{proyectoSeleccionado.avanceReal-proyectoSeleccionado.avancePlanificado | percent}}</div>
                  <div class="nombre">Avance</div>
              </div>
              <div class="comparacion">
                  <div>{{proyectoSeleccionado.avancePlanificado | percent}}</div>
                  <div>{{proyectoSeleccionado.avanceReal | percent}}</div>
              </div>
          </div>
          <div class="linea">
              <div>
                  <div class="indicador" [class.error]="(proyectoSeleccionado.avanceReal * proyectoSeleccionado.presupuesto) / (proyectoSeleccionado.costeReal) - proyectoSeleccionado.margenEstimado < 0"><img class="flechaVerde" src="/assets/icons/FlechaVerde.png"><img class="flechaRoja" src="/assets/icons/FlechaRoja.png">{{ ((proyectoSeleccionado.avanceReal * proyectoSeleccionado.presupuesto) / (proyectoSeleccionado.costeReal)- 1) / (proyectoSeleccionado.margenEstimado- 1)  - 1  | percent}}</div>
                  <div class="nombre">Margen</div>
              </div>
              <div class="comparacion">
                  <div>{{proyectoSeleccionado.margenEstimado - 1 | percent}}</div>
                  <div>{{(proyectoSeleccionado.avanceReal * proyectoSeleccionado.presupuesto) / (proyectoSeleccionado.costeReal) -1 |percent}}</div>
              </div>
          </div>
          <div class="linea">
              <div>
                  <div class="indicador presupuesto" [class.error]="proyectoSeleccionado.costePlanificado - proyectoSeleccionado.costeReal < 0"><img class="flechaVerde" src="/assets/icons/FlechaVerde.png"><img class="flechaRoja" src="/assets/icons/FlechaRoja.png"></div>
                  <div class="nombre">Presupuesto</div>
              </div>
              <div class="comparacion">
                  <div>{{proyectoSeleccionado.costePlanificado - proyectoSeleccionado.costeReal | currency:'EUR'}}</div>
              </div>
          </div>
      </div>
      <div *ngIf="seleccionado" class="empleados">
        <div class="recurso" *ngFor="let emp of empleados">
          <span class="foto"></span>
          <div class="nombre overflow"><span>{{emp.nombre}} {{emp.apellidos}}</span></div>
          <div class="porcentaje">
            <div class="numero" [class.sobrecarga]="emp.horas - emp.carga < 0">{{emp.carga/emp.horas|percent}}</div>
            <div class="barra">
              <div class="relleno" [class.sobrecarga]="emp.horas - emp.carga < 0" [style.width]="emp.carga/emp.horas*100+'%'"></div>
            </div>
          </div>
        </div>
      </div>      
      <div *ngIf="seleccionado" class="cerrar">
        <button (click)="cerrarProyecto()"></button>
      </div>
    </div>
</div>