<div class="proyecto">
    <div class="info">
        <span class="color"></span>
    <span class="titulo">{{estado.cliente.nombre}}</span>
    <span class="fecha">{{estado.fecha_creacion | date:'dd/MM/yyyy'}}</span>
    <div *ngIf="estado.proyecto!=null" class="proyectoAsignado">
        <span class="proyectoNombre">{{estado.proyecto.nombre }}</span>
        <span class="precio">{{estado.proyecto.presupuesto | currency:'EUR'}}</span>
    </div>
    <div *ngIf="estado.proyecto==null" class="proyectoNoAsignado">
        <span class="precio">{{estado.presupuesto | currency:'EUR'}}</span>
    </div>
</div>
<div class="temperatura">
    <img [class.apagado]="estado.temperatura < 60" src="assets/icons/sunrise (1).png">
    <div class="fondo">
        <div class="tamanyoTemp" [ngStyle]="{ height: estado.temperatura + '%' }">
            <div class="colorFondo"></div>
        </div>
    </div>
    <img [class.apagado]="estado.temperatura > 40" src="assets/icons/ice (1).png">
</div>
</div>