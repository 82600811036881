import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']
})
export class PagoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.frecuenciaSeleccionada = 'Mensual';
    this.planSeleccionado = this.planes[0];
  }
  frecuenciaSeleccionada =null;
planSeleccionado = null;
  planes = [
    {
      id: 1,
      nombre: 'Starter',
      precio: 5,
      precioMensual: 6,
      maxEmpleados: 5,
      minEmpleados: 1
    },
    {
      id: 2,
      nombre: 'Growth',
      precio: 10,
      precioMensual: 12,
      maxEmpleados: 10,
      minEmpleados: 6
    },
    {
      id: 3,
      nombre: 'Pro',
      precio: 15,
      precioMensual: 18,
      maxEmpleados: 25,
      minEmpleados: 11
    },
    {
      id: 4,
      nombre: 'Elite',
      precio: 20,
      precioMensual: 24,
      maxEmpleados: 50,
      minEmpleados: 26
    },
  ];

  datosTarjetaOk = false;

  numeroTargeta = "";
caducidad = "";
cvv = "";
cvvError = false;
caducidadError = false;
numeroTargetaError = false;
comprobarDatosTarjeta(){
  this.cvvError = false;
  this.caducidadError = false;
  this.numeroTargetaError = false;
  if(this.numeroTargeta.length == 16 && this.caducidad.length == 5 && this.cvv.length == 3){
    this.datosTarjetaOk = true;
  }else{
    this.datosTarjetaOk = false;
    if(this.numeroTargeta.length != 16){
      this.numeroTargetaError = true;
    }
    if(this.caducidad.length != 5){
      this.caducidadError = true;
    }
    if(this.cvv.length != 3){
      this.cvvError = true;
    }

  }
}

}
