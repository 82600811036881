import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { Clientes } from '../Interfaces/clientes';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class ClientesService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  createCliente(cliente: Clientes) {
    
    this.http
      .post(this.urlApi + 'api/cliente', cliente, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultUpdate",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.createCliente, args: [cliente] });
          this.checkStatusError(error);
        },
      });
  }

  
  updateCliente(cliente: Clientes) {
    
    this.http
      .put(this.urlApi + 'api/cliente/'+cliente.id, cliente, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultUpdate",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.updateCliente, args: [cliente] });
          this.checkStatusError(error);
        },
      });
  }

  getAllClientesByEmpresa(empresa,tag="crm") {
    
    this.http
      .get(this.urlApi + 'api/cliente?tag='+tag+'&empresa=' + empresa, this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("result",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getAllClientesByParameters, args: [empresa] });
          this.checkStatusError(error);
        },
      });
  }

  
  getAllClientesByParameters(num_devoluciones, num_pagina, empresa) {
    
    let query:string = "?empresa="+empresa+"&num_devoluciones="+num_devoluciones+"&num_pagina="+num_pagina;
    this.http
      .get(this.urlApi + 'api/cliente' + query, this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("result",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getAllClientesByParameters, args: [num_devoluciones, num_pagina, empresa] });
          this.checkStatusError(error);
        },
      });
  }
}
