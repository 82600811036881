import {Component,Inject,OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'popup-confirmacion-borrador',
  templateUrl: './popup-confirmacion-borrador.component.html',
  styleUrls: ['./popup-confirmacion-borrador.component.css'],
})
export class PopupConfirmacionBorradorComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PopupConfirmacionBorradorComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}
  descripcion = '';
  ngOnInit(): void {
    this.descripcion = this.modalData.descripcion;
  }

  confirmarGuardarBorrador(valor: boolean) {
    this.closePopup(valor);
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
}
