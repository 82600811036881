<div class="dashboardGeneralContainer">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'costes'"></sidebar>
    </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="Gastos"></app-header-bar>
      </div>
      <div class="dataBodyContainer">
        <div class="cabecera">
        <menu-navegacion [opcionPorDefecto]="'Mensual'" [opciones]="['Mensual','Todos']"></menu-navegacion>
<div class="agrupador"><button class="grafico-btn" (click)="verGrafico= !verGrafico"></button>
  <div class="buscadorContenedor"><input class="buscador" placeholder="Buscar..."></div>
</div>
</div>
        <div *ngIf="verGrafico" class="graficos">
          <apx-chart
          *ngIf="!actualizando"
            #chart
            [stroke]="chartOptions.stroke"
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [xaxis]="chartOptions.xaxis"
            [colors]="chartOptions.colors"
            [plotOptions]="chartOptions.plotOptions"
            [dataLabels]="chartOptions.dataLabels"
            [tooltip]="{ enabled: false }"
            [yaxis]="chartOptions.yaxis"
            [legend]="chartOptions.legend"
          ></apx-chart>
        </div>
        <div class="tabla">
          <div class="cabecera">
            <div class="titulo"></div>
            <div>Gastos totales</div>
            <div>Gastos directos</div>
            <div>Gastos indirectos</div>
            <div></div>
        </div>
        <ng-container *ngFor="let mes of meses; let i = index">
          <div (click)="seleccionarMes(i+1)" class="linea" [class.seleccionado]="mesSeleccionado == i+1">
            <div class="titulo">{{mes.nombre}} <ng-container *ngIf="i == mesActual && anyoSeleccionado == anyoActual">· <span>mes actual</span></ng-container></div>
            <div>{{mes.Directo+mes.Indirecto | currency:'EUR'}}</div>
            <div>{{mes.Directo| currency:'EUR'}}</div>
            <div>{{mes.Indirecto| currency:'EUR'}}</div>
            <div class="botones">
              <button *ngIf="!mesesCerrados[i]" class="btn nominas" (click)="generarNominas(i+1,$event)"></button>
              <button *ngIf="mesesCerrados[i]" class="btn close" (click)="abrirMes(i+1,$event)"></button>
              <button *ngIf="!mesesCerrados[i]" class="btn open" (click)="cerrarMes(i+1,$event)"></button>
              <button class="btn add" (click)="abrirPopUp(i+1,$event)"></button>
            </div>
        </div>
          
          <coste-mes *ngIf="anyoSeleccionado != null && mesSeleccionado==i+1 && !actualizando" [year]="anyoSeleccionado" [month]="mesSeleccionado"></coste-mes>
        </ng-container>
        

      </div> 
      </div>
    </div>
  </div>
  