import { Empresa } from './empresa';

export interface User {
  id:number;
  alta: string;
  apellidos: string;
  can_access: boolean;
  departamentos: Array<any>;
  empresas: Array<Empresa>;
  horas: number;
  horas_corto: number;
  horas_largo: number;
  horas_medio: number;
  porcentaje_corto: number;
  porcentaje_largo: number;
  porcentaje_medio: number;
  nombre: string;
  procesos: Array<any>;
  proyectos: Array<any>;
  username: string;
  profile: string;
  id_clockify: string;
  user_clockify: string;
}
export class User implements User {
  static converObjectToFront(obj) {
    let user = new User();
    user.id = obj['id'];
    user.alta = obj['alta'];
    user.apellidos = obj['apellidos'];
    user.can_access = obj['can_access'];
    user.departamentos = obj['departamentos'];
    user.empresas = obj['empresas'];
    user.horas = obj['horas'];
    user.horas_corto = obj['horas_corto'];
    user.horas_largo = obj['horas_largo'];
    user.horas_medio = obj['horas_medio'];
    if(obj['id_clockify'] != null && obj['id_clockify'] != 'null' && obj['id_clockify'] != undefined && obj['id_clockify'] != ''){
      user.id_clockify = obj['id_clockify'];
    }
    if(obj['user_clockify'] != null && obj['user_clockify'] != 'null' && obj['user_clockify'] != undefined && obj['user_clockify'] != ''){
      user.user_clockify = obj['user_clockify'];
    }

    user.porcentaje_corto = obj['porcentaje_corto'];
    user.porcentaje_largo = obj['porcentaje_largo'];
    user.porcentaje_medio = obj['porcentaje_medio'];

    user.nombre = obj['nombre'];
    user.procesos = obj['procesos'];
    user.proyectos = obj['proyectos'];
    user.username = obj['username'];
    user.profile = obj['profile'];

    return user;
  }
}
