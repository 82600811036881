<div class="confirmarGuardarBorradorGeneralContainer">
    <div class="confirmarGuardarBorradorConainer">
      <div class="bodyGuardarBorrador">
        <span>Añadir un nuevo gasto</span>
        <div class="formulario">
            <div class="linea">
                <div class="campo tam_1">
                    <label>Tipo de gasto</label>
                    <menu-navegacion [condensed]="true"  [opcionPorDefecto]="tipo" [opciones]="['Variable','Fijo','Periodo']" (emitOpcionSeleccionada)="opcionSeleccionadaTipo($event)"></menu-navegacion>
                </div>
            </div>                
            <div class="linea">
                <div class="campo tam_1">
                    <label>Imputación del gasto</label>
                    <menu-navegacion [condensed]="true"  [opcionPorDefecto]="directo" [opciones]="['Directo','Indirecto']" (emitOpcionSeleccionada)="opcionSeleccionadaImputar($event)"></menu-navegacion>
                </div>
                <div class="campo tam_1">
                  <label>Estado</label>
                  <menu-navegacion [condensed]="true"  [opcionPorDefecto]="estado" [opciones]="['Pendiente','Pagado']" (emitOpcionSeleccionada)="opcionSeleccionadaEstado($event)"></menu-navegacion>
                </div>
            </div>
            <div class="linea">
              <div class="campo tam_1">
                <label>Día</label>
                <input type="number" [(ngModel)]="day">
              </div>
              <div class="campo tam_1">
                  <label>Gasto</label>
                  <input type="number" [(ngModel)]="coste">
              </div>
            </div>
            <div class="linea">
              <div class="campo tam_1">
                  <label>Tipo de IVA</label>
                  <menu-navegacion [condensed]="true"  [opcionPorDefecto]="iva" [opciones]="['21%','10%','4%','Intercomunitario']" (emitOpcionSeleccionada)="opcionSeleccionadaIva($event)"></menu-navegacion>
              </div>
            </div>
            <div class="linea">
                <div class="campo tam_1">
                    <label>Familia</label>
                    <ng-select
                    [items]="familias"
                    placeholder="Selecciona la familia"
                    bindLabel="nombre"
                    bindValue="id"
                    [(ngModel)]="familia"
                  >
                  </ng-select>
                </div>
                <div *ngIf="proyectoOpcional" class="campo tam_1">
                  <label>Proyecto</label>
                  <ng-select
                  [items]="proyectos"
                  placeholder="Selecciona el proyecto"
                  bindLabel="nombre"
                  bindValue="id"
                  [(ngModel)]="proyecto"
                >
                </ng-select>
              </div>
              <div *ngIf="!proyectoOpcional" class="campo tam_1">
                <label>Concepto</label>
                <input [(ngModel)]="concepto">
              </div>
            </div>
            
            <div class="linea">                
              <div class="campo tam_1">
                <label>Nº Factura</label>
                <input type="text" [(ngModel)]="factura">
            </div>
            </div>
            
            <div *ngIf="proyectoOpcional" class="linea">
              <div class="campo tam_1">
                <label>Concepto</label>
                <input [(ngModel)]="concepto">
              </div>
              <div class="campo tam_1">
                <label>Adjuntar Factura</label>
                <input type="file" (change)="manejarCambioArchivo($event)" [(ngModel)]="documento">
              </div>
            </div>
        </div>
        <div class="bodyBotones">
            <button (click)="confirmarGuardarBorrador(false)" type="button">
              Cancelar
            </button>
          <button class="confirmar" (click)="guardarInfo()" type="button">
            Confirmar
          </button>
        </div>
      </div>
    </div>
  </div>
  