<div class="generalContainer">
  <div class="sideBarGeneralContainer">
    <sidebar [elementoActivo]="'proyectos'"></sidebar>
  </div>
  <div class="dataGeneralContainer">
    <div class="dataHeaderContainer">
      <app-header-bar titulo="Añadir proyecto" [cerrar]="true"></app-header-bar>
    </div>
    <div class="navegacionGeneralContainer">
      <menu-navegacion
        [opcionPorDefecto]="opcion"
        [deshabilitarSubirClick]="true"
        [opciones]="opciones"
        (emitOpcionSeleccionada)="irSeccion($event)"
      ></menu-navegacion>
      <div class="botonesControl"><button *ngIf="opcion !='Resumen'" (click)="avanzar()">Siguiente</button><button *ngIf="opcion =='Resumen'" (click)="checkData()">Guardar</button></div>
    </div>
    <ng-container *ngIf="opcion == 'Descripción'">
      <div class="contenedor">
      <div class="tarjeta">
        <span class="titulo"><span class="icon">ℹ️</span> Información básica</span>
        <div class="campos">
          <div class="nombre">
            <label for="nombreProyecto" [class.error]="errorSeccion && (nombreProyecto == '' || nombreProyecto == null)">Nombre del proyecto</label>
            <input
              type="text"
              [(ngModel)]="nombreProyecto"
            />
          </div>
          <div class="abreviatura">
            <label for="Abreviatura" [class.error]="errorSeccion && (abreviatura == '' || abreviatura == null)">Abreviatura</label>
            <input
              type="text"
              maxlength="4"
              [(ngModel)]="abreviatura"
            />
          </div>
          <div class="estado">
            <label for="estado">Cual es el estado del proyecto</label>
            <div class="toggle">
              <span [class.potencial]="!toggled" (click)="toggled=false">Potencial</span>
              <span [class.activo]="toggled" (click)="toggled=true">Activo</span>
            </div>
            
          </div>
          <div class="color">
            <label for="color" [class.error]="errorSeccion && (colorSeleccionado == '' || colorSeleccionado == null)">Selecciona un color para tu proyecto:</label>
            <div class="coloresContainer">
              <input
                type="text"
                [readonly]="true"
                class="color"
                *ngFor="let color of colores"
                [ngStyle]="{ 'background-color': color }"
                (click)="getColorPicker(color)"
                [class.seleccionado]="colorSeleccionado == color"
              />
            </div>
          </div>
          <div class="responsable">
            <label for="responsableCliente" [class.error]="errorSeccion && (selectedUsuario == '' || selectedUsuario == null)">Responsable del proyecto</label>
            <ng-select
              [items]="usuarios"
              bindLabel="nombre"
              bindValue="id"
              append="true"
              [(ngModel)]="selectedUsuario"
            >
            </ng-select>
          </div>
        </div>
        
      </div>
      <div class="tarjeta">
        <span class="titulo"><span class="icon">💼</span> Información cliente</span>
        <span class="btn_float" (click)="crearCliente()">Crear</span>
        <div class="campos">
          <div class="cliente">
            <label for="cliente" [class.error]="errorSeccion && (selectedCliente == '' || selectedCliente == null)">Cliente</label>
            <ng-select
              [items]="clientes"
              bindLabel="nombre"
              bindValue="id"
              [(ngModel)]="selectedCliente"
            >
            </ng-select>
          </div>
          <div class="sector">
            <label for="sector" [class.error]="errorSeccion && (sector == '' || sector == null)">Sector</label>
            <input type="text" [(ngModel)]="sector" />
          </div>
          <div class="persona">
            <label for="personaContacto" [class.error]="errorSeccion && (personaContacto == '' || personaContacto == null)">Persona de contacto</label>
            <input
              type="text"
              [(ngModel)]="personaContacto"
            />
          </div>
        </div>
      </div>
      <div class="tarjeta">
        <span class="titulo"><span class="icon">💶</span> Información gestión</span>
        <div class="campos">
          <div class="coste">
            <label for="coste" [class.error]="errorSeccion && (coste < 0 || coste == null)">Coste/día</label>
            <input type="number" [(ngModel)]="coste" />
          </div>
          <div class="beneficio">
            <label for="multiplicador" [class.error]="errorSeccion && (multiplicador < 0 || multiplicador == null)">Margen</label>
            <input
              type="number"
              [(ngModel)]="multiplicador"
            />
          </div>

          <div class="estado">
            <label for="estado">Traqueo del tiempo</label>
            <div class="toggle">
              <span [class.potencial]="!vincular_clockify" (click)="vincular_clockify=false">Manual</span>
              <span [class.activo]="vincular_clockify" (click)="vincular_clockify=true">Clockify</span>
            </div>
            
          </div>
          <div class="estado" *ngIf="vincular_clockify">
            <label for="estado">Proyecto de clockify</label>
            <div class="toggle">
              <span [class.activo]="proyecto_clockify" (click)="proyecto_clockify=true">Nuevo</span>
              <span [class.potencial]="!proyecto_clockify" (click)="proyecto_clockify=false">Existente</span>
            </div>
            
          </div>
          <div class="clockify" *ngIf="!proyecto_clockify">
            <label for="api" [class.error]="errorSeccion && (id_clockify == '' || id_clockify == null)">Id clockify</label
            >
            <input
              type="text"
              placeholder="Introduce tu api"
              [(ngModel)]="id_clockify"
            />
          </div>
        </div>
      </div>
    </div>
    </ng-container>
    <ng-container *ngIf="opcion == 'Fases y procesos'">
      
      <div class="contenedor">
        <div class="tarjeta">
          <span class="titulo"><span class="icon">📆</span> Duración del proyecto</span>
          <div class="campos">
            <div class="fechaIni">
              <label for="responsableCliente" [class.error]="errorSeccion && (fechaInicio == '' || fechaInicio == null)">Fecha de inicio</label>
              <input
                type="date"
                placeholder="Fecha de inicio"
                [max]="fechaFin"
                [(ngModel)]="fechaInicio"
              />
            </div>
            <div class="fechaFin">
              <label for="responsableCliente" [class.error]="errorSeccion && (fechaFin == '' || fechaFin == null)">Fecha de fin</label>
              <input
                type="date"
                placeholder="Fecha de fin"
                [min]="fechaInicio"
                [(ngModel)]="fechaFin"
              />
            </div>
            <div class="horasTot">
              <label for="responsableCliente">Horas totales</label>
              <input
                type="number"
                placeholder="Horas totales"
                min="0"
                disabled
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : 0"
                [(ngModel)]="horasTot"
              />
            </div>
          </div>
        </div>

        <div class="tarjeta">
          <span class="titulo"><span class="icon">🚧</span> Procesos y fases</span>
          <div class="cuerpo">
            
            <div class="procesosProyectoContainer">
              <div class="procesosProyectoHeader">
              </div>
              <div class="fasesGeneralContainer">
                <ng-container *ngFor="let fase of fases; let i = index">
                  <div class="fase">
                    <div>
                      <input [(ngModel)] = "fase.nombre" placeholder="Nombre de la fase" [class.error]="errorSeccion && (fase.nombre == '' || fase.nombre == null)">
                    </div>
                    <span class="info">{{fase.horas}} horas · {{(fase.horas / 8 * coste * multiplicador)|number:"0.0-2"}} Euros (€)</span>
                    <div class="botones">
                      <button (click)="eliminarFase(i)"><img src="/assets/icons/remove.png"></button>
                    </div>
                  </div>
                  <ng-container *ngIf="fase.procesos.length > 0">
                    <div class="proceso" *ngFor="let proceso of fase.procesos; let j = index">
                      <div class="procesoInputs">
                        <div class="nombre"><input [class.error]="errorSeccion && (proceso.nombre == '' || proceso.nombre == null)" [(ngModel)] = "proceso.nombre" placeholder="Nombre Proceso"></div>
                        <div class="fecha"><input [class.error]="errorSeccion && (proceso.fechaInicio == '' || proceso.fechaInicio == null)" [(ngModel)] = "proceso.fecha_ini" [min]="fechaInicio" [max]="fechaFin" type="date"></div>
                        <div class="fecha"><input [class.error]="errorSeccion && (proceso.fechaFin == '' || proceso.fechaFin == null)" [(ngModel)] = "proceso.fecha_fin" [min]="proceso.fecha_ini ?? fechaInicio" [max]="fechaFin" type="date"></div>
                        <div class="dias"><input [class.error]="errorSeccion && (proceso.dias == '' || proceso.dias == null || proceso.dias < 0)" (input)="calcularHora(fase)" [(ngModel)] = "proceso.dias" placeholder="Días" type="number"></div>
                      </div>
                      <div style="text-align:right;">{{(proceso.horas / 8 * coste * multiplicador)|number:"0.2-2"}} Euros (€)</div>
                      <div><button (click)="eliminarProceso(fase,j)"><img src="/assets/icons/remove.png"></button></div>
                    </div>
                  </ng-container>
                  <button (click)="addProceso(fase)">Crear nuevo proceso</button>
      
                </ng-container>
              </div>
              <div class="nuevaFase">
                <p *ngIf="fases.length == 0 ">Todavía no has creado ninguna fase. Para comenzar a editar tu proyecto crea una nueva fase.</p>
                <button type="button" (click)="addFase()">Añadir nueva fase</button>
              </div>
            </div>
          </div>
        </div>
        
      </div>


      
    </ng-container>
    <ng-container *ngIf="opcion == 'Resumen'">
      <div class="tarjetasHorasContainer">
        <div class="tarjetaSimple">
          <span>Horas presupuestadas</span>
          <span>{{ horasTot }} h</span>
        </div>
        <div class="tarjetaSimple">
          <span>Presupuesto total</span>
          <span>{{ horasTot / 8 * coste * multiplicador | number: "0.0-2" }} €</span>
        </div>
      </div>
      <div class="graficoListadoGeneralContainer">
        <div class="graficoGeneralContainer">
          <div class="graficoSubmenuContainer">
            <menu-navegacion
              [opcionPorDefecto]="'Corto Plazo'"
              [tipoMenu]="'submenu'"
              [opciones]="['Corto Plazo', 'Medio Plazo', 'Largo Plazo']"
              (emitOpcionSubmenuSeleccionada)="
                opcionSubMenuSeleccionada($event)
              "
            ></menu-navegacion>
            <div id="chart">
              <apx-chart
                #chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [yaxis]="chartOptions.yaxis"
                [xaxis]="chartOptions.xaxis"
                [legend]="chartOptions.legend"
                [colors]="chartOptions.colors"
                [grid]="chartOptions.grid"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="asignacionFasesGeneralContainer">
        <ng-container *ngFor="let fase of fases; let i = index">
          <div class="preProduccionContainer">
            <div class="titulo">
              <label for="preProduccion"><span>Fase {{i+1}}</span> {{ fase.nombre }}</label>
              <button (click)="fase.ocultar = !fase.ocultar">
                <img [class.girar]="fase.ocultar" src="/assets/icons/arrow.png" />
              </button>
            </div>
            <tabla
            *ngIf="!fase.ocultar"
              [etiquetas]="etiquetas"
              [integrantes]="usuarios"
              [procesos]="fase.procesos"
              (horas)="cambioHoras($event, fase)"
            ></tabla>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
