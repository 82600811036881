import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { resultHttp } from '../Interfaces/resultHttp';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class ImplicacionService extends BaseService {

  constructor(protected http: HttpClient) {
    super();
  }

  getImplicacionUserSemana(usuario) {
    this.http
      .get(
        this.urlApi + 'api/implicacion/'+usuario,
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getImplicacionUserSemana, args: [usuario] });
          this.checkStatusError(error);
        },
      });
  }

  getImplicacionSemana() {    
    this.http
      .get(this.urlApi + 'api/implicacion', this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getImplicacionSemana, args: [] });
          this.checkStatusError(error);
        },
      });
  }
  getWeekly(fecha=null,optimizar=false) {
    if(fecha==null){
      //guardar en fecha el lunes de esta semana
      let date = new Date();
      let day = date.getDay();
      let diff = date.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
      fecha = new Date(date.setDate(diff));
      //formato fecha para php con ceros a la izquierda
      let dd = String(fecha.getDate()).padStart(2, '0');
      let mm = String(fecha.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = fecha.getFullYear();
      fecha = yyyy + '-' + mm + '-' + dd;
      
    }
    let url = this.urlApi;
    if(optimizar){
      url = url + 'api/weekly/optimizar?fecha='+fecha;
    }else{
      url = url + 'api/weekly?fecha='+fecha;
    }
    this.http
      .get(url, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.getWeekly, args: [fecha,optimizar] });
          this.checkStatusError(error);
        },
      });
  }

  
  validarWeekly(fecha=null) {
    if(fecha==null){
      //guardar en fecha el lunes de esta semana
      let date = new Date();
      let day = date.getDay();
      let diff = date.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
      fecha = new Date(date.setDate(diff));
      //formato fecha para php con ceros a la izquierda
      let dd = String(fecha.getDate()).padStart(2, '0');
      let mm = String(fecha.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = fecha.getFullYear();
      fecha = yyyy + '-' + mm + '-' + dd;
      
    }
    let url = this.urlApi;
      url = url + 'api/weekly/validar';
    this.http
      .post(url,{fecha:fecha}, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.validarWeekly, args: [fecha] });
          this.checkStatusError(error);
        },
      });
  }

  addImplicacion(implicacion) {    
    this.http
      .post(this.urlApi + 'api/implicacion', implicacion, this.getHeader())
      .subscribe({
        next: (data) => {
          let retu = new resultHttp();
          retu.ok = true;
          retu.mensaje = data['message'];
          retu.status = data['status'];
          this.sendNextResult("resultUpdate",retu);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.addImplicacion, args: [implicacion] });
          this.checkStatusError(error);
        },
      });
  }
}
