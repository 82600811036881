import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/Services/login.service';
import { PopupService } from 'src/app/Services/popup.service';
import { PopupConfirmacionCerrarSesionComponent } from '../popup-confirmacion-cerrar-sesion/popup-confirmacion-cerrar-sesion.component';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.css'],
})
export class HeaderBarComponent implements OnInit {
  constructor(public popup: PopupService, public router: Router, public loginSE: LoginService) {}
  @Input('titulo') titulo: any = '';
  @Input('cerrar') cerrar: boolean = false;
  @Input('boton') boton: boolean = false;
  @Output('accionBoton') clickBoton:any = new EventEmitter(); 
  verSubmenu = false;
  localStorage;
  ngOnInit() {
    this.localStorage = localStorage;
  }
  filtro: string = '';
  borrarTexto() {
    this.filtro = '';
  }

  cerrarSesion() {
    this.popup.openPopup(PopupConfirmacionCerrarSesionComponent);
  }
  cerrarPop() {
    this.router.navigate([localStorage.getItem('lastRoute')]);
  }
  accionBoton(){
    this.clickBoton.emit();
  }
}
