<div class="tabla">
  <div class="cabecera">
    <div *ngFor="let etiqueta of etiquetas">{{ etiqueta }}</div>
  </div>
  <ng-container *ngFor="let proceso of procesos">
    <div>
      <div class="proceso">
        <span
          >{{ proceso.nombre }}
        </span>
      </div>
      <div>
        {{proceso.horas}} horas
      </div>
      <div>{{ (proceso.horas / horasTotales) * 100 | number: "1.0-1" }}%</div>
      <div>
        <ng-container *ngIf="proceso.factura">{{
          proceso.presupuesto
        }}</ng-container>
        <ng-container *ngIf="!proceso.factura">-</ng-container>
      </div>
      <div>
        <ng-select
          [items]="integrantes"
          [multiple]="true"
          placeholder="Selecciona los integrantes"
          bindLabel="nombre"
          bindValue="id"
          [(ngModel)]="proceso.empleados"
        ></ng-select>
      </div>
    </div>
  </ng-container>
</div>
