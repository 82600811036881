import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class CosteEmpresaService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  generarNominas(year,month){
    this.http
      .post(this.urlApi + 'api/coste/personal/'+year+'/'+month, null, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.generarNominas, args: [year,month] });
          this.checkStatusError(error);
        },
      });
  }
  guardarCoste(year,month,coste){
    this.http
      .post(this.urlApi + 'api/coste/'+year+'/'+month, coste, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.guardarCoste, args: [year,month,coste] });
          this.checkStatusError(error);
        },
      });
  }
  getCostesYear(year) {
    this.http
      .get(this.urlApi + 'api/coste/'+year, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getCostesYear, args: [year] });
          this.checkStatusError(error);
        },
      });
  }

  
  getCostesYearMonth(year,month) {
    this.http
      .get(this.urlApi + 'api/coste/'+year+'/'+month, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getCostesYearMonth, args: [year,month] });
          this.checkStatusError(error);
        },
      });
  }

  getCosteIndirectosEmpresaByYear(year) {
    this.http
      .get(this.urlApi + 'api/coste/indirecto/'+year, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.getCosteIndirectosEmpresaByYear, args: [year] });
          this.checkStatusError(error);
        },
      });
  }
  getCosteEmpresaByYear(year) {
    this.http
      .get(this.urlApi + 'api/costeEmpresa/'+year, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getCosteEmpresaByYear, args: [year] });
          this.checkStatusError(error);
        },
      });
  }
  postCosteEmpresaIndirectoByYear(year,costes) {
    this.http
      .post(this.urlApi + 'api/coste/indirecto/'+year, costes, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.postCosteEmpresaIndirectoByYear, args: [year,costes] });
          this.checkStatusError(error);
        },
      });
  }
  postCosteEmpresaByYear(year,costes) {
    this.http
      .post(this.urlApi + 'api/costeEmpresa/'+year, costes, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.postCosteEmpresaByYear, args: [year,costes] });
          this.checkStatusError(error);
        },
      });
  }
  
  convertirArrayMesesEnObjeto(array){
    let costes = {
      enero: array[0],
      febrero: array[1],
      marzo: array[2],
      abril: array[3],
      mayo: array[4],
      junio: array[5],
      julio: array[6],
      agosto: array[7],
      septiembre: array[8],
      octubre: array[9],
      noviembre: array[10],
      diciembre: array[11]
    }
    return costes;
  }
  
  convertirObjetoMesesEnArray(costes){
    let array = [];
    array[0] = costes.enero;
    array[1] = costes.febrero;
    array[2] = costes.marzo;
    array[3] = costes.abril;
    array[4] = costes.mayo;
    array[5] = costes.junio;
    array[6] = costes.julio;
    array[7] = costes.agosto;
    array[8] = costes.septiembre;
    array[9] = costes.octubre;
    array[10] = costes.noviembre;
    array[11] = costes.diciembre;
    return array;
  }
}
