import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { PopUpContratoComponent } from 'src/app/Components/pop-up-contrato/pop-up-contrato.component';
import { PopupConfirmacionBorradorComponent } from 'src/app/Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { ContratoService } from 'src/app/Services/Api/contrato.service';
import { DepartamentosService } from 'src/app/Services/departamentos.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupService } from 'src/app/Services/popup.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-Nuevo-Integrante',
  templateUrl: './Nuevo-Integrante.component.html',
  styleUrls: ['./Nuevo-Integrante.component.css'],
})
export class NuevoIntegranteComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  nombre: string = '';
  apellidos: string = '';
  horario: number;
  departamento = [];
  username: string = '';

  vacioNombre: boolean = false;
  vacioTipoCoste: boolean = false;
  vacioApellidos: boolean = false;
  vacioHorario: boolean = false;
  vacioDepartamento: boolean = false;
  vacioUsername: boolean = false;
  vacioCoste: boolean = false;
  vacioCosteAnual: boolean = false;
  departamentos = [];
  cargando = false;

  @Output() estado = new EventEmitter<boolean>();

  constructor(
    public popup: PopupService,
    public router: Router,
    private loaderSE: LoaderService,
    protected userService: UserService,
    private notificacionesSE: NotificacionesService,
    protected departamentosSE: DepartamentosService,
    private activatedRoute: ActivatedRoute,
    private contratoSE: ContratoService,
  ) {
    super();
  }
  contratos = [];
editar = false;
titulo = "Añadir integrante";
  ngOnInit(): void {
    // if(localStorage.getItem("login") === "needLogin"){
    //   this.router.navigate(["/login"]);
    // }
    this.loaderSE.open();

    //si recibe el id del empelado por parametro get en la url
     if (this.activatedRoute.snapshot.params['id']) {
      this.editar = true;
      this.cargando = true;
      this.titulo = "Editar integrante";
      this.userService.clearResult();
    this.userService.getUserEmpresaById(this.activatedRoute.snapshot.params['id']);
    this.userService
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual2))
      .subscribe((value1) => {
        if (value1 == null) {
          return;
        }
        console.log(value1);
        this.nombre = value1.nombre;
        this.apellidos = value1.apellidos;
        this.horario = value1.horas;
        this.username = value1.username;
        this.coste = value1.coste;
        this.costeAnual = value1.coste_anual;
        value1.departamentos.forEach(departamento => {
          this.departamento.push(departamento.id);
        });
        this.tipoCoste = value1.tipo_coste;
        this.cargando = false;
        this.canAccess = value1.can_access;
        this.contratos = value1.contratos;
        this.ordenarContratos()
        this.loaderSE.closeContador();
        this._unsubscribeIndividual2.next('');
      });

     }
    this.departamentosSE.getDepartamentosByEmpresa();

    this.departamentosSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.departamentos = value;
        this.loaderSE.closeContador();
        this._unsubscribeIndividual.next('');
      });
  }

  cerrarAddIntegrante() {
    this.router.navigate([localStorage.getItem('lastRoute')]);
  }
  tipoCoste = null;
  canAccess = true;;
coste = null;
costeAnual = null;
  enviarDatos() {
    let datos = {
      nombre: this.nombre,
      apellidos: this.apellidos,
      horas: this.horario,
      departamentos: this.departamento,
      username: this.username,
      coste: this.coste,
      costeAnual: this.costeAnual,
      tipoCoste: this.tipoCoste,
      can_access: this.canAccess
    };
    if(this.editar){
      this.userService.editUser(this.activatedRoute.snapshot.params['id'],datos);
    }else{
      this.userService.createUser(datos);
    }
    this.loaderSE.open();
    this.userService
      .getResultUpdate()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.notificacionesSE.showFeedBack(value.message);
        this._unsubscribeIndividual.next('');
      });
  }

  comprobarDatos() {
    var valido = true;
    if (this.nombre == '') {
      this.vacioNombre = true;
      valido = false;
    } else {
      this.vacioNombre = false;
    }

    if (this.apellidos == '') {
      this.vacioApellidos = true;
      valido = false;
    } else {
      this.vacioApellidos = false;
    }

    if (this.horario > 0) {
      this.vacioHorario = false;
    } else {
      this.vacioHorario = true;
      valido = false;
    }
    
    if (this.tipoCoste == null) {
      this.vacioTipoCoste = true;
      valido = false;
    } else {
      this.vacioTipoCoste = false;
    }

    if (
      this.departamento.length == 0 
    ) {
      this.vacioDepartamento = true;
      valido = false;
    } else {
      this.vacioDepartamento = false;
    }

    if (this.username == '' || this.username == 'Selecciona un username') {
      this.vacioUsername = true;
      valido = false;
    } else {
      this.vacioUsername = false;
    }

    if (this.coste == null) {
      this.vacioCoste = true;
      valido = false;
    } else {
      this.vacioCoste = false;
    }

    if (this.costeAnual == null) {
      this.vacioCosteAnual = true;
      valido = false;
    } else {
      this.vacioCosteAnual = false;
    }

    if (valido == true) {
      this.enviarDatos();
    }
  }
  ordenarContratos(){
    this.contratos.sort((a, b) => {
      if (a.inicio > b.inicio) {
        return 1;
      }
      if (a.inicio < b.inicio) {
        return -1;
      }
      return 0;
    });
  }
  addContrato(){
    this.popup.openPopup(PopUpContratoComponent,{
      nombre: this.nombre + " " + this.apellidos,
      empleado: this.activatedRoute.snapshot.params['id']
    });    
        this.popup
        .returnData()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          if (value.returnValue) {
            value.contrato.coste_hora = value.contrato.costeHora;
            this.contratos.push(value.contrato);
            this.ordenarContratos();
          }
          this.popup.clearData(); 
          this._unsubscribeIndividual.next('');
        });
  }
  eliminarContrato(id){
    this.popup.openPopup(PopupConfirmacionBorradorComponent,{descripcion: '¿Eliminar el contrato?'});
    this.popup.returnData().pipe(takeUntil(this._unsubscribeIndividual)).subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValue) {
        this.contratoSE.eliminarContrato(id);
        this.loaderSE.open();
        this.contratoSE.getResult().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
          if (value == null) {
            return;
          }
          this.loaderSE.closeContador();
          this.contratoSE.clearResult();
          this._unsubscribeIndividual2.next('');
          this.contratos = this.contratos.filter(item => item.id !== id);
        });
      }
      this.popup.clearData();
      this._unsubscribeIndividual.next('');
    });
  }
  vaciarCampos() {
    this.nombre = '';
    this.apellidos = '';
    this.horario = undefined;
    this.departamento = [];
    this.username = 'Selecciona un username';
  }
}
