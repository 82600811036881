import { Clientes } from './clientes';
import { Empresa } from './empresa';
import { Fase } from './fase';
import { User } from './user';

export interface Proyectos {
  abreviatura: string;
  cliente: Clientes;
  contacto: string;
  empresa: Empresa;
  estado: number;
  etiqueta: string;
  fases: Array<Fase>;
  fecha_fin: string;
  fecha_ini: string;
  horas: number;
  id: number;
  nombre: string;
  potencial: boolean;
  presupuesto: number;
  responsable: User;
  sector: string;
}
export class Proyectos implements Proyectos {
  static converObjectToFrontFull(obj) {
    let proyecto = new Proyectos();
    proyecto.abreviatura = obj['abreviatura'];
    proyecto.cliente = Clientes.converObjectToFront(obj['cliente']);
    proyecto.contacto = obj['contacto'];
    proyecto.empresa = Empresa.converObjectToFront(obj['empresa']);
    proyecto.estado = obj['estado'];
    proyecto.etiqueta = obj['etiqueta'];
    proyecto.fases = obj['fases'];
    proyecto.fecha_ini = obj['fecha_ini'];
    proyecto.fecha_fin = obj['fecha_fin'];
    proyecto.horas = obj['horas'];
    proyecto.id = obj['id'];
    proyecto.nombre = obj['nombre'];
    proyecto.potencial = obj['potencial'];
    proyecto.presupuesto = obj['presupuseto'];
    proyecto.responsable = User.converObjectToFront(obj['responsable']);
    proyecto.sector = obj['sector'];

    return proyecto;
  }
  static converObjectToFront(obj) {
    let proyecto = new Proyectos();
    proyecto.abreviatura = obj['abreviatura'];
    proyecto.cliente = obj['cliente'];
    proyecto.contacto = obj['contacto'];
    proyecto.empresa = obj['empresa'];
    proyecto.estado = obj['estado'];
    proyecto.etiqueta = obj['etiqueta'];
    proyecto.fases = obj['fases'];
    proyecto.fecha_ini = obj['fecha_ini'];
    proyecto.fecha_fin = obj['fecha_fin'];
    proyecto.horas = obj['horas'];
    proyecto.id = obj['id'];
    proyecto.nombre = obj['nombre'];
    proyecto.potencial = obj['potencial'];
    proyecto.presupuesto = obj['presupuseto'];
    proyecto.responsable = obj['responsable'];
    proyecto.sector = obj['sector'];

    return proyecto;
  }
}
