import {Component,Inject,OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DailyService } from 'src/app/Services/daily.service';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/Services/loader.service';

@Component({
  selector: 'app-validar-daily',
  templateUrl: './validar-daily.component.html',
  styleUrls: ['./validar-daily.component.css']
})
export class ValidarDailyComponent
extends UnsubscribesDestroy
implements OnInit {
  constructor(
    public loaderSE: LoaderService,
    private dailySE: DailyService,
    public dialogRef: MatDialogRef<ValidarDailyComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {super();}
  descripcion = '';
  id;
  daily;
  horas;
  avance;
  horasEstimadas;
  avanceEstimado;
  tipo = 'No, corregido';
  isDataLoaded: boolean = false;
  ngOnInit(): void {
    this.descripcion = this.modalData.descripcion;
    this.id = this.modalData.id;
    this.loaderSE.open();
    this.dailySE.getDaily(this.id);
    this.dailySE.getResultIndividual()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if(value==null){return;}
      this.daily = value;

      this.avance = this.daily.avance_teorico*100;
      this.avanceEstimado = this.avance * this.daily.porcentaje;
      this.horas = this.daily.horas_reportadas;
      this.horasEstimadas = this.daily.horas_estimadas;
      this.loaderSE.closeContador();
      this.isDataLoaded = true;
    });

  }

  validarDaily(){
    console.log(this.tipo)/*
    this.tipo == "Sí, completado" ? this.dailySE.validateDailyId(this.id,true,this.horas,this.avance/100.0) :  this.dailySE.validateDailyId(this.id,false,this.horas,this.avance/100.0);*/
    this.dailySE.validateDailyId(this.id,true,this.horas,this.avance/100.0);

      this.loaderSE.open();
      this.dailySE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.dailySE.clearResultUpdate();
        this._unsubscribeIndividual2.next('');
        this.closePopup(true);
      }
    );
  }
  confirmarGuardarBorrador(valor: boolean) {
    this.closePopup(valor);
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  opcionSeleccionadaTipo(valor) {
    console.log(valor)
    this.tipo = valor;
  }
}
