import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { Proyectos } from '../Interfaces/proyectos';
import { resultHttp } from '../Interfaces/resultHttp';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class ProyectosService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  getAllProyectos(devoluciones, pag, tag=null, presupuestos = false) {    
    let url = this.urlApi + 'api/proyecto?num_devoluciones=' + devoluciones + '&num_pagina=' + pag;
    if(tag!=null){
      url += '&tag=' + tag;
    }
    if(presupuestos){
      url += '&presupuestos=true';
    }
    this.http
      .get(url, this.getHeader())
      .subscribe({
        next: (data) => {
          let proyecto = new Proyectos();
          let proyectos = [];
          proyectos.push(data);
          proyectos.forEach((element) => {
            proyecto = element;
            this.sendNextResult("result",proyecto);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getAllProyectos, args: [devoluciones, pag, tag, presupuestos] });
          this.checkStatusError(error);
        },
      });
  }

  getProyecto(id) {    
    this.http
      .get(
        this.urlApi + 'api/proyecto/' + id,
        this.getHeader()
      )
      .subscribe({
        next: (data) => {
          let proyectos = [];
          proyectos.push(data);
          this.sendNextResult("result",data);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getProyecto, args: [id] });
          this.checkStatusError(error);
        },
      });
  }

  addProyecto(proyecto) {    
    this.http
      .post(this.urlApi + 'api/proyecto', proyecto, this.getHeader())
      .subscribe({
        next: (data) => {
          let retu = new resultHttp();
          retu.ok = true;
          retu.mensaje = data['message'];
          retu.status = data['status'];
          retu.data = data['data'];
          this.sendNextResult("resultUpdate",retu);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.addProyecto, args: [proyecto] });
          this.checkStatusError(error);
        },
      });
  }

  editProyecto(id, proyecto) {
    this.http
      .put(this.urlApi + 'api/proyecto/' + id, proyecto, this.getHeader())
      .subscribe({
        next: (data) => {
          let retu = new resultHttp();
          retu.ok = true;
          retu.mensaje = data['message'];
          retu.status = data['status'];
          this.sendNextResult("resultUpdate",retu);
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.editProyecto, args: [id,proyecto] });
          this.checkStatusError(error);
        },
      });
  }
}
