<div class="confirmarGuardarBorradorGeneralContainer">
  <div class="confirmarGuardarBorradorConainer">
    <div class="bodyPopUp">
      <div class="cabecera">
      <span>Generar contrato para {{nombre}}</span>
    </div>
    <div class="formulario">
      <label>Fecha alta</label>
      <input [(ngModel)]="contrato.inicio" type="date">
      <label>Fecha baja</label>
      <input [(ngModel)]="contrato.fin" type="date">
      <label>Coste/hora</label>
      <input [(ngModel)]="contrato.costeHora" type="number">
      <label>Horas semanales</label>
      <input [(ngModel)]="contrato.horas" type="number">
    </div>
      <div class="botones">
        <button (click)="crearContrato()"><img src="/assets/icons/check.svg"></button>
        <button (click)="closePopup(false)"><img src="/assets/icons/x.svg" style="width: 30px;margin-top: 3px;"></button>
      </div>
    </div>
  </div>
</div>
