import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DailyService } from 'src/app/Services/daily.service';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';


@Component({
  selector: 'app-popup-edit-daily',
  templateUrl: './popup-edit-daily.component.html',
  styleUrls: ['./popup-edit-daily.component.css']
})
export class PopupEditDailyComponent
extends UnsubscribesDestroy implements OnInit {
  tipo = 'No completado';
  categorias = [];
  constructor(public loaderSE: LoaderService, private dailySE: DailyService,public dialogRef: MatDialogRef<PopupEditDailyComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { super(); }

  ngOnInit(): void {
    this.daily.id = this.modalData.daily.id;
    this.daily.nombre_tarea = this.modalData.daily.nombre_tarea;
    this.daily.porcentaje = (this.modalData.daily.porcentaje*100).toFixed(0);
    this.daily.completada = this.modalData.daily.completada;
    this.daily.motivo = this.modalData.daily.motivo;
    if(this.modalData.daily.categoria != null){
      this.daily.categoria = this.modalData.daily.categoria.id;
    }
    this.dailySE.getCategoriasDaily();
    this.loaderSE.open();
    this.dailySE.getResult().pipe(takeUntil(this._unsubscribeIndividual)).subscribe((value) => {
      if (value == null) {
        return;
      }
      this.categorias = value;
      this._unsubscribeIndividual.next('');
      this.dailySE.clearResult();
      this.loaderSE.closeContador();
    });
    this.daily.completada ? this.tipo = "Completado" : this.tipo = 'No completado';
  }
  daily = {
    id: null,
    nombre_tarea: null,
    porcentaje: null,
    completada: null,
    motivo: null,
    categoria:null
  };
  idUser;
  editarDaily(valor:boolean){
    if (valor) {
      this.tipo == "Completado" ? this.daily.completada = true : this.daily.completada = false;
      if(this.daily.completada){
        this.daily.porcentaje = 1;
      }else{
        this.daily.porcentaje = this.daily.porcentaje/100;
      }
      this.daily.porcentaje == 1 || this.daily.porcentaje == 100 ? this.daily.completada = true : this.daily.completada = false;

      this.dailySE.editarDaily(this.daily.id,this.daily);
      this.loaderSE.open();
          this.dailySE.getResultUpdate().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
            if (value == null) {
              return;
            }
            this.loaderSE.closeContador();
            this.dailySE.clearResultUpdate();
            this._unsubscribeIndividual2.next('');
            this.closePopup(true);
          }
          );
    }else{
      this.closePopup(false);
    }
  }

  closePopup(returnValue?:any){
    let p={returnValue:returnValue};
    this.dialogRef.close(p);
  }

  opcionSeleccionadaTipo(valor) {
    this.tipo = valor;
  }
}
