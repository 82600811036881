import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenManagmentService {
  protected static _refreshTokenComplete=new Subject<any>();
  protected static _startTokenRefresh=new Subject<any>();
  protected static _tokenLost=new Subject<any>();

  constructor(){}

  /** Acciona el listener de cuando pierdes el token */
  public static tokenLost(){
    localStorage.removeItem('token');
    this._tokenLost.next(true);
  }
  /** Acciona el listener de cuando tienes que refrescar el token */
  public static startTokenRefresh(){ this._startTokenRefresh.next(true); }
  /** Acciona el listener de cuando COMPLETA el refrescar token */
  public static completeRefreshToken(){ this._refreshTokenComplete.next(true); }

  /**
   * Escuchas el evento para cuando haya que refrescar el token
   * @returns {Subject}
   */
  public static listenerStartRefreshToken(){ return this._startTokenRefresh; }
  /**
   * Escucha el evento para cuando se COMPLETA el refrescar token
   * @returns {Subject}
   */
  public static listenerRefreshToken(){ return this._refreshTokenComplete; }
  /**
   * Escucha el evento para cuando pierde el TOKEN (El cual habrá que navegar a la página de inicio y enviar una notificación)
   * @returns {Subject}
   */
  public static listenerTokenLost(){ return this._tokenLost; }

  /**
   * Te devuelve el tiempo de expiración del token
   * @param {string} token 
   * @returns {Ojbect}
   */
  public static parseJwt(token:any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
  /**
   * Te devuelve el token completamente parseado a un objeto manejable
   * @param {string} token 
   * @returns {Object}
   */
  public static getParseToken(token:any){
    var base64Url = token.split('.')[1];
    return JSON.parse(atob(base64Url));
  }
}
