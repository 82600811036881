import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tarjeta-info',
  templateUrl: './tarjeta-info.component.html',
  styleUrls: ['./tarjeta-info.component.css']
})
export class TarjetaInfoComponent implements OnInit {

  constructor() { }
@Input() sobrecarga: boolean;
@Input() cantidad: number;
@Input() notificacionValor: number;
@Input() notificacion: boolean;
@Input() texto: string;
@Input() money: boolean = false;

  ngOnInit(): void {
  }

}
