import { Component, OnInit } from '@angular/core';
import { sha256 } from 'js-sha256';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { UserService } from 'src/app/Services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ajustes',
  templateUrl: './ajustes.component.html',
  styleUrls: ['./ajustes.component.css'],
})
export class AjustesComponent extends UnsubscribesDestroy implements OnInit {
  constructor(
    protected userSE: UserService,
    protected loaderSE: LoaderService,
    protected notificacionesSE: NotificacionesService
  ) {
    super();
  }

  user;
  password;
  password2;
  ngOnInit() {
    this.loaderSE.open();
    this.userSE.getMyUser();
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        console.log(value);
        this.user = value;
        this.perfilImg = environment.urlImg + this.user.profile;
        this._unsubscribeIndividual.next('');
        this.loaderSE.closeContador();
        this.userSE.clearResult();
      });
  }
  perfilImg = '';
  vacioNombre = false;
  vacioApellidos = false;
  vacioHorario = false;
  vacioPass = false;
  comprobarDatos() {
    var valido = true;
    if (this.user.nombre == '') {
      this.vacioNombre = true;
      valido = false;
    } else {
      this.vacioNombre = false;
    }

    if (this.user.apellidos == '') {
      this.vacioApellidos = true;
      valido = false;
    } else {
      this.vacioApellidos = false;
    }

    if (this.user.horas > 0) {
      this.vacioHorario = false;
    } else {
      this.vacioHorario = true;
    }

    if(this.password != this.password2){
      this.vacioPass = true;
      valido = false;
      this.notificacionesSE.showError('Las contraseñas no coinciden');
    }else{
      this.vacioPass = false;
    }

    if (valido == true) {
      this.enviarDatos();
    }else{
      this.notificacionesSE.showError('Faltan campos por completar');
    }
  }
  resizeImg = false;
  imagenNueva = false;
  recogerImagen(mensaje) {
    this.imagenNueva = true;
    this.perfilImg = mensaje;
    console.log(mensaje);
    this.cerrar();
  }
  cerrar() {
    this.resizeImg = false;
  }
  enviarDatos() {
    let user = {};
    if (this.imagenNueva) {
      if(this.password != null || this.password != undefined || this.password != ''){
        user = {
          nombre: this.user.nombre,
          apellidos: this.user.apellidos,
          horas: this.user.horas,
          id_clockify: this.user.id_clockify,
          profile: this.perfilImg,
          password: sha256(this.password),
        };
      }else{
        user = {
          nombre: this.user.nombre,
          apellidos: this.user.apellidos,
          horas: this.user.horas,
          id_clockify: this.user.id_clockify,
          profile: this.perfilImg,
        };
      }
    } else {
      if(this.password != null && this.password != undefined && this.password != ''){
        user = {
          nombre: this.user.nombre,
          apellidos: this.user.apellidos,
          horas: this.user.horas,
          id_clockify: this.user.id_clockify,
          password: sha256(this.password),
        };
      }else{
        user = {
          nombre: this.user.nombre,
          apellidos: this.user.apellidos,
          horas: this.user.horas,
          id_clockify: this.user.id_clockify,
        };
      }
    }
    if(this.password != null && this.password != undefined && this.password != ''){
      user['password'] = sha256(this.password);
    }

    this.loaderSE.open();
    this.userSE.editUserMe(user);
    this.userSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this._unsubscribeIndividual.next('');
        this.loaderSE.closeContador();
        this.userSE.clearResult();
        this.notificacionesSE.showFeedBack(value.message);
      });
  }
}
