import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { FinanzasService } from 'src/app/Services/finanzas.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupService } from 'src/app/Services/popup.service';

@Component({
  selector: 'app-popup-hito-pago-cotizador',
  templateUrl: './popup-hito-pago-cotizador.component.html',
  styleUrls: ['./popup-hito-pago-cotizador.component.css']
})
export class PopupHitoPagoCotizadorComponent extends UnsubscribesDestroy implements OnInit {

  constructor(
    public popup: PopupService,
    private notificacionesSE: NotificacionesService,
    public loaderSE: LoaderService, private finanzasSE: FinanzasService,public dialogRef: MatDialogRef<PopupHitoPagoCotizadorComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { super(); }


    fases = [];
  ngOnInit(): void {
    this.fases = this.modalData.fases;
    this.presupuestoRestante = this.modalData.presupuestoRestante;
    if(this.modalData.id != null){
      this.id = this.modalData.id;
      this.fecha = this.modalData.fecha.split('T')[0];
      this.cantidad = this.modalData.cantidad;
      this.concepto = this.modalData.concepto;
      this.fase = this.modalData.cantidad;
      this.proceso = this.modalData.concepto;      
      this.procesos = this.fase.procesos;
    }
    
  }

  cambioFase(){
    this.procesos = this.fase.procesos;
  }
  procesos = [];

  id = null;
  fecha;
  cantidad;
  concepto;
  fase = null;
  proceso = null;
  crearFactura(valor:boolean){
    if (valor) {
      let fecha = this.fecha;
      if(this.fase!=null){
        fecha = this.fase.fechaFin;
      }else{
        this.proceso = null;
      }
      let hito = {
        id: this.id,
        fecha: fecha,
        importe: this.cantidad,
        concepto: this.concepto,
        fase: this.fase,
        proceso: this.proceso,
      }
      this.closePopup(true,hito);
    }else{
      this.closePopup(false);
    }
  }

  closePopup(returnValue?:any,hito=null){
    let p={returnValueFactura:returnValue,hito:hito};
    this.dialogRef.close(p);
  }

  presupuestoRestante = 0;
  



  
}
