import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tarjeta-proyecto',
  templateUrl: './tarjeta-proyecto.component.html',
  styleUrls: ['./tarjeta-proyecto.component.css']
})
export class TarjetaProyectoComponent implements OnInit {

  @Input() proyecto: any;
  constructor() { }

  environment;
  ngOnInit(): void {
    this.environment = environment;
  }

}
