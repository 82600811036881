import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { EmpresasService } from 'src/app/Services/empresas.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { LoginService } from 'src/app/Services/login.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupService } from 'src/app/Services/popup.service';
import { HttpClient} from '@angular/common/http';
import { CrmService } from 'src/app/Services/crm.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { ClienteCRMService } from 'src/app/Services/cliente-crm.service';
import { PopUpCRMComponent } from 'src/app/Components/pop-up-crm/pop-up-crm.component';
import { PopupConfirmacionBorradorComponent } from 'src/app/Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.css']
})
export class CrmComponent 
extends UnsubscribesDestroy
implements OnInit{  
  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public router: Router,
    public loaderSE: LoaderService,
    public empresaSE: EmpresasService,
    protected crmSE: CrmService,
    protected clienteCRMSE: ClienteCRMService,
    private notificacionesSE: NotificacionesService,
    public loginSE: LoginService,
    protected popup: PopupService,
    protected http: HttpClient
  ) {
    super();
  }
  nombres = [];
  crm = [];
  pageSize = 10;
  pagina = 0;
  cantidadPaginas = 1;
  clockify = false;
  ngOnInit(): void {
    if (
      localStorage.getItem('token') === null &&
      localStorage.getItem('login') != 'login'
    ) {
      this.loginSE.desconectar();
    }
    localStorage.setItem('lastRoute', this.router.url);
    this.obtenerCRM();
  }
  obtenerCRM(){
    this.loaderSE.open();
    this.crmSE.getAllCRMByEmpresa(localStorage.getItem('numEmpresa'));
    this.crmSE.getResult().pipe(takeUntil(this._unsubscribeIndividual)).subscribe((data) => {
      if (data != null) {
        console.log(data);
        this.crm = data;
        let cont = 0;
        data.forEach(element => {
          this.nombres.push(cont.toString());
          cont++;
        });
        this.loaderSE.closeContador();
        this._unsubscribeIndividual.next('');
        this.crmSE.clearResult();
      }
    });
  }
crearProyecto = null;
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      let proyecto:any = event.previousContainer.data[event.previousIndex];
      let indice = Number(event.container.id);

      
      if(this.crm[indice].no_cierre){
        this.popup.openPopup(PopupConfirmacionBorradorComponent, {
          descripcion: '¿Desea marcar como no cierre?',
        });
        this.popup
          .returnData()
          .pipe(takeUntil(this._unsubscribeIndividual2))
          .subscribe((value) => {
            if (value == null) {
              return;
            }
            if (!value.returnValue) {
              return;
            }
            this.editarPosicionCRM(proyecto,indice,event);
            this.popup.clearData();
            this._unsubscribeIndividual2.next('');
          });
        }else{
          this.editarPosicionCRM(proyecto,indice,event);
        }
    }
  }


  editarPosicionCRM(proyecto,indice,event){
    this.clienteCRMSE.updateClienteCRM(proyecto.id,{soloCRM:this.crm[indice].id});
      this.loaderSE.open();
      this.clienteCRMSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((value) => {
          console.log(value);
          if (value == null) {
            return;
          }
          console.log("PEDRO");
          this.loaderSE.closeContador();
          this.notificacionesSE.showFeedBack(value.message);
          this.clienteCRMSE.clearResultUpdate();
          this._unsubscribeIndividual.next('');
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex,
          );          
          console.log(proyecto);
          if(this.crm[indice].cierre && proyecto.proyecto != null){
            this.popup.openPopup(PopupConfirmacionBorradorComponent, {
              descripcion: '¿Editar la propuesta para pasar a real?',
            });
            this.popup
              .returnData()
              .pipe(takeUntil(this._unsubscribeIndividual))
              .subscribe((value) => {
                if (value == null) {
                  return;
                }
                if (value.returnValue) {
                  this.router.navigate(['/presupuestos/cerrar/' + proyecto.proyecto.id]);
                }
                this.popup.clearData();
                this._unsubscribeIndividual.next('');
              });
            }            
            return;
          
        });
      this.clienteCRMSE
        .getResultUpdateError()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.loaderSE.closeContador();
          this.notificacionesSE.showError(value.message);
          this.clienteCRMSE.clearResultUpdateError();
          this._unsubscribeIndividual.next('');
        });
      }
  
  addClienteCRM(idCRM){
    //abrir popup para agregar cliente
    this.popup.openPopup(PopUpCRMComponent,{
      idCRM: idCRM
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValuePropuesta) {
          this.obtenerCRM();
          this.popup.clearData();
          this._unsubscribeIndividual2.next('');
        }
      }
      );
  }

  editClienteCRM(estado){
    //abrir popup para editar cliente
    let datos = {
      id:estado.id,
      idCliente:estado.cliente.id,
      idProyecto:null,
      temperatura:estado.temperatura,
      presupuesto:estado.presupuesto
    };
    if(estado.proyecto != null)
      datos.idProyecto = estado.proyecto.id;
        
    this.popup.openPopup(PopUpCRMComponent,datos);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubscribeIndividual3))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        if (value.returnValue) {
          this.obtenerCRM();
        }
        this.popup.clearData();
        this._unsubscribeIndividual3.next('');
      }
      );
  }
}
