import {Component,Inject,OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { Fase } from 'src/app/Interfaces/fase';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { ProcesosService } from 'src/app/Services/procesos.service';
import { ProyectosService } from 'src/app/Services/proyectos.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'popup-general-procesos',
  templateUrl: './popup-general-procesos.component.html',
  styleUrls: ['./popup-general-procesos.component.css'],
})
export class PopupGeneralProcesosComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  nombreProceso: string = '';
  selectedIntegrante: any = [];
  proyectos = [];
  integrantes = [];
  selectedFase: any = null;

  fases = [
    { id: 1, name: 'Pre-producción' },
    { id: 2, name: 'Producción' },
    { id: 3, name: 'Post-producción' },
  ];

  selectedProyecto;
  nombreFase: string = '';
  fechaInicio: string = '';
  fechaFin: string = '';
  horas: number = null;
  pago_fin = true;
  numeroProceso: number = null;
  idProceso: number = null;
  porcentaje: number = null;
  constructor(
    public dialogRef: MatDialogRef<PopupGeneralProcesosComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private loaderSE: LoaderService,
    private proyectosSE: ProyectosService,
    private notificacionesSE: NotificacionesService,
    private userSE: UserService,
    private procesoSE: ProcesosService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._unsubscribeIndividual.next('');
    this.proyectosSE.clearResult();
  }
  ngOnInit(): void {
    this.hoy = new Date();
    this.fechaInicio = this.hoy;
    if (this.modalData.proceso != undefined || this.modalData.proceso != null) {
      this.nombreProceso = this.modalData.proceso.nombre;
      this.fechaInicio = this.modalData.proceso.fecha_ini.split('T')[0];
      this.fechaFin = this.modalData.proceso.fecha_fin.split('T')[0];
      this.horas = this.modalData.proceso.horas;
      this.horas = this.modalData.proceso.horas;
      this.selectedFase = this.modalData.proceso.fase;
      this.modalData.proceso.asignaciones.forEach((asignacion) => {
        if(asignacion.empleado != null){
          this.selectedIntegrante.push(asignacion.empleado.id);
        }
      });
      this.numeroProceso = this.modalData.numeroProceso;
      this.idProceso = this.modalData.proceso.id;
      this.porcentaje = this.modalData.proceso.completado;
      this.porcentaje1 = this.porcentaje * 100;
      this.fases = this.modalData.proceso.fases;
    }
    setTimeout(() => {
      this.getAllUsersByEmpresa();
    }, 1000);
  }
  ngAfterViewChecked(): void {}

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  
  getAllUsersByEmpresa() {
    this.userSE.getAllUsersByEmpresa(false);
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        value.forEach((element) => {
          if (!this.integrantes.includes(element)) {
            this.integrantes = [...this.integrantes, element];
          }
        });
        this._unsubscribeIndividual.next('');
      });
  }

  porcentaje1;
  cambioPorcentaje() {
    this.porcentaje = this.porcentaje1 * 0.01;
  }
  checkData() {
    let datosValidos = true;

    if (this.nombreFase == '' || this.nombreFase == null) {
      datosValidos = false;
    }
    if (this.fechaInicio == '' || this.fechaInicio == null) {
      datosValidos = false;
    }
    if (this.fechaFin == '' || this.fechaFin == null) {
      datosValidos = false;
    }  

    if (datosValidos == false) {
      this.notificacionesSE.showError(
        'Se han detectado errores en los campos',
        'Uno o más campos contienen errores, porfavor resvisalos e intentalo más tarde.'
      );
    } else {
      let fase = new Fase();
      fase.pago_fin = this.pago_fin;
      fase.fecha_fin = this.fechaFin;
      fase.fecha_ini = this.fechaInicio;
      fase.nombre = this.nombreFase;
      fase.procesos = [];
      this.closePopup(fase);
      this.notificacionesSE.showFeedBack('Se ha creado la fase correctamente');
    }
  }
  hoy;
  compareDates(inicio, fin) {
    let date1 = new Date(inicio);
    let date2 = new Date(fin);
    let fechaActual = new Date();
    if (date1.getTime() > date2.getTime()) {
      return {
        valido: false,
        mensaje: 'La fecha de inicio no puede ser mayor a la fecha de fin',
      };
    } else if (date1.getTime() < fechaActual.getTime()) {
      return {
        valido: false,
        mensaje: 'La fecha de inicio no puede ser menor a la fecha actual',
      };
    } else if (date2.getTime() < fechaActual.getTime()) {
      return {
        valido: false,
        mensaje: 'La fecha de fin no puede ser menor a la fecha actual',
      };
    } else {
      return { valido: true, mensaje: '' };
    }
  }
  dataChanged() {
    var datosNuevos = false;
    if (this.modalData.proceso != undefined || this.modalData.proceso != null) {
      if (this.nombreProceso != this.modalData.proceso.nombre) {
        datosNuevos = true;
      }
      if (this.fechaInicio != this.modalData.proceso.fecha_ini.split('T')[0]) {
        datosNuevos = true;
      }
      if (this.fechaFin != this.modalData.proceso.fecha_fin.split('T')[0]) {
        datosNuevos = true;
      }
      if (this.horas != this.modalData.proceso.horas) {
        datosNuevos = true;
      }
    }
    return datosNuevos;
  }
  checkDataProceso() {
    let datosValidos = true;

    if (this.nombreProceso == '' || this.nombreProceso == null) {
      datosValidos = false;
    }
    if (this.fechaInicio == '' || this.fechaInicio == null) {
      datosValidos = false;
    }
    if (this.fechaFin == '' || this.fechaFin == null) {
      datosValidos = false;
    }
    if (this.horas == null) {
      datosValidos = false;
    }
    if (this.selectedFase == null) {
      datosValidos = false;
    }

    if (datosValidos == false) {
      this.notificacionesSE.showError(
        'Se han detectado errores en los campos',
        'Uno o más campos contienen errores, porfavor resvisalos e intentalo más tarde.'
      );
    } else {
      let editProceso = {
        nombre: this.nombreProceso,
        horas: this.horas,
        fechaIni: this.fechaInicio,
        fechaFin: this.fechaFin,
        completado: this.porcentaje,
        fase: this.modalData.proceso.fase.id,
        empleados: this.selectedIntegrante,
      };
      this.loaderSE.open();
      this.procesoSE.updateProceso(this.idProceso, editProceso);
      this.procesoSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((data) => {
          if (data == null) {
            return;
          }
          this.loaderSE.closeContador();
          this.notificacionesSE.showFeedBack(data.mensaje);
          // this.dialogRef.close({ update: true });

          this.procesoSE.clearResultUpdate();
          this._unsubscribeIndividual.next('');
            this.closePopup(true);
        });
      this.procesoSE
        .getResultUpdateError()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((data) => {
          if (data == null) {
            return;
          }
          this.loaderSE.closeContador();
          this.notificacionesSE.showError(data.mensaje);
          this._unsubscribeIndividual.next('');
          this.procesoSE.clearResultUpdateError();
        });
    }
  }
}
