import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.css'],
})
export class TablaComponent implements OnInit {
  @Input() etiquetas: string[] = [];
  @Input() procesos: any = [];
  @Output() horas = new EventEmitter<number>();
  horasTotales = 0;
  selectedIntegrante: any = null;
  @Input() integrantes: any = [];

  constructor() {}

  ngOnInit() {
    this.procesos.forEach((proceso) => {
      this.horasTotales += proceso.horas;
    });
  }
  recalcularPorcentaje() {
    this.horasTotales = 0;
    this.procesos.forEach((proceso) => {
      this.horasTotales += proceso.horas;
    });
    this.horas.emit(this.horasTotales);
  }
}
